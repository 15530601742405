import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreAnalysisCategories } from '../../firebase/useFirestore'
import useSettings from "../../hooks/Settings";
import firebase from 'firebase/app';

const DevelopmentMultilineGraphResponsive = ({field, researchID, startDate, endDate}) => {
    const [data, setData] = useState(null)
    const [colors, setColors] = useState([])

     // Default start and end date if dates are undefined
     const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
     const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());
    
    // Set colors
    const primaryColor = useSettings().PrimaryColor
    const secondaryColor = useSettings().SecundairyColor
    const tertiaryColor = useSettings().TertairyColor
    
    useEffect(() => {
      setColors([primaryColor, secondaryColor, tertiaryColor, primaryColor, secondaryColor, tertiaryColor])
    },[primaryColor, secondaryColor, tertiaryColor])
    
    const categories = useFirestoreAnalysisCategories(field ? field : '123')
    
    const getwords = async (item, moment) => {
      
      let words = 0
      
      await db.collection('AnalysisWords')
          .where('CompagnyID', '==', client ? client : "")
          .where('FieldID', '==', field ? field : "")
          .where('MomentID', '==', moment ? moment : "")
          .where('CategoryID', '==', item ? item : "")
          .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
          .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
          .get()
          .then((querySnapshot) => {
             words = querySnapshot.size
    
          })
    
      return words
    }
    
    const getMoments = async () => {
    
      const momentSnapshot = 
      await db.collection("MeasureMoments")
      .where('CompagnyID', '==', client ? client : "")
      .where('ResearchID', '==', researchID ? researchID : "")
      .orderBy("Position", "asc")
      .get();
    
      const momentPromise = momentSnapshot.docs.map(async (moment) => {
          const momentData = {
              name: moment.data().Title,
              categories: []
          }
    
          const categoriesSnapshot = 
          await db.collection('AnalysisCategories')
          .where('CompagnyID', '==', client ? client : "")
          .where('FieldID', '==', field ? field : "")
          .get()
    
          const categorieArray =  []
    
          categoriesSnapshot.docs.map(async (cat) => {
            momentData[cat.data().Categorie] = await getwords(cat.data().ID, moment.data().ID)
            momentData.categories.push(cat.data().Categorie)
          })
    
          momentData.categories = categorieArray
    
          return momentData
        })
    
        const moments = await Promise.all(momentPromise)
    
        return moments
    }
    
        useEffect(() => {   
            getMoments().then((data) => {
                setTimeout(() => {
                  setData(data)
                } , 2000)
            })
        },[field, researchID])
    
    return (
        <ResponsiveContainer width="90%" height={400}>
            <LineChart
            data={data && data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
                {categories && categories.map((cat, index) => (
                <Line key={`line-${index}`} type="monotone" dataKey={cat.Categorie} stroke={cat.Color} strokeWidth='2'/>
                ))}
            <CartesianGrid strokeDasharray="3 3" />
            </LineChart>
        </ResponsiveContainer>
    );
       
}

export default DevelopmentMultilineGraphResponsive