import { useState } from 'react';

const CheckboxDropdown = ({ items, label, filterFunction }) => {
    // State
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    // Hooks
    const selectedItemsLabel = selectedItems.length === 0 ? "Selecteer..." : `${selectedItems.length} geselecteerd`;

    // Handle checkbox change
    const handleCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedItems([...selectedItems, item.id]);
        } else {
            setSelectedItems(selectedItems.filter(id => id !== item.id));
        }
        filterFunction([...selectedItems, item.id]);
    };

    console.log(showDropdown);

    return (
        <div className="custom-select">
            <div className="select-box" onClick={() => setShowDropdown(showDropdown === label ? "" : label)}>
                {selectedItemsLabel}
                <span className="arrow">▼</span>
            </div>
            <div className="dropdown-content" style={{display: showDropdown === label ? 'block' : 'none'}}>
                {items.map((item, index) => (
                    <div key={index}>
                        <input
                            type="checkbox"
                            id={`${label}-${item.id}`}
                            value={item.id}
                            onChange={(e) => handleCheckboxChange(e, item)}
                        />
                        <label htmlFor={`${label}-${item.id}`}>{item.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckboxDropdown