import React, { useEffect } from 'react'
import { useFirestoreGeneralTwoOrderBy } from '../../firebase/useFirestore'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { useState } from 'react';
import Location from "../../hooks/Location"

const OpenQuestionSlider = ({moment, field, startDate, endDate}) => {
    // State
    const [visible, setVisible] = useState(0);
    const [showTextResponses, setShowTextResponses] = useState(false);
    const [printPage, setPrintPage] = useState(false);

    // Hooks
    const page = Location()[2];

    // Firestore
    const responses = useFirestoreGeneralTwoOrderBy("QuestionnairesResponses", "FieldID", field, "MomentID", moment, "Timestamp", "asc");

    // Set page type
    useEffect(() => {
        if(page === 'printlivereport'){
            setPrintPage(true);
        }
    }, [page])

    // Navigation
    const nextIndicator = () => {
        if(visible < responses.length -1){
            setVisible(visible + 1);
        } else {
            setVisible(0);
        }
    }

    const previousIndicator = () => {
        if(visible > 0){
            setVisible(visible - 1);
        } else {
            setVisible(responses.length -1);
        }
    }

  return (
    <>
        {/* Check if page type if print page or not */}
        {printPage 

            ? 

            <div id='live-report-open-question-slider'>
                {responses && responses.map((response, index) => (
                    <div key={response.ID} id='live-report-open-question-slider'>
                        <p>{index + 1}</p>
                        <p>{response.Input}</p>
                    </div>
                ))}
            </div>

            :
            
            <div id='text-reponses-container'>
                {responses && responses.map((response, index) => (
                    <div key={response.ID} style={{display: index === visible ? 'block' : 'none'}} id='live-report-open-question-slider'>
                        <p>{response.Input}</p>
                    </div>
                ))}
                <div id='live-report-effect-indicator-navbar'>
                    <p className="live-report-effect-indicator-navbar-button" id='live-report-open-question-text-indicator-navbar-button' onClick={previousIndicator}>Vorige</p>
                    <div>
                        <p id='live-report-open-question-text-count-indicator'>{visible + 1} van de {responses.length }</p>
                    </div>
                    <p className="live-report-effect-indicator-navbar-button" id='live-report-open-question-text-indicator-navbar-button' onClick={nextIndicator}>Volgende</p>
                </div>
            </div>
        }
    </>
  )
}

export default OpenQuestionSlider