import Location from "../../hooks/Location"
import { useFirestoreGeneral, useFirestoreGeneralOrderBy, useFirestoreID } from "../../firebase/useFirestore"
import { useEffect, useState, useContext } from 'react';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { client } from "../../hooks/Client";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import { ShareQuestionaireSystemMessage } from "../../hooks/impactAI/Prompts"
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import CopyLink from "../../components/common/CopyLink";
import Tooltip from "../../components/common/Tooltip";
import QrcodeIcon from '../../images/icons/qrcode-icon.png'

const ShareQuestionnaire = () => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // Hooks
    const questionnaire = Location()[3]
    const research = Location()[4]
    const persona = Location()[5]
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor
    ScrollToTop()

    // State
    const [measureMomentID, setMeasureMomentID] = useState("")

    // Firestore
    const researches = useFirestoreGeneral('Research', 'ID', research ? research : '')
    const measureMoments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', research ? research : '' , 'Position', 'asc')
    const personas = useFirestoreID('Personas', persona ? persona : '')

     // ImpactAI
     const pageSystemMessage = ShareQuestionaireSystemMessage()
     const pageStartMessage = `Hoi ${auth?.ForName}, welkom op pagina 'Vragenlijst delen'. 
     Hier kun je onderzoekensdata verzamelen door vragenlijsten uit te zetten onder je doelgroep(en).
     Kan ik je ergens mee helpen?`
     const pageType = 'sharequestionaire'
 
 
     useEffect(() => {
         setType(pageType)
         setSystemMessage(pageSystemMessage)
         setStartMessage(pageStartMessage)
     }, [auth])


    const measureMomentHandler = (e) => {
        const id = e.target.options[e.target.selectedIndex].value
        console.log(id)
        setMeasureMomentID(id)
    }

    // Get persona from URL
    const personaMeta = () => {
        
        return personas && personas.map(persona => (
            persona.Name
        ))
    }

    console.log(research, measureMomentID)


  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <h1>Vragenlijst delen</h1>
            </div>
            <div className="dashboard-container">
                <div className="wizard-section-container">
                    <div className='text-section'>

                        <div className="start-research-step-title-container">
                            <h2>Selecteer onderzoek</h2> 
                            {researches && researches.map(research => (
                                <p>{research.Title}</p>
                            ))}
                        </div> 
                         
                        <div>
                            <div className="start-research-step-title-container">
                                <h2>Selecteer meetmoment</h2> 
                                <select name="" id="" onChange={measureMomentHandler} defaultValue={measureMomentID}>
                                    <option value="">-- selecteer meetmoment --</option>
                                    {measureMoments && measureMoments.map(measureMoment => (
                                        <option value={measureMoment.ID}>{measureMoment.Title}</option>
                                    ))}
                                </select>
                            </div> 
                        </div>

                        {persona 
                            ? 
                            <div className="start-research-step-title-container">
                                <h2>Selecteer persona</h2> 
                                <p>{personaMeta()}</p>
                            </div> 
                            : 
                            <div></div>
                        }

                        <div className="share-questionnaire-container" style={{display: measureMomentID === '' ? 'none' : 'block'}}>
                            <div className="start-research-step-title-container">
                                <h2>Start onderzoek</h2>
                            </div> 
                            <div className='table-container'>
                                <table>
                                    <tr>
                                        <th style={{backgroundColor: secundairyColor}}>LINK</th>
                                        <th style={{backgroundColor: secundairyColor}}>QR-CODE</th>
                                        <th style={{backgroundColor: secundairyColor}}>PRINTEN</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CopyLink link={`/${client}/questionnaireopen/${questionnaire}/${measureMomentID}/${research}/${persona}/open`}/>
                                        </td>
                                        <td>
                                            <Tooltip content='Print de QRcode' top={'-40px'}>
                                                <img src={QrcodeIcon}id='share-questionnaire-qrcode-icon' alt="" onClick={() =>  history.push(`/${client}/qrcode/${questionnaire}/${measureMomentID}/${research}/${persona}`)}/>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip content='Print de vragenlijst' top={'-40px'}>
                                                <LocalPrintshopIcon className='icon-margin-left' onClick={() =>
                                                    history.push(`/${client}/printquestionaire/${questionnaire}/${measureMomentID}/${research}/${persona}`)
                                                }/>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <p>Print/PDF</p>
                                        </td>
                                        <td>
                                            <Tooltip content='Print de vragenlijst' top={'-60px'}>
                                                <LocalPrintshopIcon className='icon-margin-left' onClick={() => history.push(`/${client}/printQuestionnaire/${questionnaire}`)}/>
                                            </Tooltip>
                                        </td>
                                    </tr> */}
                            </table>
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default ShareQuestionnaire