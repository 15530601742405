import { useFirestoreAll, useFirestore } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client"
import { v4 as uuid } from 'uuid';
import { useState, useContext } from 'react';
import CheckboxDropdown from '../../components/common/CheckboxDropdown';
import { sectorsArray, regionsArray, targetgroupArray, legalArray } from '../../components/financiers/FundFilterData';
import { useEffect } from 'react';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import { Auth } from '../../StateManagment/Auth';
import { AllFundsSystemMessage, AllFundsToolCallContent, AllFundsSaveMessage } from '../../hooks/impactAI/PromptsFinancing';

const AllFunds = () => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [filters, setFilters] = useState({
        sectors: [],
        regions: [],
        targetgroups: [],
        legal: []
    });
    const [filteredFunds, setFilterFunds] = useState(null);

    // Firestore
    const funds = useFirestoreAll('Funds')
    const compagnyFunds = useFirestore('CompanyFunds')

    const addFund = (e) => {
        const id = e.target.dataset.id
        db.collection('CompanyFunds').add({
            FundID: id,
            ID: uuid(),
            CompagnyID: client,
            Timestamp: timestamp,
            Position: compagnyFunds.length + 1,
            Status: 'not started'
        })
    }

    // Stringify filtered funds for FinancingAI
    const stringyfiedFilteredFunds = JSON.stringify(filteredFunds, null, 2)

    console.log(stringyfiedFilteredFunds)

     //ImpactAI
     const pageSystemMessage = AllFundsSystemMessage(stringyfiedFilteredFunds)
     const pageToolCallContent = AllFundsToolCallContent()
     const pageSaveMessage = AllFundsSaveMessage()
     const pageStartMessage = `Welkom bij het fondsenoverzicht, ${auth?.ForName}. Hier kun je fondsen vinden die jouw project kunnen financieren.
     Kan ik je helpen om geschikte fondsen te vinden?`
     const pageExampleMessages = []
     const pageCollection = 'CompagnyFunds'
     const pageField = 'Alle fondsen'
     const pageParentId = ''
     const pageType = 'fund'
 
     useEffect(() => {
         setType(pageType)
         setSystemMessage(pageSystemMessage)
         setStartMessage(pageStartMessage)
         setToolCallContent(pageToolCallContent)
         setSaveMessage(pageSaveMessage)
         setCollection(pageCollection)
         setField(pageField)
         setParentId(pageParentId)
         setExampleMessages(pageExampleMessages)
     }, [auth])

    // Handle filter checkbox change
    const handleCheckboxChange = (category, value, checked) => {
        setFilters(prevFilters => {
            const updatedCategory = checked
                ? [...prevFilters[category], value]
                : prevFilters[category].filter(item => item !== value);

            return { ...prevFilters, [category]: updatedCategory };
        });
    };

    // Generic filter function
    const applyFilters = (fund) => {
        const { sectors, regions, targetgroups, legal } = filters;
    
        // If all filter arrays are empty, return true to show all funds
        const noFiltersApplied = 
            sectors.length === 0 && 
            regions.length === 0 && 
            targetgroups.length === 0 && 
            legal.length === 0;
    
        if (noFiltersApplied) {
            return true; // Show all funds if no filters are selected
        }
    
        // Check if these fields exist and are arrays, otherwise handle accordingly
        const sectorMatch = sectors.length === 0 || (Array.isArray(fund.Sector) && sectors.some(sector => fund.Sector.includes(sector)));
        const regionMatch = regions.length === 0 || (Array.isArray(fund.Region) && regions.some(region => fund.Region.includes(region)));
        const targetgroupMatch = targetgroups.length === 0 || (Array.isArray(fund.Targetgroup) && targetgroups.some(targetgroup => fund.Targetgroup.includes(targetgroup)));
        const legalMatch = legal.length === 0 || (Array.isArray(fund.Legal) && legal.some(legalEntity => fund.Legal.includes(legalEntity)));
    
        // Return true only if all filters match
        return sectorMatch && regionMatch && targetgroupMatch && legalMatch;
    };
    

    useEffect(() => {
        setFilterFunds(funds && funds.filter(applyFilters))
    },[filters])

    console.log(filteredFunds);

    return (
        <div className="main-container">
            <div className="page-header">
                <h1>Alle fondsen</h1>
            </div>
            <div id='all-funds-filter-container'>
                <p>Aandachtsgebieden</p>
                <CheckboxDropdown items={sectorsArray} label="Alle aandachtsgebieden" filterFunction={(value, checked) => handleCheckboxChange('sectors', value, checked)} />

                <p>Regio</p>
                <CheckboxDropdown items={regionsArray} label="Alle regio's" filterFunction={(value, checked) => handleCheckboxChange('regions', value, checked)} />

                <p>Doelgroep</p>
                <CheckboxDropdown items={targetgroupArray} label="Alle doelgroepen" filterFunction={(value, checked) => handleCheckboxChange('targetgroups', value, checked)} />

                <p>Rechtsvorm</p>
                <CheckboxDropdown items={legalArray} label="Alle rechtsvormen" filterFunction={(value, checked) => handleCheckboxChange('legal', value, checked)} />
                
                <p>Zoeken</p>
                <input type='text' placeholder='Zoek naar fondsen'/>
            </div>
            <div className="dashboard-container">
                {filteredFunds && filteredFunds.map((item, index) => (
                    <div key={index} className='all-funds-fund-container'>
                        <img className='all-funds-fund-logo-img' src={item.Image} alt="" />
                        <h2>{item.Name}</h2>
                        <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                        <div className='all-funds-fund-info-container'>
                            <div>
                                <p>Doelgroepen</p>
                                <ul>
                                    {item.Targetgroup.map((target, index) => (
                                        <li key={index}>{target}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <p>Sector</p>
                                <ul>
                                    {item.Sector.map((sector, index) => (
                                        <li key={index}>{sector}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <p>Regio</p>
                                <ul>
                                    {item.Region.map((region, index) => (
                                        <li key={index}>{region}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <p>Rechtsvormen</p>
                                <ul>
                                    {item.Legal.map((legal, index) => (
                                        <li key={index}>{legal}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <button data-id={item.ID} onClick={addFund}>Toevoegen aan mijn fondsen</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllFunds;
