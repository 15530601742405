import sendIcon from '../../images/icons/send-icon.png'
import { useFirestoreID } from '../../firebase/useFirestore'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { client } from '../../hooks/Client'

const PersonaResearchLink = ({researchID, personaID}) => {

    // State
    const [questionnaireID, setQuestionnaireID] = useState('')

     // Hooks
    const history = useHistory()

    // Firestore
    const researches = useFirestoreID('Research', researchID ? researchID : '0')

    // Set questionnaire ID
    useEffect(() => {
        researches && researches.map(research => {
            setQuestionnaireID(research.QuestionnaireID)
        })
    }, [researches, researchID])
 
  return (
    <img src={sendIcon} alt="" className="table-delete-icon" onClick={() => history.push(`/${client}/sharequestionnaire/${questionnaireID}/${researchID}/${personaID}`)}/>
  )
}

export default PersonaResearchLink