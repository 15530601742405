import firebase from 'firebase/app';
import 'firebase/firestore';

const configPortfolio = {
    apiKey: "AIzaSyD0oj34MrrFvXzNUXeStsR_PsiRV2vAPl4",
    authDomain: "finpact-c522a.firebaseapp.com",
    projectId: "finpact-c522a",
    storageBucket: "finpact-c522a.appspot.com",
    messagingSenderId: "831280003631",
    appId: "1:831280003631:web:03461d14747f3ab28424da"
};

const portfolioApp = firebase.initializeApp(configPortfolio, 'portfolio');

const portfoliodb = portfolioApp.firestore();
const portfolioFunctions = portfolioApp.functions();

export { portfoliodb, portfolioFunctions };