import useSettings from "./Settings";
import { useState, useEffect } from "react";

const RandomColorGenerator = () => {
    const [colors, setColors] = useState([])

    // Set colors
    const primaryColor = useSettings().PrimaryColor
    const secondaryColor = useSettings().SecundairyColor
    const tertiaryColor = useSettings().TertairyColor

    useEffect(() => {
    setColors([primaryColor, secondaryColor, tertiaryColor])
    },[primaryColor, secondaryColor, tertiaryColor])

    // Create ramdon number
    const randomColorGenerator = () => {
        const randomIndex = colors && Math.floor(Math.random() * colors.length);
        const randomColor = colors[randomIndex];

        return randomColor

    }

  return (
    randomColorGenerator()
  )
}

export default RandomColorGenerator