import { useFirestore, useFirestoreOrderBy} from "../../firebase/useFirestore";
import { useContext, useState } from "react";
import { db, timestamp } from "../../firebase/config.js"
import { client } from '../../hooks/Client';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useHistory } from "react-router-dom";

const SelectKPIsOutputs = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [effectID, setEffectID] = useState('')
    const [selectedOutput, setSelectedOutput] = useState(null)
    const [color, setColor] = useState(null)

    // Hooks
    const history = useHistory()

    // Firestore
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')
    const outputs = useFirestore('Outputs')
    const proofs = useFirestoreOrderBy('KpiData', 'Position', 'asc' )

    // Save the output proof
    const saveOutputProof = () => {

        db.collection("KpiData")
        .doc()
        .set({
            Output: selectedOutput,
            KpiID: effectID,
            Timestamp: timestamp,
            CompagnyID: client, 
            Type: 'output',
            Color: color,
            Position: proofs.length + 1
        })
        .then(() => {
            setSaved('flex')
            history.push(`/${client}/kpis`)
        })

    }

     // Update the selected output
     const outputHandler = (e) => {
        e.preventDefault()

        const output = e.target.options[e.target.selectedIndex].value
        const color = e.target.options[e.target.selectedIndex].dataset.color

        setSelectedOutput(output)
        setColor(color)

    }

    // Effect handler
    const effectHandler = (e) => {
        e.preventDefault()

        const effect = e.target.options[e.target.selectedIndex].value

        setEffectID(effect)

    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>Onderbouw met outputs</h1>
            </div>
            <div className="table-container dashboard-container">
                <div>
                    <h1>Effect onderbouwen</h1>
                    <div>
                        <h2>1. Selecteer effect</h2>
                            <select name="" id="" onChange={effectHandler}>
                                <option value="">-- selecteer effect --</option>
                                {effects && effects.map(item => (
                                    <option key={item.ID} value={item.ID}>{item.Effect}</option>
                                ))}
                            </select>
                        <div style={{display: effectID ? 'block' : 'none'}}>
                            <h2>2. Selecteer outputs</h2>
                            <div>
                                <select name="" id="" onChange={outputHandler}>
                                    <option value="">-- selecteer output --</option>
                                    {outputs && outputs.map(item => (
                                        <option key={item.ID} data-color={item.Color} value={item.ID}>{item.Title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <button style={{display: selectedOutput ? 'block' : 'none'}} id='kpi-research-save-button' onClick={saveOutputProof}>Opslaan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SelectKPIsOutputs