import { useState, useEffect, useContext } from "react";
import Section from "./Section";
import TopLevelFields from "./TopLevelFields";
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid';
import { Responses } from "../../StateManagment/Responses";
import { useFirestoreGeneralOrderBy, useFirestoreGeneral, useFirestoreID } from "../../firebase/useFirestore";
import Location from "../../hooks/Location"
import { client } from "../../hooks/Client";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Hostname from '../../hooks/Hostname'

const QuestionnaireField = ({questionaire, setTotalTopLevelQuestions, setFieldIndex, fieldIndex, compagnyId}) => {
    // Context
    const [responses, setResponses] = useContext(Responses)

    // State
    const [saved, setSaved] = useState(false)
    const [sendCopyEmail, setSendCopyEmail] = useState('')

    // Get url params
    const moment = Location()[4]
    const research = Location()[5]
    const persona = Location()[6]
    const test = Location()[7]

    // Hooks
    const host = Hostname()

    // Firestore
    const fields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionaire.ID, 'Position', 'asc') 
    const researches = useFirestoreGeneral('Research', 'ID', research ? research : '')
    const questionaires = useFirestoreID('Questionnaires', questionaire.ID ? questionaire.ID : '')

    // SendCopyOption 
    const sendCopyOption = researches && researches.map(research => research.SendCopyOption);

    // Get title fof questionnaire 
    const questionaireTitle = questionaires && questionaires.map(questionaire => questionaire.Title) 

    // Set total top level questions
    const topLevelFields = fields && fields.filter(field => field.SectionID === '')   

    setTotalTopLevelQuestions(topLevelFields?.length)

    const saveResponses = () => {

        const formID = uuid()

        responses && responses.map(response => {

            db.collection('QuestionnairesResponses')
                .doc()
                .set({
                FieldID: response.FieldID,
                Input: response.Input,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionannaireID: questionaire.ID,
                MomentID: moment,
                ResearchID: research,
                FormID: formID,
                ID: uuid(),
                OptionType: response.OptionType ? response.OptionType : '',
                Persona: persona ? persona : '',
                RowTitle: response.RowTitle ? response.RowTitle : '',
                FieldType: response.FieldType ? response.FieldType : '',
                ColumnID: response.ColumnID ? response.ColumnID : '',
            })
            .then(() => {
                setSaved(true)
            })
        })
    }

    console.log(fields)

    // Helper function to get the title of the question
    const getResponseTitle = (id) => {
        const matchingField = fields && fields.find(field => field.ID === id);
        return matchingField ? matchingField.Question : null; // Returns Title if found, else null
    };

    // Helper function to create a string version of the responses to sent by email
    const createResponsesString = () => {
        const array = [];
    
        responses && responses.map(response => {
            const object = {
                Vraag: getResponseTitle(response.FieldID),
                Antwoord: response.Input
            };
    
            array.push(object);
        });
    
        // Format each object in array to a readable string and join them
        const formattedString = array.map(item => `Vraag: ${item.Vraag}\nAntwoord: ${item.Antwoord}`).join("\n\n");
    
        return formattedString;
    };
    

    // Send a copy of the results by email
    const downloadResultsHandler = (e) => {

        // Change color and text of button after click
        e.target.style.color = "lightgray"
        e.target.innerHTML = 'Downloaden...'

        const filename = `responses-${questionaire.Title}-${new Date}.txt`; // The desired file name

        // Create a blob from the string
        const blob = new Blob([createResponsesString()], { type: 'text/plain' });

        // Create an anchor element and set the download attribute
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Append the link to the body, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the URL to free up memory
        URL.revokeObjectURL(link.href);

        e.target.innerHTML = 'Gedownload'
        
    }

    return(
        <div id='questionnaire-fields-container'>
            {topLevelFields && topLevelFields.map((field, index) => (
                <div key={field.ID} id='questionnaire-field-section-item-container'>
                    {field.Type === 'section' && 
                        <Section 
                        field={field} 
                        index={index} 
                        setFieldIndex={setFieldIndex} 
                        fieldIndex={fieldIndex} 
                        compagnyId={compagnyId} 
                        questionaire={questionaire}
                        />
                    }
                    {field.Type !== 'section' && 
                        <TopLevelFields 
                        field={field} 
                        index={index} 
                        setFieldIndex={setFieldIndex} 
                        fieldIndex={fieldIndex} 
                        compagnyId={compagnyId} 
                        questionaire={questionaire}
                        />
                    }
                </div>
             ))} 
             <div style={{display: fieldIndex + 1  > topLevelFields?.length ? 'block' : 'none'}}>
                <div style={{display: saved ? 'none' : 'block'}}>
                    <h2>Dit is het einde van de vragenlijst</h2>
                    {test === 'test' ? <p>Dit is een previewversie van de vragenlijst. De antwoorden kunnen niet worden opgeslagen.</p>
                    :
                    <p>Klik op verzenden om de vragenlijst op te slaan</p>
                    }
                    <div className="questionnaire-button-container">
                        <button onClick={() => setFieldIndex(fieldIndex - 1)}>Terug</button>
                        <button onClick={saveResponses} id='questionniare-send-button' style={{display: test === 'test' ? 'none' : 'block'}}>
                            <div id='send-button-inner-container' >
                                <SendOutlinedIcon />
                                <p >Verzenden</p>
                            </div>
                        </button>
                    </div>
                </div>
               
                <div id='responses-send-container' style={{display: saved ? 'flex' : 'none'}}>
                    <h2>Uw antwoorden zijn opgeslagen</h2>
                    <button onClick={() => window.location.reload()}>Opnieuw</button>
                    <div id='send-copy-container' style={{display: sendCopyOption[0] === 'on' ? 'block' : 'none'}}>
                        <p><b>Kopie van resultaten downloaden</b></p>
                        <button className="button-simple" onClick={downloadResultsHandler}>Downloaden</button>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default QuestionnaireField