import { createContext, useEffect, useState } from "react";
import {useFirestore} from "../firebase/useFirestore"

export const ImpactAIData = createContext();

export const ImpactAIDataProvider = (props) => {
    // States
    const [summary, setSummary] = useState("none");
    const [problemAnalyse, setProblemAnalyse] = useState("none");
    const [targetgroups, setTargetgroups] = useState("none");
    const [goals, setGoals] = useState("none");
    const [activities, setActivities] = useState("none");
    const [activityIDList, setActivityIDList] = useState("none"); 
    const [outputs, setOutputs] = useState("none");
    const [effects, setEffects] = useState("none");

    // Firestore
    const sourceData = useFirestore('SourceData')
    const centralProblem = useFirestore('CentralProblem')
    const stakeholders = useFirestore('Stakeholders')
    const goal = useFirestore("Goals")
    const activityList = useFirestore('Activities')  
    const outputsList = useFirestore('Outputs')
    const effectsList = useFirestore('OutputEffects')

    // Set firestore data to states
    useEffect(() => {
        // Arrays of mulitple data
        const targetgroupArray = []
        const activityArray = []
        const activityIDArray = []
        const outputArray = []
        const effectArray = []

        // Set organisation context data to state
        sourceData && sourceData.map(source => {
            setSummary(source.Summary ? source.Summary : "none")
        })

        // Set central problem context data to state
        centralProblem && centralProblem.map(problem => {
            setProblemAnalyse(problem.CentralProblem ? problem.CentralProblem : "none")
        })

        // Set targetgroup context data to state
        stakeholders && stakeholders.map(stakeholder => {
            targetgroupArray.push(stakeholder.Organisation ? stakeholder.Organisation : "none")
        })
        setTargetgroups(targetgroupArray)

        // Set goal context data to state
        goal && goal.map(item => {
            
                setGoals(item.Title ? item.Title : "none")
        })

        // Set activity data to state
        activityList && activityList.map(activity => {
            activityArray.push(activity.Activity ? activity.Activity : "none")
        })
        setActivities(activityArray)

        // Set a list of activities with corresponding ID's to state
        activityList && activityList.map(activity => {
            const object = {
                id: activity.ID,
                activity: activity.Activity
            }
            activityIDArray.push(object)
        })
        const string = JSON.stringify(activityIDArray)
        setActivityIDList(string ? string : "none")


        // Set output data to state
        outputsList && outputsList.map(output => {
            outputArray.push(output.Title ? output.Title : "none")
        })
        setOutputs(outputArray)

        // Set effect data to state
        effectsList && effectsList.map(effect => {
            effectArray.push(effect.Effect ? effect.Effect : "none")
        })
        setEffects(effectArray)

    }, [
        sourceData, 
        centralProblem,
        stakeholders,
        goal,
        activityList,
        outputsList,
        effectsList
    ])

    return (
        <ImpactAIData.Provider value={{
            summary: [summary, setSummary],
            problemAnalyse: [problemAnalyse, setProblemAnalyse],
            targetgroups: [targetgroups, setTargetgroups],
            goals: [goals, setGoals],
            activities: [activities, setActivities],
            activityIDList: [activityIDList, setActivityIDList],
            outputs: [outputs, setOutputs],
            effects: [effects, setEffects]
        }}>
            {props.children}
        </ImpactAIData.Provider>
    );
}