import printIcon from "../../images/icons/print-icon.png";
import arrowLeftIcon from "../../images/icons/arrow-left-icon.png";
import { useHistory } from "react-router-dom";
import { useFirestoreID, useFirestoreGeneralOrderBy } from '../../firebase/useFirestore';
import Location from '../../hooks/Location';
import { useEffect, useState } from 'react';

const PrintPresenceReport = () => {
    const history = useHistory();
    const client = Location()[1];
    const personaId = Location()[3];
    const startDate = Location()[4];
    const endDate = Location()[5];

    // Firestore
    const personas = useFirestoreID('Personas', personaId ? personaId : '123');
    const presenceReports = useFirestoreGeneralOrderBy(
        'PersonaPresenceReports', 
        'PersonaID', 
        personaId ? personaId : '', 
        'Position', 
        'asc'
    );

    const [totalSheduledPresence, setTotalSheduledPresence] = useState(0);
    const [totalPresent, setTotalPresent] = useState(0);

    // Helper to format date to 'd-m-y'
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(endDate);

    // Date normalization function
    const normalizeDate = (date) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };

    // Parse custom date function if needed
    const parseCustomDate = (dateString) => {
        const monthsOfYear = {
            januari: 0, februari: 1, maart: 2, april: 3, mei: 4,
            juni: 5, juli: 6, augustus: 7, september: 8, oktober: 9, november: 10, december: 11
        };
        const [_, day, month, year] = dateString.split(' ');
        return new Date(year, monthsOfYear[month], day);
    };

    // Helper function to calculate scheduled hours
    const getScheduledHours = (startTime, endTime) => {
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
        return (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
    };

    // Helper function to calculate present hours
    const getPresentHours = (startTime, endTime, correctedStartTime, correctedEndTime) => {
        const start = correctedStartTime ? new Date(`1970-01-01T${correctedStartTime}:00`) : new Date(`1970-01-01T${startTime}:00`);
        const end = correctedEndTime ? new Date(`1970-01-01T${correctedEndTime}:00`) : new Date(`1970-01-01T${endTime}:00`);
        return (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
    };

    // Filter reports by date
    const filterReportsByDate = () => {
        const start = normalizeDate(startDate);
        const end = normalizeDate(endDate);

        return presenceReports.filter(report => {
            let reportDate = typeof report.Date === 'string' ? parseCustomDate(report.Date) : new Date(report.Date.seconds * 1000);
            reportDate = normalizeDate(reportDate);
            return reportDate >= start && reportDate <= end;
        });
    };

    // Calculate totals
    const calculateTotals = () => {
        const filteredReports = filterReportsByDate();
        let scheduledTotal = 0;
        let presentTotal = 0;

        filteredReports.forEach(report => {
            const scheduledHours = report.Type !== 'absent' ? getScheduledHours(report.Start, report.End) : 0;
            const presentHours = report.Type !== 'absent' ? getPresentHours(report.Start, report.End, report.CorrectedStart, report.CorrectedEnd) : 0;
            scheduledTotal += scheduledHours;
            presentTotal += presentHours;
        });

        setTotalSheduledPresence(scheduledTotal);
        setTotalPresent(presentTotal);
    };

    // Calculate totals when presenceReports change
    useEffect(() => {
        if (presenceReports && presenceReports.length > 0) {
            calculateTotals();
        }
    }, [presenceReports, startDate, endDate]);

    return (
        <div className="main">
            <div className="main-container">
                <div className="print-go-back-container" onClick={() => history.push(`/${client}/personabuilder/${personaId}`)}>
                    <img src={arrowLeftIcon}/>
                    <p>Terug</p>
                </div>
                <div className="print-icon-container" onClick={() => window.print()}>
                    <img src={printIcon} alt="print icon" className="print-icon" />
                    <p>Print aanwezigheidsrapportage</p>
                </div>
                <div id='print-presence-report-data-container'>
                    <h1>Aanwezigheidsrapportage van {personas && personas[0]?.Name}</h1>
                    <div id='print-presence-report-period-container'> 
                        <p>Periode:</p>
                        <p>{startDateFormatted}</p>
                        <p>-</p>
                        <p>{endDateFormatted}</p>
                    </div>
                    <div>
                        <h2>Totaal geroosterd: {totalSheduledPresence} uur</h2>
                        <h2>Totaal aanwezig: {totalPresent} uur</h2>
                        <h2>Totaal verschil: {totalPresent - totalSheduledPresence} uur</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintPresenceReport;
