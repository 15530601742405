import { useFirestoreID } from '../../firebase/useFirestore'
import OutputMeta from './OutputMeta'

const ResultMeta = ({output}) => {

    const outputs = useFirestoreID('Outputs', output)

  return (
    <>
        {outputs && outputs.map(item => (
            <div key={item.ID}>
                <p><b>{item.Title}</b></p>
            </div>
        ))}
    </>
  )
}

export default ResultMeta