import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreGeneral } from '../../firebase/useFirestore'
import firebase from 'firebase/app'
import OutputMultiLineGraph from '../Visualisations/OutputMultiLineGraph';

const OutputData = ({activity, startDate, endDate, graphType}) => {
      // State
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const options = {year: 'numeric', month: 'numeric', day: 'numeric' };

  // Firestore
  const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activity ? activity : '')
  const activityData = useFirestoreGeneral('Activities', 'ID', activity? activity : '')

  // Default start and end date if dates are undefined
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

  // Get the output results for each date
  const outputResults = async (output, date) => {

      const querySnapshot = await db.collection('Results')
      .where('CompagnyID', '==', client)
      .where('Timestamp', '==', date)
      .where('OutputID', '==', output)
      .get()

      // Get the result for each output
      const score = querySnapshot.docs.map(async (doc) => {
          const result = await doc.data().Result

          return result 
      })

      const result = await Promise.all(score)

      // Return 0 if there are no results
      if(querySnapshot.empty) return [0]

      return result

  }

  // Query output results
  const queryResults = async () => {
    try {
      setIsLoading(true); // Start loading
  
      const querySnapshot = await db.collection('Results')
        .where('CompagnyID', '==', client)
        .where('ActivityID', '==', activity)
        // .where('Timestamp', '>=', startDate || defaultStartDate)
        // .where('Timestamp', '<=', endDate || defaultEndDate)
        .orderBy('Timestamp', 'asc')
        .get();
  
      const array = await Promise.all(querySnapshot.docs.map(async (doc) => {
        const date = doc.data().Timestamp.toDate().toLocaleDateString("nl-NL", options);
        const object = { name: date };
  
        const outputsSnapshot = await db.collection('Outputs')
          .where('CompagnyID', '==', client)
          .where('ActivityID', '==', activity)
          .get();
  
        await Promise.all(outputsSnapshot.docs.map(async (output) => {
          const results = await outputResults(output.data().ID, doc.data().Timestamp);
          object[output.data().Title] = results[0];
        }));
  
        return object;
      }));
  
      // Add the results to the previous result
      array.length > 0 && addResults(array); 

      // Set the data
      if (outputs && activity) {
        array && setData(array || []);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
      // Handle error appropriately
    } finally {
      setIsLoading(false); // Stop loading whether there was an error or not
    }
  };

  // This code is used to process data for different types of graphs: a line graph requires cumulative data, whereas a bar graph does not need this processing.
  const addResults = (array) => {

    if(graphType === 'line' && array.length > 0){
      // Add the results to the previous result
      for (let i = 1; i < array.length; i++) {
        for (const key in array[i]) {
          if (typeof array[i][key] === 'number') {
            array[i][key] += array[i - 1][key];
          }
        }
      }
    } else if(graphType === 'bar' && array.length > 0){
      return
    } else{
      return
    }

  }

  useEffect(() => {
      queryResults().then((data) => {
              // filterDuplicateDates(data);
              data && addResults(data)
              if(outputs && activity){
                  data && setData(data)
              }
      })
  },[outputs, activity, activityData, graphType, startDate, endDate])

  return (
    <>
        <OutputMultiLineGraph data={data} isLoading={isLoading} outputs={outputs} graphType={graphType} />
    </>
  )
}

export default OutputData