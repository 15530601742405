import { ImpactAIData } from "../../StateManagment/ImpactAIData";
import { useContext } from "react";

// Prompt components

const role = (type) => {

    return (
      `Je bent een consultant op het gebied van fondsenwwerving.
      Je bent een expert op het gebied van ${type}.
      Je toon is vriendelijk en laagdrempelig.
      Je gesprekspartner is een sociale organisatie. Probeer te praten in simpele taal.
      Je gesprekspartner gebruikt de impactmanagementsoftware waarin jullie nu een gesprek hebben. `
    )
  
  }
  
  const save = (type) => {
  
    return (
      `Geef een samenvatting van de ${type} dat je hebt gecreëerd en vraag of de gebruiker dit wil gebruiken of er verder aan wil werken.
      Wanneer de gebruiker tevreden is met de ${type} vraag je hem of je de omschrijving op mag slaan in de software. 
      Geef aan dat je gespreekpartner moet typen: 'Opslaan" als hij tevreden is met de ${type}.
      Je mag de ${type} ALLEEN opslaan als het woord 'Opslaan' in de het LAATSTE BERICHT staat dat je gesprekspartner heeft getypt`
    )
  }
  
  const format = () => {
  
    return (
      `Formateer je antwoorden ALTIJD met de volgende HTML-tags: <p> </p>, <b> </b>, <i> </i> en/of <ul><li></li></ul>.
      Zorg ervoor dat je lange antwoorden opsplitst in meerdere paragrafen.`
    )
  
  }

// Dashboard
const AllFundsSystemMessage = (stringyfiedFilteredFunds) => {
    const {
        summary: [summary, setSummary],
        problemAnalyse: [problemAnalyse, setProblemAnalyse],
        targetgroups: [targetgroups, setTargetgroups],
        goals: [goals, setGoals],
        activities: [activities, setActivities],
        outputs: [outputs, setOutputs],
        effects: [effects, setEffects]
    } = useContext(ImpactAIData);  

    console.log(stringyfiedFilteredFunds)


    const systemMessage = {"role": "system", "content": `
  
    ${role('het vinden van geschikte fondsen')}
    
    Je helpt je gesprekspartner met het vinden van geschikte fondsen voor zijn project.
    Het project heeft deze:
    
    omschrijving: ${summary}, 
    probleemanalyse: ${problemAnalyse}, 
    doelgroepen: ${targetgroups}, 
    maatschappelijk doel: ${goals},
    activiteiten: ${activities} 
    en outputs: ${outputs}..
    en effecten: ${effects}.

    Hier is een overzicht van de fondsen die je gesprekspartner kan gebruiken voor zijn project:
    ${stringyfiedFilteredFunds}.

    Geef een lijst met fondsen die je gesprekspartner kan gebruiken voor zijn project.
    Je mag alleen fondsen selecteren uit de lijst die je gesprekspartner kan gebruiken voor zijn project.
  
    ${format()}
  
   
    `}
  
    return systemMessage
  
  }

  const AllFundsToolCallContent = () => {

    return (
      `Gebaseerd op ons gesprek, gelieve alle indicatoren te vermelden in het volgende JSON-arrayformaat, 
      waarbij elke indicator een apart object is: { "array": [{ "item": "indicator1" }, { "item": "indicator2" }, ...] }. 
      Ik wil elke indicator van ons gesprek als een afzonderlijk object in deze array zien."`
    )
  
  }
  
  const AllFundsSaveMessage = () => {
  
    return (
      `Ik heb het de inidctaoren opgeslagen. Je kunt het de opgeslagen indicatoren hiernaast terugvinden en/of aanpassen. 
      Je kunt nu je Theory of Change bekijken in de volgende stap.`
    )
  
  }

  export { 
    AllFundsSystemMessage,
    AllFundsToolCallContent,
    AllFundsSaveMessage
  }