import firebase from "firebase";

const createSuggestion = async (prompt, setSuggestion, setLoading) => {

    try {
        setLoading('block')

        const completionOnCallOld = firebase.functions().httpsCallable('completionOnCallOld')  

        const result = await completionOnCallOld({messages: prompt})

        console.log(result)

        const answer = result.data

        setLoading('none')

        console.log(answer)

        const array = JSON.parse(answer.content)

        console.log(array)

        setSuggestion(array)

    } catch (error) {
        console.log(error.message)
        setSuggestion([{item: 'Er is iets fout gegaan. Probeer het opnieuw.'}])
    }

}

export default createSuggestion