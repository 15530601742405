import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const PageNotice = ({text}) => {
  return (
    <div id='page-notice-container'>
        <ErrorOutlineOutlinedIcon id='page-notice-icon'/>
        <p>{text}</p>
    </div>
  )
}

export default PageNotice