import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { useFirestoreAll } from '../../firebase/useFirestore';

const MetaData = () => {

    const users = useFirestoreAll('Users')

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <AccountBalanceOutlinedIcon/>
                <h1>Meta data</h1>
            </div> 
            <div className="table-container dashboard-container">
                <h2>Emailadressen</h2>
                {users && users.map((user) => (
                    <div key={user.id} >
                        <div id='super-admin-meta-data-emails-item-container'>
                            <p>{user.Email}</p>
                            <p>{user.ForName}</p>
                            <p>{user.SurName}</p>
                        </div>
                    </div>
                ))}
                <div className='button-container'>
                    <button className='button'>Exporteer als CSV</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MetaData