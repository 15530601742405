import { useFirestoreNoCompagny } from "../../firebase/useFirestore"
import { useState, useEffect } from "react"
import { db } from "../../firebase/config"
import Location from "../../hooks/Location"
import { useHistory } from "react-router-dom"
import Hostname from '../../hooks/Hostname'
import firebase from 'firebase'
import spinner from '../../images/spinner-ripple.svg'
import verifyAccountBanner from '../../images/verify-account-banner.jpg'

const NotApproved = () => {
    const [loading, setLoading] = useState(false)

    const route = Location()[3]
    const host = Hostname()
    const history = useHistory()
    
    const verificationNotice = () => {

        // if(user === null){
        //     return  <div>
        //                 <h2>Je account is niet bekend bij ${host.Name}</h2>
        //                 <p>Er is een probleem ontstaan waardoor je account niet kan worden gevonden bij ${host.Name}. 
        //                     Neem contact op met de <a href="https://deccos.nl/Contact" target="_blank">klantenservice</a> om het probleem op te lossen.</p>
        //             </div>
        // } else if(user != null){
        //     return  <div>
        //                 <button onClick={verifiyAccount}>Verifieer je account</button>
        //             </div>
        // }
    }

    const verifiyAccount = async () => {

        setLoading(true)

        const updateUserAccountWhenVerified = firebase.functions().httpsCallable('updateUserAccountWhenVerified')  

        const result = await updateUserAccountWhenVerified({data: route}) 

        if(result.data === 'success') {
            setLoading(false)
            history.push('/login');
        } else if(result.data === 'error') {
            setLoading(false)
            alert(`Er is iets mis gegaan, neem contact op met Deccos via info@deccos.nl`)
        }
    }

    return (
        <div id='not-approved-container'>
            <div className="approval-message-container">
                <img src={verifyAccountBanner} alt="" />
                <h1>Verifieer je {host.Name} account</h1>
                <button style={{display: loading ? 'none' : 'block'}} onClick={verifiyAccount}>Verifieer account</button>
                <img src={spinner} alt="" style={{display: loading ? 'block' : 'none'}}/>
            </div>
        </div>
    )
}

export default NotApproved
