import { useState, useRef } from 'react'
import { db } from "../../firebase/config.js"
import dragIcon from '../../images/icons/drag-icon.png'

const DraggableTableItems = ({children, 
    item, 
    input, 
    parentIndex, 
    collection, 
    itemArray, 
    setHoverIndex, 
    hoverIndex,
    setPlaceholderIndex
}) => {

    // State
    const [docid, setDocid] = useState(null)
    const [dragIndex, setDragIndex] = useState(null)

    // Refs
    const inputRef = useRef(null);

    // Drag and drop
    const dragstart = (e) => {

        // Prevent drag if starting from an input element
        if (inputRef.current.id === 'input-container') {
            return; 
        }

        e.stopPropagation()
        const docid = e.target.dataset.docid
        const index = e.target.dataset.index

        const numberIndex = parseInt(index)

        setDocid(docid)
        setDragIndex(numberIndex)
        
    }

    const dragging = (e) => {
        e.stopPropagation()
        e.target.style.opacity = '0.1'
    }

    const dragover = (e) => {
        e.preventDefault()
        e.stopPropagation()
    
        const currentHoverIndex = e.target.dataset.index
        const numberHoverIndex = parseInt(currentHoverIndex)
    
        const parentRect = e.currentTarget.getBoundingClientRect();
        const offsetY = e.clientY - parentRect.top;
        const halfHeight = e.target.offsetHeight / 2;
    
        // Check if the mouse pointer is in the upper of lower half of the target element.
        if (offsetY < halfHeight) {
            // If upper half set the placeholder above the target row.
            setPlaceholderIndex(numberHoverIndex);
            // If upper half set the hoverIndex (the index of row being dragged over) to the current index of the target row.
            setHoverIndex(numberHoverIndex);
        } else if(offsetY > halfHeight) {
            // If bottom half set the placeholder below the target row.
            setPlaceholderIndex(numberHoverIndex + 1);
            // If bottom half set the hoverIndex (the index of row being dragged over) to the current index of the target row + 1.
            setHoverIndex(numberHoverIndex + 1);
        }
    }

    const dragend = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        e.target.style.opacity = '1'

        setPlaceholderIndex(null); // Reset placeholder
    
        const arrayCopy = [...itemArray] // copy array
        // arrayCopy.splice(dragIndex, 1) // remove item from array at dragIndex
        // arrayCopy.splice(hoverIndex, 0, {docid: docid}) // add item to array at hoverIndex
        // saveNewPositions(arrayCopy) // save new positions to firestore

        const itemToMove = arrayCopy.splice(dragIndex, 1)[0]; // Remove item from array at dragIndex

        // If moving down, adjust hoverIndex to compensate for the removed item's index shift
        const adjustedHoverIndex = dragIndex < hoverIndex ? hoverIndex - 1 : hoverIndex;
    
        arrayCopy.splice(adjustedHoverIndex, 0, itemToMove); // Add item to array at the adjusted hover index
        saveNewPositions(arrayCopy); // Save new positions to firestore
      };

   const saveNewPositions = (arrayCopy) => {
    console.log(arrayCopy)
        arrayCopy.forEach((item, index) => {
            // console.log(item, index, collection)
            db.collection(collection)
            .doc(item.docid)
            .update({
                Position: index + 1
            })
            .then(() => {
                console.log('position updated')
            })
        })
    }

  return (
    <tr 
    key={item.ID} 
    draggable='true' 
    data-index={parentIndex} 
    data-docid={item.docid}
    onDragStart={dragstart} 
    onDrag={dragging} 
    onDragOver={dragover}
    onDragEnd={dragend}
    >
        <td 
            draggable='false' 
            data-index={parentIndex} 
          >
            <div 
            className="table-item-title-container"
            data-index={parentIndex} 
            >
                <img 
                className="drag-icon"
                src={dragIcon} 
                alt="" 
                draggable='false' 
                data-index={parentIndex} 
                />
                <p 
                className="table-position-item" 
                data-index={parentIndex} 
                >{parentIndex + 1}
                </p>
                <div id='input-container' ref={inputRef}>
                    {input}
                </div>
                
            </div>
        </td>
        {children}
    </tr>
  )
}

export default DraggableTableItems