import { useFirestoreID } from "../../firebase/useFirestore";

const RouteMeta = ({routeId}) => {

    // Firestore
    const route = useFirestoreID('PersonaRoutes', routeId ? routeId : '')

  return (
    <>
        {route && route.map(item => (
            <>
                {item.Title}
            </>
        ))}
    </>
  )
}

export default RouteMeta