import { useContext } from "react"
import { db } from "../../../firebase/config";
import { SavedIcon } from "../../../StateManagment/SavedIcon";

const CategoryAnalysisColor = ({item}) => {
    const [saved, setSaved] = useContext(SavedIcon)

    const colorHandler = (e) => {
        const value = e.target.value

        const docid = e.target.dataset.docid

        db.collection('AnalysisCategories')
        .doc(docid)
        .update({
            Color: value
        })
        .then(() => {
            setSaved('flex')
        })
    }

  return (
   <>
    <input className="setting-color-picker" type="color" data-docid={item.docid} defaultValue={item.Color} onChange={colorHandler}/>
   </>
  )
}

export default CategoryAnalysisColor