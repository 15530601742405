import { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useFirestoreQuestionnairesResponses } from '../../firebase/useFirestore';

const MatrixBarChart = ({field, moment}) => {

    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    const results = useFirestoreQuestionnairesResponses(field, moment)

    const getColumnScore = (row, column) => {
        const array = []

        results && results.forEach(result => {
            const input = result.Input

            const columnRowArray = input.split('/')
            const resultColumn = columnRowArray[0]
            const resultRow = columnRowArray[1]

            if(row === resultRow && column === resultColumn) {
                array.push(column)
            }
        })

        return array.length
    }

    const getData = () => {

        const columnArray = []
        const rowArray = []

        results && results.forEach(result => {
            const input = result.Input

            // Get column and row
            const columnRowArray = input.split('/')
            const column = columnRowArray[0]
            const row = columnRowArray[1]

            // Filter duplicates and set rows
            if(!rowArray.includes(row)){
                rowArray.push(row)
            }
            
            // Filter duplicates and set columns
            if(!columnArray.includes(column)){
                columnArray.push(column)
            }

        })

        const dataArray = []

        rowArray.forEach(row => {
            const object = {
                name: row,
            }

            columnArray.forEach(column => {
                object[column] = getColumnScore(row, column)
            })

            dataArray.push(object)
        })

        setColumns(columnArray)
        setData(dataArray)
    }

    useEffect(() => {
        setTimeout(() => {
           columns && getData()
        }, 1000)
    }, [results, field, moment])
   
  return (
    <BarChart
          width={500}
          height={300}
          data={data && data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {columns && columns.map(column => (
            <Bar dataKey={column} fill="#8884d8" />
          ))}
          
        </BarChart>
  )
}

export default MatrixBarChart