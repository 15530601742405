import { useEffect, useState } from 'react'
import Location from "../../hooks/Location"
import { useFirestoreGeneralThreeOrderBy, useFirestoreGeneralThree, useFirestoreID} from '../../firebase/useFirestore'
import firebase from 'firebase/app'
import FieldMetaForms from '../../components/Questionnaire/FieldMetaForms'
import { db } from '../../firebase/config'

const ResearchResultsPersonasForms = () => {

    // State
    const [researchTitle, setResearchTitle] = useState('')
    const [personaTitle, setPersonaTitle] = useState('')
    const [convertedTimestamp, setConvertedTimestamp] = useState(null)

    // Hooks
    const timestamp = Location()[3]
    const researchId = Location()[4]
    const personaId = Location()[5]
    const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

    // Firestore
    const responses = useFirestoreGeneralThreeOrderBy('QuestionnairesResponses', 'ResearchID', researchId ? researchId : '', 'Persona', personaId, 'Timestamp', convertedTimestamp, 'Position', 'asc')
    const unorderdedResponses = useFirestoreGeneralThree('QuestionnairesResponses', 'ResearchID', researchId ? researchId : '', 'Persona', personaId, 'Timestamp', convertedTimestamp)
    const research = useFirestoreID('Research', researchId ? researchId : '')
    const personas = useFirestoreID('Personas', personaId ? personaId : '') 

    // Regular expression to extract seconds and nanoseconds
    useEffect(() => {
        const regex = /Timestamp\(seconds=(\d+), nanoseconds=(\d+)\)/;
        const matches = timestamp.match(regex);

        if (matches) {
            const seconds = parseInt(matches[1], 10);
            const nanoseconds = parseInt(matches[2], 10);

            const newTimestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);

            setConvertedTimestamp(newTimestamp)
        } else {
            console.log("No matches found.");
        }
    }, [timestamp])

    // Set the research title
    useEffect(() => {
        research  && research.forEach(item => {
            setResearchTitle(item.Title)
        })
    }, [research])

    // Set the persona title
    useEffect(() => {
        personas  && personas.forEach(item => {
            setPersonaTitle(item.Name)
        })
    }, [personas])

    const formDate = () => {
        const date = convertedTimestamp?.toDate().toLocaleDateString("nl-NL", options);
        return date
    }

    const updateResponsesWithPosition = () => {

        unorderdedResponses && unorderdedResponses.forEach(async (response, index) => {

            console.log(response.FieldID)

            await db.collection('QuestionnaireFields')
            .where('ID', '==', response.FieldID)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    db.collection('QuestionnairesResponses').doc(response.docid).update({
                        Position: doc.data().Position
                    })
                })
            })

        })

    }

    useEffect(() => {
        updateResponsesWithPosition()
    }, [unorderdedResponses])

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>{researchTitle}</h1>
                <h2>{personaTitle}</h2>
                <h2>{formDate()}</h2>
            </div>

            {responses && responses.map((response) => (
                <div key={response.id} className='dashboard-container'>
                    <FieldMetaForms fieldId={response.FieldID}/>
                    <p>{response.Input}</p>
                </div>
            ))}

        </div>
    </div>
  )
}

export default ResearchResultsPersonasForms