import Location from "../../hooks/Location"
import { useFirestoreID } from "../../firebase/useFirestore"
import { db } from "../../firebase/config"
import SavedNotice from "../../components/common/SavedNotice"
import { useContext } from "react"
import { SavedIcon } from '../../StateManagment/SavedIcon'

const PersonaMethodeDetails = () => {
       // Context
       const [saved, setSaved] = useContext(SavedIcon)

    // Hooks
    const companyId = Location()[3]

    // Firestore
    const methodes = useFirestoreID('PersonaMethodes', companyId ? companyId : '')

    // Title handler
    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('PersonaMethodes')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
        })
        
    }

    // Description handler
    const descriptionHandler = (e) => {

        const docid = e.target.dataset.docid
        const description = e.target.value

        db.collection('PersonaMethodes')
        .doc(docid)
        .update({
            Description: description
        })
        .then(() => {
            setSaved('flex')
        })
            
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Methode aanpassen</h1>
            </div>
            {methodes && methodes.map(methode => (
                <div key={methode.ID} id='persona-methode-details-container'>
                    <div className="table-container dashboard-container">
                        <h2>Titel</h2>
                        <input type="text" defaultValue={methode.Title} data-docid={methode.docid} onChange={titleHandler}/>
                    </div>

                    <div className="table-container dashboard-container">
                        <h2>Beschrijving</h2>
                        <textarea defaultValue={methode.Description} data-docid={methode.docid} onChange={descriptionHandler}/>
                    </div>
                </div>
            ))}
             <SavedNotice />
        
        </div>
    </div>
  )
}

export default PersonaMethodeDetails