import { useState, useContext } from "react"
import { useFirestoreMeasureMoments } from "../../firebase/useFirestore";
import ResponsesCount from "../../components/Research/ResponsesCount";
import { db } from "../../firebase/config.js"
import deleteIcon from '../../images/icons/delete-icon.png'
import sendIcon from '../../images/icons/send-icon.png'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import uploadIcon from '../../images/icons/upload-icon.png'
import Tooltip from "../../components/common/Tooltip";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import useSettings from "../../hooks/Settings";

const MeasureMoment = ({research}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const moments = useFirestoreMeasureMoments(research.ID)

    // Edit title
    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('MeasureMoments')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Change moment date
    const changeMomentDateHandler = (e) => {
        const date = e.target.value
        const docid = e.target.dataset.docid

        db.collection('MeasureMoments')
        .doc(docid)
        .update({
            Moment: date
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Delete moment
    const deleteMoment = (e) => {
        const docid = e.target.dataset.docid

        db.collection('MeasureMoments')
        .doc(docid)
        .delete()

    }

    return(
        <div className='table-container'>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        {/* <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                        <th style={{backgroundColor: secundairyColor}}>AANTAL REACTIES</th> */}
                        {/* <th style={{backgroundColor: secundairyColor}}>REACTIES UPLOADEN</th> */}
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>
                {moments && moments.map((moment, index) => (
                    <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={moment}
                        input={
                            <Tooltip content={moment.Title} width='80%' top='-35px'>
                                <input type="text" placeholder="Schrijf hier de titel" data-docid={moment.docid} defaultValue={moment.Title} onChange={titleHandler}/>
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'MeasureMoments'}
                        itemArray={moments}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={moment.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                            {/* <td>
                                <input type="date" defaultValue={moment.Moment} data-docid={moment.docid} onChange={changeMomentDateHandler} />
                            </td>
                            <td>
                                <ResponsesCount moment={moment.ID}/>
                            </td> */}
                            {/* <td>
                                <img className='table-delete-icon' src={uploadIcon} alt="" onClick={() => history.push(`/${client}/uploadresponses/${moment.QuestionnaireID}/${moment.ID}/${research.ID}`)}/>
                            </td> */}
                            <td>
                                <img className='table-delete-icon' data-docid={moment.docid} onClick={deleteMoment} src={deleteIcon} alt="" />
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
        </div>
    )
}

export default MeasureMoment