import firebase from 'firebase'
import { bucket } from '../firebase/config';

const saveFile = (e, setFile, setFileMeta) => {

    console.log(e.target.files[0])
    const file = e.target.files[0] 

    setFileMeta && setFileMeta(file)

    const storageRef = bucket.ref(file.name);
    const uploadTask = storageRef.put(file)

    uploadTask.then(() => {
      
        uploadTask.on('state_changed', snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused');
            break;
        case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running');
            break;
        }
        }, (err) => {
            alert(err)
        }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        console.log('File available at', downloadURL);

            setFile(downloadURL)

            })
        })
    })

}

export default saveFile;