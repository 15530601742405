import Breadcrumb from "../../components/common/Breadcrumb";
import { useFirestoreID, useFirestore, useFirestoreGeneralTwoOrderBy, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import { useState, useEffect, useContext } from "react";
import useSettings from "../../hooks/Settings";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import Development from '../../components/reserachAnalyses/development/Development'
import Field from '../../components/reserachAnalyses/measureMoment/Field'

const ResearchResultsVisualisations = () => {
     // Context
     const [saved, setSaved] = useContext(SavedIcon)

     // State
     const [question, setQuestion] = useState('')
     const [momentID, setMoment] = useState('')
     const [questionaireId, setQuestionaireId] = useState('')
     const [allDocids, setAllDocids] = useState([])
 
     // Hooks
     ScrollToTop()
     const fieldID = Location()[3]
     const researchID = Location()[4]
     const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
     const secundairyColor = useSettings().SecundairyColor
 
     // Firestore
     const results = useFirestoreGeneralTwoOrderBy('QuestionnairesResponses', 'FieldID', fieldID ? fieldID : '', 'MomentID', momentID ? momentID : '', 'Position', 'asc' )
     const moments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchID ? researchID : '', 'Position', 'asc')
     const fields = useFirestoreID('QuestionnaireFields', fieldID ? fieldID : '')

     // Set question in state
    useEffect(() => {
        fields && fields.forEach(field => {
            setQuestion(field.Question)
        })
    },[fields])


  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>Visualisaties</h1>
                <p>{question}</p>
            </div>
            <div className="table-container dashboard-container">
                {/* If the research has multiple measure moments, show the difference and development */}
                {moments.length > 1 ?
                    <table>
                        <tr>
                            {moments && moments.map(moment => (
                                <th style={{backgroundColor: secundairyColor}}>{moment.Title.toUpperCase()}</th>
                            ))}
                            <th style={{backgroundColor: secundairyColor}}>ONTWIKKELING</th>
                        </tr>
                        {fields && fields.map(field => (
                            <>
                                {field.Type !== 'section' &&
                                    <tr key={field.ID}>
                                        {moments && moments.map(moment => (
                                            <Field field={field} moment={moment} researchID={researchID}/>
                                        ))}
                                        <td>
                                            <Development field={field} researchID={researchID}/>
                                        </td>
                                    </tr>
                                }
                            </>
                        ))}
                    </table>
                    
                // If research has only one measure moment, show the fields
                :
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                            {moments && moments.map(moment => (
                                <th style={{backgroundColor: secundairyColor}}>{moment.Title.toUpperCase()}</th>
                            ))}
                        </tr>
                        {fields && fields.map(field => (
                            <>
                                {field.Type !== 'section' &&
                                <tr key={field.ID}>
                                    <td>
                                        <p>{field.Question}</p>
                                    </td>
                                    {moments && moments.map(moment => (
                                        <Field field={field} moment={moment} researchID={researchID}/>
                                    ))}
                                </tr>
                                }
                            </>
                            
                        ))}
                    </table>
                }
            </div>
            <Breadcrumb
            section={'Meten'}
            previousPage={'Onderzoeksresultaten'}
            previousPageUrl={`ResearchResults/${researchID}`}
            nextPage={''}
            nextPageUrl={''}
            counter={2}
            totalSteps={5}
            />
        </div>
    </div>
  )
}

export default ResearchResultsVisualisations