import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useFirestoreGeneral } from "../../firebase/useFirestore";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import Tooltip from '../common/Tooltip';

const CategorizeFieldNotice = ({fieldID, researchID, type}) => {

    // Hooks
    const history = useHistory()

    // Firestore
    const categories = useFirestoreGeneral('AnalysisCategories', 'FieldID', fieldID ? fieldID : '')

  return (
    <div>
        {type === 'paragraph' 
        ?
        <div>
            {categories.length === 0 
            ?
            <div className='categorisation-notice-item-container necessary' onClick={() => {history.push(`/${client}/researchresultsopenquestionanalysis/${fieldID}/${researchID}`)}}>
                <Tooltip content='Categoriën nog niet aangemaakt' top={'0px'} left={'35px'}>
                    <ErrorOutlineOutlinedIcon/>
                </Tooltip>
            </div>
            :
            <div className='categorisation-notice-item-container done' onClick={() => {history.push(`/${client}/researchresultsopenquestionanalysis/${fieldID}/${researchID}`)}}>
                <Tooltip content='Categoriën zijn aangemaakt' top={'-30px'} left={'35px'}>
                    <CheckCircleOutlinedIcon/>
                </Tooltip>
            </div>
            }       
        </div>
        :
        <div className='categorisation-notice-item-container not-necessay' onClick={() => {history.push(`/${client}/researchresultsopenquestionanalysis/${fieldID}/${researchID}`)}}>
                <Tooltip content='Categorisatie niet nodig' top={'-30px'} left={'35px'}>
                    <DoDisturbOnOutlinedIcon/>
                </Tooltip>
            </div>
        }
    </div>
  )
}

export default CategorizeFieldNotice