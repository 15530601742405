import { useFirestoreID } from "../../firebase/useFirestore"
import LiveReportEffectData from "./LiveReportEffectData"
import LiveReportDashboardMedia from "./LiveReportDashboardMedia"

const LiveReportEffects = ({effect, startDate, endDate}) => {

    const effects = useFirestoreID('OutputEffects', effect ? effect : '' )

  return (
    <div className="report-activities-container">
        {effects && effects.map(item => (
            <div className="report-activity-item-container" style={{borderBottom: effects.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Effect}</h3>
                    <p>{item.Description}</p>
                    <LiveReportDashboardMedia parent={item}/>
                </div>
                <div className="report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <LiveReportEffectData effectId={item.ID} startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        ))}
    </div>
  )
}

export default LiveReportEffects