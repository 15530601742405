import { useFirestoreGeneralOrderBy,  useFirestoreOrderBy} from "../../firebase/useFirestore";
import { useContext, useState } from "react";
import { db } from "../../firebase/config.js"
import { client } from '../../hooks/Client';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useHistory } from "react-router-dom";
import Location from "../../hooks/Location"
import firebase from 'firebase'

const SelectKPIs = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [questionnaireID, setQuestionnaireID] = useState(null)
    const [checkedQuestions, setCheckedQuestions] = useState([]);

    // Hooks
    const history = useHistory()
    const effectId = Location()[3]

    // Firestore
    const questions = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionnaireID ? questionnaireID : '123', 'Position', 'asc' )
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')

    // HANDLERS
    // Update the squestionnaire research
    const questionnaireHandler = (e) => {
        e.preventDefault()
        const id = e.target.options[e.target.selectedIndex].value

        setQuestionnaireID(id)
    }

    // Update the selected question
    const handleCheckboxChange = (id, type, question, docid) => {
        setCheckedQuestions(prevState => {
            const index = prevState.findIndex(item => item.id === id);
            if (index > -1) {
                // The question is currently checked, remove it from the array
                return prevState.filter((_, i) => i !== index);
            } else {
                // The question is not checked, add it to the array
                return [...prevState, { id, type, question, docid }];
            }
        });
    };

    // Save the proof
    const saveIndicators = (e) => {
        checkedQuestions.map((item) => {

            db.collection("QuestionnaireFields")
            .doc(item.docid)
            .update({
                EffectId: firebase.firestore.FieldValue.arrayUnion(effectId),
                Indicator: 'true'
            })
            .then(() => {
                setSaved('flex')
                history.push(`/${client}/addindicators/${effectId}`)
            })

        })

    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>Onderbouw met onderzoek</h1>
            </div>
            <div className="table-container dashboard-container">
                <div>
                    <h2>1. Selecteer vragenlijst</h2>
                    <select name="" id="" onChange={questionnaireHandler}>
                        <option value="">-- selecteer vragenlijst --</option>
                        {questionnaires && questionnaires.map(item => (
                            <option key={item.ID} value={item.ID}>{item.Title}</option>
                        ))}
                    </select>
                </div>
                <div style={{ display: questionnaireID !== '' ? 'block' : 'none' }}>
                    <h2>2. Selecteer vragen</h2>
                    <div>
                        {questions && questions.map(item => (
                            <div key={item.ID} id='kpi-select-questions-item-container'>
                                {item.Type !== 'section' &&
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checkedQuestions.some(q => q.id === item.ID)}
                                            onChange={() => handleCheckboxChange(item.ID, item.Type, item.Question, item.docid)}
                                        />
                                        {item.Question}
                                    </label>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <button style={{display: checkedQuestions.length > 0 ? 'block' : 'none'}} id='kpi-research-save-button' onClick={saveIndicators}>Opslaan</button>
            </div>
        </div>
    </div>
  )
}

export default SelectKPIs