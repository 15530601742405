import Location from "../../hooks/Location"
import { useFirestoreGeneralOrderBy, useFirestoreID, useFirestoreGeneral } from "../../firebase/useFirestore"
import { useState, useEffect, useContext } from 'react';
import { db, timestamp } from "../../firebase/config";
import { client } from "../../hooks/Client";
import uuid from 'react-uuid';
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import plusButton from '../../images/icons/plus-icon.png'
import eyeIcon from '../../images/icons/eye-icon.png'
import Tooltip from '../../components/common/Tooltip';
import { useHistory } from "react-router-dom";
import useSettings from "../../hooks/Settings";
import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import { QuestionaireFieldsSystemMessage, QuestionaireFieldsToolCallContent, QuestionaireFieldsSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from "../../StateManagment/ImpactAI";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import robotImage from "../../images/icons/robot-icon.png"
import OrderQuestionnaireFieldsView from "../../components/Questionnaire/OrderQuestionnaireFieldsView";
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import QuestionmarkIcon from '../../images/icons/questionmark-icon.png'
import MeetstandardIcon from '../../images/meetstandaard-icon.png'

const QuestionaireBuilder = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        position: [position, setPosition],
    } = useContext(ImpactAI);

    // State
    const [title, setTitle ] = useState('')
    const [pageDocid, setPageDocid ] = useState('')
    const [itemArray, setItemArray] = useState([])
    const [ID, setID] = useState('')
    const [questionniareDescription, setQuestionnaireDescription] = useState('')
    const [targetgroupID, setTargetgroupID] = useState(null)
    const [targetgroupTitle, setTargetgroupTitle] = useState('')
    const [targergroupEffects, setTargetgroupEffects] = useState('')
    const [showAddContainer, setShowAddContainer] = useState(false)

    // Hooks
    const route = Location()[3]
    const researchID = Location()[4]
    ScrollToTop()
    const history = useHistory()
    const TertairyColor = useSettings().TertairyColor

    // Firestore
    const questionnares = useFirestoreID('Questionnaires', route)
    const questionnaireFields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', route, 'Position', 'asc')
    const research = useFirestoreID('Research', researchID ? researchID : '')
    const targetgroups = useFirestoreID('Stakeholders', targetgroupID ? targetgroupID : '')
    const effects = useFirestoreGeneral('OutputEffects', 'Targetgroup', targetgroupID ? targetgroupID : '')

    //ImpactAI
    const pageSystemMessage = QuestionaireFieldsSystemMessage(title, targergroupEffects, targetgroupTitle)
    const pageToolCallContent = QuestionaireFieldsToolCallContent()
    const pageSaveMessage = QuestionaireFieldsSaveMessage()
    const pageStartMessage = `Hoi ${auth?.ForName}. Hier gaan we de vragen creëeren voor de vragenlijst '${title}'.
    Zal ik een voorstel doen?`
    const pageCollection = 'QuestionnaireFields'
    const pageField = 'Question'
    const pageType = 'questionaireFields' + ID

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(ID)
        setExampleMessages([])
        setDocid(pageDocid)
        setPosition(questionnaireFields.length + 1)
    }, [auth, targergroupEffects, targetgroupTitle, questionnaireFields])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, graag!')
    }

    // Set the targetgroupID in state
    useEffect(() => {
        research && setTargetgroupID(research[0]?.TargetgroupID)
    }, [research])

    // Set the targetgroup title in state
    useEffect(() => {
        targetgroups && setTargetgroupTitle(targetgroups[0]?.Organisation)
    }, [targetgroups])

    // Set the targetgroup effects in state
    useEffect(() => {

        const array = []

        effects && effects.forEach(effect => {
            array.push(effect.Effect)
        })

        setTargetgroupEffects(array.join(', '))

    }, [effects])

    // UpdateQuestionnaireFields 
    useEffect(() => {
        questionnaireFields && questionnaireFields.forEach((field, index) => {
            db.collection('QuestionnaireFields')
            .doc(field.docid)
            .update({
                CompagnyID: field.Compagny
            })
        })
    }, [questionnaireFields])

    // Set title, docid, ID and description of questionaire to state
    useEffect(() => {
        questionnares && questionnares.forEach(questionnare => {
            setTitle(questionnare.Title)
            setPageDocid(questionnare.docid)
            setID(questionnare.ID)
            setQuestionnaireDescription(questionnare.Description)
        })
    }, [questionnares])

    // Set fields array
    useEffect(() => {
        const array = []
        questionnaireFields && questionnaireFields.forEach(item => {
            const object = {
                fieldDocid: item.docid
            }
            array.push(object)
        })
        setItemArray(array)
    },[questionnaireFields])

    // Update questionaire title
    const titleHandler = (e) => {

        const title = e.target.value 

        setTitle(title)

        db.collection('Questionnaires')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Add field to database
    const addField = (e) => {

        const position = questionnaireFields.length + 1

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Timestamp: timestamp,
            QuestionnaireID: route,
            Type: 'paragraph',
            Question: 'Vraag ' + position,
            Explainer: '',
            ReachStart: 0,
            ReachStartLable: '',
            ReachEnd: 5,
            ReachEndLabel: '',
            Key: uuid(),
            Position: position,
            Multiple: [],
            SectionID: '',
            LiveReportHidden: false,
        })
    }

    // Update questionaire description
    const descriptionHandler = (e) => {
        const description = e.target.value

        db.collection('Questionnaires')
        .doc(docid)
        .update({
            Description: description
        })
        .then(() => {
            setSaved('flex')
        })
    }

    // Add a section to the questionnaire (and database)
    const addSection = () => {

        const position = questionnaireFields.length + 1

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Timestamp: timestamp,
            QuestionnaireID: route,
            Type: 'section',
            Question: 'Sectietitel',
            Explainer: '',
            ReachStart: 0,
            ReachStartLable: '',
            ReachEnd: 5,
            ReachEndLabel: '',
            Key: uuid(),
            Position: position,
            Multiple: [],
            SectionID: '',
            Color: TertairyColor,
            Title: 'Sectietitel',
        })
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <ListAltOutlinedIcon/>
                <h1>Vragenlijst instellingen</h1>
            </div>
            <p><b>Titel vragenlijst</b></p>
            <input type="text" placeholder='Titel' defaultValue={title} onChange={titleHandler} />
            <p><b>Omschrijving</b></p>
            <textarea name="" id="questionnaire-builder-description-textarea" cols="30" rows="10" placeholder='Voeg een omschrijving toe van je vragenlijst' defaultValue={questionniareDescription} onChange={descriptionHandler}></textarea>
            <p><b>Vragen</b></p>
            <div className="questionnairefields-container">

                <div className="order-questions-container">
                    <OrderQuestionnaireFieldsView questionnaireFields={questionnaireFields}/>
                </div>

                <div className='add-questionnairefield-container' >
                    <Tooltip content='Vraag toevoegen' top='-10px' left='30px' width='30px'>
                        <img src={plusButton} style={{display: showAddContainer ? 'none' : 'block'}} onClick={() => setShowAddContainer(!showAddContainer)} alt="" />
                    </Tooltip>
                    <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                        <div className="add-options-container-item-container" onClick={() => {history.push(`/${client}/addstandardeffectstoquestionaire/${route}/${researchID}`)}}>
                            <img src={MeetstandardIcon} alt="" />
                            <p>Gestandaardiseerde effecten toevoegen</p>
                        </div>
                        <div className="add-options-container-item-container" onClick={() => {history.push(`/${client}/addindicatorstoquestionaire/${route}/${researchID}`)}}>
                            <img src={plusButton} alt="" />
                            <p>Indicator toevoegen</p>
                        </div>
                        <div className="add-options-container-item-container" onClick={addField}>
                            <img src={plusButton} alt="" />
                            <p>Ongekoppelde vraag toevoegen</p>
                        </div>
                        <div className="add-options-container-item-container" onClick={addSection}>
                            <img src={plusButton} alt="" />
                            <p>Sectie toevoegen</p>
                        </div>
                    </div>
                   <div style={{display: showAddContainer ? 'none' : 'block'}}>
                        <Tooltip content={'Kan ik je helpen?'} top='-10px' left='30px' width='30px'>
                            <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                        </Tooltip> 
                   </div>
                   <div style={{display: showAddContainer ? 'none' : 'block'}}>
                        <Tooltip content='Vragenlijst voorbeeld' top='-10px' left='30px' width='30px'>
                            <img src={eyeIcon} alt="" style={{display: questionnaireFields.length > 0 ? 'block' : 'none'}} onClick={() => window.open(`/${client}/questionnaireopen/${ID}/${ID}/${ID}/open/test`, '_blank')}/>
                        </Tooltip>
                   </div>
                    
                </div>

            </div>
        </div>
    </div>
  )
}

export default QuestionaireBuilder