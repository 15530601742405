import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import { useFirestoreOrderBy, useFirestoreGeneral } from '../../firebase/useFirestore';
import { useState } from 'react';
import editIcon from '../../images/icons/edit-icon.png'
import plusButton from '../../images/icons/plus-icon.png'
import Tooltip from '../../components/common/Tooltip';
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import { db } from '../../firebase/config';
import { timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { client } from "../../hooks/Client"
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import robotImage from "../../images/icons/robot-icon.png"
import IndicatorsCount from '../../components/Indicators/IndicatorsCount';
import questionMarkIcon from "../../images/icons/questionmark-icon.png"
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';

const Indicators = () => {
    // State
    const [effectId, setEffectId] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [showAddIcons, setShowAddIcons] = useState(false)

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Firestore
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc');
    const fields = useFirestoreGeneral('QuestionnaireFields', 'EffectId', effectId ? effectId : '');
    const outputs = useFirestoreOrderBy('Outputs', 'Position', 'asc');
    const indicatorOutputs = useFirestoreGeneral('Outputs', 'EffectId', effectId ? effectId : '');

    // ImpactAI
    const impactaiHandler = () => {
    }

    const expandEffectHandler = (e) => {

        const id = e.target.dataset.id 

        if (effectId === id) {
            setEffectId(null)
            return
        }

        setEffectId(id)
    }

     // Add field to database
     const addQuestion= (e) => {

        const id = e.target.dataset.id

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Timestamp: timestamp,
            QuestionnaireID: '',
            Type: 'scale',
            Question: 'Vraag',
            Explainer: '',
            ReachStart: 0,
            ReachStartLable: '',
            ReachEnd: 5,
            ReachEndLabel: '',
            Multiple: [],
            SectionID: '',
            EffectId: id,
            Indicator: 'true'
        })
    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Question: e.target.value
        })
    }

    const deleteQuestion = (e) => {

        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            effectId: ''
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <HighlightAltOutlinedIcon/>
                <h1>Indicatoren</h1>
            </div>
                {effects && effects.map((effect) => (
                    <div key={effect.ID} id='indicator-effect-item-container' className="table-container dashboard-container">
                        <div id='indicator-effect-item-title-container'>
                            <p><b>{effect.Effect}</b></p>
                            <div id='indicator-effect-icons-container'>
                                <Tooltip content='Aantal indicatoren' width={'30px'} top='0px' left='-160px'>
                                    <p><IndicatorsCount effectId={effect.ID} /></p>
                                </Tooltip>
                                <Tooltip content='Indicatoren aanpassen' width={'30px'} top='0px' left='-160px'>
                                    <img src={editIcon} alt="" data-id={effect.ID} onClick={expandEffectHandler}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div id='indicator-indicators-container' style={{display: effectId === effect.ID ? 'block' : 'none'}}>
                            <div className='activity-meta-title-container'>
                                <img src={questionMarkIcon} alt="" />
                                <h2>Vragen</h2>
                            </div>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                                </tr>
                                {fields && fields.map((field, index) => (
                                        <>
                                        {placeholderIndex === index && (
                                            <tr className="placeholder-row">
                                                <td style={{backgroundColor: secundairyColor}}></td>
                                            </tr>
                                        )}
                                        <DraggableTableItems 
                                        item={field}
                                        input={
                                            <Tooltip content={field.Question} width='100%' top='-40px'>
                                                <input placeholder='Schrijf hier de vraag' defaultValue={field.Question} data-docid={field.docid} onChange={titleHandler}/>
                                            </Tooltip>
                                            }
                                        parentIndex={index}
                                        collection={'Indicators'}
                                        itemArray={fields}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={field.Question}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                        >
                                        <td>
                                            <Tooltip content={'Indicator verwijderen'} width='80%' top='-60px'>
                                                <img className='table-delete-icon' data-docid={field.docid} src={deleteIcon} alt="eye icon" onClick={deleteQuestion} />
                                            </Tooltip>
                                        </td>
                                        </DraggableTableItems>
                                    </>
                                ))}
                            </table>
                            <div className='add-row-container'>
                                <Tooltip content={'Vraag toevoegen'} width='80%' top='-40px'>
                                    <img src={plusButton} alt="" data-id={effect.ID} onClick={addQuestion}/>
                                </Tooltip>
                            </div>

                            <div className='activity-meta-title-container'> 
                                <OutputOutlinedIcon/>
                                <h2>Outputs</h2>
                            </div>
                            <table>
                                <tr>
                                    <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                                </tr>
                                {fields && fields.map((field, index) => (
                                        <>
                                        {placeholderIndex === index && (
                                            <tr className="placeholder-row">
                                                <td style={{backgroundColor: secundairyColor}}></td>
                                            </tr>
                                        )}
                                        <DraggableTableItems 
                                        item={field}
                                        input={
                                            <Tooltip content={field.Question} width='100%' top='-40px'>
                                                <input placeholder='Schrijf hier de vraag' defaultValue={field.Question} data-docid={field.docid} onChange={titleHandler}/>
                                            </Tooltip>
                                            }
                                        parentIndex={index}
                                        collection={'Indicators'}
                                        itemArray={fields}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={field.Question}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                        >
                                        <td>
                                            <Tooltip content={'Indicator verwijderen'} width='80%' top='-60px'>
                                                <img className='table-delete-icon' data-docid={field.docid} src={deleteIcon} alt="eye icon" onClick={deleteQuestion} />
                                            </Tooltip>
                                        </td>
                                        </DraggableTableItems>
                                    </>
                                ))}
                            </table>
                            <div className='add-row-container'>
                                <Tooltip content={'Output toevoegen'} width='80%' top='-40px'>
                                    <img src={plusButton} alt="" data-id={effect.ID} onClick={addQuestion}/>
                                    <div>
                                        
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    </div>
  )
}

export default Indicators