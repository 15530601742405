import { useFirestoreGeneralArrayContains } from '../../firebase/useFirestore';
import { useEffect, useState } from 'react';

const IndicatorsCount = ({effectId}) => {
    // State
    const [counter, setCounter] = useState(0);

    // Firestore
    const fields = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '');
    const outputs = useFirestoreGeneralArrayContains('Outputs', 'EffectId', effectId ? effectId : '');

    // Update counter
    useEffect(() => {
          setCounter(fields.length + outputs.length);
    }, [fields, outputs])

  return (
    <>
        {counter}
    </>
    
  )
}

export default IndicatorsCount