import { PieChart as RechartsPieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

const PieChart = ({ data }) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{fontSize: '14px'}}>
        {data[index].name} - {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsPieChart>
        <Pie
          data={data && data.length > 0 ? data : [{ name: 'No data', score: 0 }]}
          cx={'50%'}
          cy={'50%'}
          innerRadius={0}
          outerRadius={130}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="score"
          labelLine={false}
          label={renderCustomizedLabel}
          
        >
           <Legend 
          layout="vertical" 
          align="right" 
          verticalAlign="middle"
          wrapperStyle={{
            position: 'absolute',
            left: 0, // Adjust as needed to ensure it's visible
            paddingLeft: 20,
          }}
          />
          {data && data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />
          ))}
          
        </Pie>
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
