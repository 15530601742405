import { useEffect } from "react"; 

 const useScrollToBottom = (messages, chatContainerRef) => {
    useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
        const start = chatContainer.scrollTop;
        const end = chatContainer.scrollHeight;

        const change = end - start;
        const duration = 400; // Duration in milliseconds
        let startTime = null;

        function animation(currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        chatContainer.scrollTop = start + change * progress;

        if (timeElapsed < duration) {
            requestAnimationFrame(animation);
        }
        }

        requestAnimationFrame(animation);
    }
    }, [messages])
};

export default useScrollToBottom;