import React from 'react'
import Tooltip from "../common/Tooltip";
import { useFirestoreGeneral, useFirestoreID } from '../../firebase/useFirestore';
import { db } from '../../firebase/config';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext } from "react";


const OutputIsSyncedTitle = ({outputID}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // Firestore
    const outputs = useFirestoreID('Outputs', outputID)
    const syncs = useFirestoreGeneral('Synchronisations', 'ProjectOutput', outputID ? outputID : '',)

    // Update title
    const outputHandler = (e) => {
        const title = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
        })
    }

  return (
    <>
        {outputs && outputs.map(output => (
            <>
                {syncs.length > 0 ?
                    <Tooltip content='Deze output is gesynchroniseerd en kan niet worden aangepast' width='100%' top='65px'>
                        <p className='synced-output-title'>{output.Title}</p>
                    </Tooltip>
                    :
                    <Tooltip content={output.Title} top='65px' width='100%'>
                        <input type="text" data-docid={output.docid}  defaultValue={output.Title} placeholder='Output' onChange={outputHandler} />
                    </Tooltip>
                }
            </>
        ))}
    </>
  )
}

export default OutputIsSyncedTitle