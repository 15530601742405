import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const HomeMultipleBarChart = ({data, otherInputs}) => {

      const getPath = (x, y, width, height) => {
        return `M${x},${y}
        L${x + width},${y}
        L${x + width},${y + height}
        L${x},${y + height}
        Z`;
      };
      
      const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
      
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
      };

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          if(label === 'Anders'){
            return (
              <div className="custom-tooltip">
                <p className="label">{`${label}: ${payload[0].value}`}</p>
                <p className="label">{`Reacties:`}</p>
                <ul>
                  {otherInputs && otherInputs.map(input => (
                    <li>{input}</li>
                  ))}
                </ul>
              </div>
            );
          }
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
          );
        }
      
        return null;
      };

  return (
    <ResponsiveContainer width="90%" height={300}>
        <BarChart
            data={data && data.length > 0 ? data : [{name: 'No data', uv: 0}]}
            margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />}/>
            <Bar dataKey="score" shape={<TriangleBar />} label={{ position: 'top' }}>
            {data ? data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color}/>
            ))
            : null}
            </Bar>
        </BarChart>
    </ResponsiveContainer>
  )
}

export default HomeMultipleBarChart