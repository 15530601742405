import { useFirestoreID } from '../../firebase/useFirestore'
import LiveReportDashboardMedia from './LiveReportDashboardMedia'

const LiveReportTargetgroupsDashboard = ({report}) => {

    const targetgroups = useFirestoreID('Stakeholders', report && report)

  return (
    <>
        {targetgroups && targetgroups.map(item => (
            <div className="report-targetgroup-item-container" style={{borderBottom: targetgroups.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Organisation}</h3>
                    <p>{item.Description}</p>
                </div>
                <div className="report-targetgroup-media-container">
                    <LiveReportDashboardMedia parent={item}/>
                </div>
            </div>
        ))}
    </>
  )
}

export default LiveReportTargetgroupsDashboard