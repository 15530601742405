import { useEffect, useState } from "react"
import { useFirestoreGeneral } from "../../firebase/useFirestore"
import SimpleBarChart from "../Visualisations/SimpleBarChart"

const MatrixOneData = ({field, startDate, endDate, graphType}) => {
  const [data, setData] = useState([])
  const [totalAverage, setTotalAverage] = useState(0)

  const responses = useFirestoreGeneral('QuestionnairesResponses', 'FieldID', field)

  // Get total average
  const getTotalAverage = () => {

    const average = []

    // Get all responses
    responses && responses.map(response => {
      const input = response.Input

      average.push(parseInt(input))

    })

    // Calculate total average
    const total = average.reduce((a, b) => a + b, 0)
    const totalAverage = total / average.length
    setTotalAverage(totalAverage)
  }

  // Get data
  const getData = () => {
    const tempData = [];
  
    // Get all responses
    responses &&
      responses.forEach((response) => {
        const columnTitle = response.RowTitle;
        const input = parseInt(response.Input, 10); // Parse as integer
  
        // Find the existing entry with the same columnTitle
        const existingEntry = tempData.find((e) => e.name === columnTitle);
  
        if (existingEntry) {
          // If the entry exists, update its 'uv' value and increment the count
          existingEntry.totalInput += input;
          existingEntry.count += 1;
          existingEntry.uv = existingEntry.totalInput / existingEntry.count; // Calculate the average
        } else {
          // If the entry does not exist, add it to the array with initial values
          tempData.push({
            name: columnTitle,
            score: input, // Initially set to the first input value
            totalInput: input, // Track the total input
            count: 1, // Track the number of responses
          });
        }
      });
  
    setData(tempData.map(({ name, score }) => ({ name, score }))); // Set data, keeping only name and average (uv)
  };

  useEffect(() => {
    getTotalAverage()
    getData()
  }, [responses])

  return (
    <>
      <div id='matrix-one-data-total-average-container'>     
        <p><b>Totale gemiddeld</b></p>
        <p>{totalAverage}</p>
      </div>
      <SimpleBarChart data={data} />
    </>
  )
}

export default MatrixOneData