import { useFirestoreID } from '../../firebase/useFirestore'

const PersonaNameMeta = ({personaID}) => {

    const users = useFirestoreID('Personas', personaID ? personaID : '123')

  return (
    <>
        {users && users.map( user => (
            <p key={user.ID}>
                {user.Name}
            </p>
        ))}
    </>
  )
}

export default PersonaNameMeta