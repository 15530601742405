import Location from "../../hooks/Location";
import { client } from "../../hooks/Client";
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { usePageConfig } from "../../hooks/usePageConfig";

const TopBarBreadCrumb = () => {
    const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);
    const { getBreadcrumbTrail } = usePageConfig();
    const pageName = Location()[2];
    const history = useHistory();

    // Generate Breadcrumbs based on page hierarchy
    const generateBreadcrumbs = (breadcrumbs) => (
        <div id="topbar-breadcrumb-links-container">
            <p onClick={() => history.push(`/${client}`)}>
                Home {'>'}
            </p>
            {breadcrumbs.map((breadcrumb, index) => (
                <p
                    key={index}
                    onClick={() => {
                        history.push(`/${client}/${breadcrumb.link}`);
                        setActive(breadcrumb.link);
                        setActiveSubItem(breadcrumb.subItem || "");
                    }}
                >
                    {breadcrumb.name}
                    {index < breadcrumbs.length - 1 && ' >'}
                </p>
            ))}
        </div>
    );

    // Retrieve the breadcrumb trail based on the page name
    const renderBreadCrumb = () => {
        const breadcrumbs = getBreadcrumbTrail(pageName);
        return generateBreadcrumbs(breadcrumbs);
    };

    return <div id="topbar-breadcrumb-container">{renderBreadCrumb()}</div>;
};

export default TopBarBreadCrumb;

