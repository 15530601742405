import { useFirestoreGeneralOrderBy, useFirestoreGeneral } from '../../firebase/useFirestore';
import PersonaGoalData from '../../components/personas/PersonaGoalData';
import MixedBarChart from '../../components/Visualisations/MixedBarChart';
import { useEffect, useState, useContext } from 'react';
import CalendarIcon from '../../images/icons/calendar-icon.png';
import { db, timestamp } from '../../firebase/config';
import { Auth } from '../../StateManagment/Auth';
import { client } from "../../hooks/Client";
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import Presence from '../../components/personas/Dashboard/Presence';

const Dashboard = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
  // Context
  const [auth] = useContext(Auth);

  // State
  const [showGoalDetails, setShowGoalDetails] = useState('');

  // Hooks
  const history = useHistory();

  // Firestore
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', item.ID ? item.ID : '');

  
  // Function to calculate the current date on the timeline
  const currentDate = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    const totalDays = (endDateObject - startDateObject) / (1000 * 60 * 60 * 24);
    const differenceInDaysFromStart = (currentDate - startDateObject) / (1000 * 60 * 60 * 24);
    const percentageDifference = (differenceInDaysFromStart / totalDays) * 100;

    return (
      <div id='timeline-current-date-container' style={{ width: `${percentageDifference}%` }}>
        <p>Vandaag</p>
        <div id='timeline-current-date'></div>
      </div>
    );
  };

  return (
    <div style={{ display: personaNavigation === 'dashboard' ? 'block' : 'none' }}>
      <h2>Dashboard</h2>

      <div className='table-container dashboard-container'>
        <h2>Tijdlijn</h2>
        <div id='timeline-container'>
          <div>
            <h3>Startdatum</h3>
            <p>{item.StartDate}</p>
          </div>
          <div id='timeline-activities-container'>
            {currentDate(item.StartDate, item.EndDate)}
          </div>
          <div>
            <h3>Einddatum</h3>
            <p>{item.EndDate}</p>
          </div>
        </div>
      </div>

      <Presence item={item} courseStartDate={courseStartDate} courseEndDate={courseEndDate}/>

      <div className='dashboard-container'>
        <h2>Doelen</h2>
        
        {goals && goals.map(goal => (
          <div key={goal.ID} id='persona-goal-item-container'>
            <div className='dashboard-goal-title-container' onClick={() => setShowGoalDetails(goal.ID === showGoalDetails ? '' : goal.ID)}>
              <h3>{goal.Goal}</h3>
            </div>
            
            <div style={{display: goal.ID === showGoalDetails ? 'block' : 'none'}}>
              <PersonaGoalData goal={goal} personaId={item.ID} />
            </div>
            
          </div>
        ))}
      </div>
     
    </div>
  );
};

export default Dashboard;
