import { useFirestoreID } from '../../firebase/useFirestore'

const QuestionMeta = ({field}) => {

    const fields = useFirestoreID('QuestionnaireFields', field ? field : '')

  return (
    <>
        {fields && fields.map(item => (
            <p key={field.ID}>{item.Question}</p>
        ))}
    </>
  )
}

export default QuestionMeta