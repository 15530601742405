import React from 'react'
import { useFirestore } from '../../firebase/useFirestore'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Tooltip from "../../components/common/Tooltip";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useState, useContext } from 'react'
import { db } from "../../firebase/config";
import TargetgroupMeta from '../stakeholders/TargetgroupMeta';
import firebase from 'firebase'
import deleteIcon from '../../images/icons/delete-icon.png'

const SelectTargetgroup = ({effect}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [editStakeholder, setEditStakeholder] = useState(false)

    // Firestore
    const stakeholders = useFirestore('Stakeholders')

    // Update the stakeholder
    const stakeholderHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value
        const docid = e.target.dataset.docid

        console.log(value, docid)

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Targetgroup: firebase.firestore.FieldValue.arrayUnion(value)
        })
        .then(() => {
            setSaved('flex')
            setEditStakeholder(false)
         })

    }

    // Delete the stakeholder
    const deleteHandler = (e) => {

        const targetgroup = e.target.dataset.targetgroup
        const docid = effect.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Targetgroup: firebase.firestore.FieldValue.arrayRemove(targetgroup)
        })
        .then(() => {
            setSaved('flex')
         })

    }


  return (
    <div className="table-edit-container">
        <div>
            <div>
                <ul>
                {Array.isArray(effect.Targetgroup) ? 
                    effect.Targetgroup.length > 0 ? 
                        effect.Targetgroup.map(targetgroup => (
                            <li key={targetgroup}>
                                <div id='effects-targetgroups-container'>
                                    <TargetgroupMeta targetgroupID={targetgroup} />
                                    <img src={deleteIcon} alt="" data-targetgroup={targetgroup} onClick={deleteHandler}/>
                                </div>
                            </li>
                        )) 
                        : 'Geen doelgroepen toegevoegd' 
                    : 'Geen doelgroepen toegevoegd'
                }
                </ul>
            </div>
            <div className="effect-meta-select-stakeholder-container" style={{display: editStakeholder ? 'flex' : 'none'}}> 
                <select name="" id="" data-docid={effect.docid} onChange={stakeholderHandler} defaultValue={effect.Targetgroup}>
                    <option value="">-- Selecteer doelgroep --</option>
                    {stakeholders && stakeholders.map( item => (
                        <option key={item.ID} value={item.ID}>{item.Organisation}</option>
                    ))}
                </select>
            </div>
        </div>
        <Tooltip content={'Doelgroep aanpassen'} top='-60px' width={'10%'}>
            <CreateOutlinedIcon onClick={() => setEditStakeholder(!editStakeholder)}/>
        </Tooltip>
    </div>
  )
}

export default SelectTargetgroup