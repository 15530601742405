

const ButtonClickedSimple = (e, text, original) => {

    e.target.innerText = text 
    e.target.style.color = '#D3D3D3'

    setTimeout(() => {
        e.target.innerText = original
        e.target.style.color = '#63cadc'
    }, 1000)
   
}

export default ButtonClickedSimple