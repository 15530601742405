import { useFirestoreID } from '../../firebase/useFirestore'

const EffectMeta = ({item}) => {

  const effects = useFirestoreID('OutputEffects', item ? item : '')

  return (
    <>
        {effects && effects.map((item) => (
            <p>{item.Effect}</p>
        ))}
    </>
  )
}

export default EffectMeta