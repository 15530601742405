import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';

const MultipleBarChart = ({moment, field}) => {
    const [data, setData] = useState([])
    const [otherInputs, setOtherInputs] = useState(0)

    // Query input results
    const queryResults = async (input) => {

        const querySnapshot = await db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('MomentID', '==', moment)
        .where('FieldID', '==', field)
        .where('Input', '==', input)
        .get()

        const array = []

        querySnapshot.docs.map(async (doc) => {
            array.push(doc.data())
        })

        return array.length

    }

    // Query other results
    const otherResults = async () => {

      const querySnapshot = await db.collection('QuestionnairesResponses')
      .where('CompagnyID', '==', client)
      .where('MomentID', '==', moment)
      .where('FieldID', '==', field)
      .where('OptionType', '==', 'other')
      .get()

      const array = []

      querySnapshot.docs.map(async (doc) => {
          array.push(doc.data().Input)
      })

      setOtherInputs(array)

      return array.length

  }

    const fields = async () => {

        const querySnapshot = await db.collection('MultipleQuestionOptions')
        .where('CompagnyID', '==', client)
        .where('Field', '==', field)
        .orderBy('Position', 'asc')
        .get()

        const array = []

        querySnapshot.docs.map(async input => {

            const object = {
                name: input.data().Option === 'other' ? 'Anders' : input.data().Option,
                score: input.data().Option === 'other' ? await otherResults() : await queryResults(input.data().Option),
                color: input.data().Color
            }
            array.push(object)
        })

        return array

    }


    useEffect(() => {
        fields().then((data) => {
            setTimeout(() => {
                setData(data)
            }, 1000)
        })
    },[])

      const getPath = (x, y, width, height) => {
        return `M${x},${y}
        L${x + width},${y}
        L${x + width},${y + height}
        L${x},${y + height}
        Z`;
      };
      
      const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
      
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
      };

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          if(label === 'Anders'){
            return (
              <div className="custom-tooltip">
                <p className="label">{`${label}: ${payload[0].value}`}</p>
                <p className="label">{`Reacties:`}</p>
                <ul>
                  {otherInputs && otherInputs.map(input => (
                    <li>{input}</li>
                  ))}
                </ul>
              </div>
            );
          }
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
          );
        }
      
        return null;
      };

  return (
    <BarChart
          width={500}
          height={300}
          data={data.length > 0 ? data : [{name: 'No data', uv: 0}]}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />}/>
          <Bar dataKey="score" shape={<TriangleBar />} label={{ position: 'top' }}>
            {data ? data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color}/>
            ))
            : null}
          </Bar>
        </BarChart>
  )
}

export default MultipleBarChart