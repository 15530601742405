import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
  } from "recharts";
import { useState, useEffect } from 'react'
import { useFirestoreGeneral} from '../../firebase/useFirestore'

const OutputResultsGraphResponsive = ({output, color}) => {
    const [data, setData] = useState('')

    const options = { month: 'numeric', year: 'numeric'};

    const dataset = useFirestoreGeneral('Results', 'OutputID', output ? output : '')

    const resultsArray = []

    useEffect(() => {

        const dataArray = []

        dataset && dataset.forEach(data => {

            const month = data.Timestamp.toDate().toLocaleDateString("nl-NL", options)

            resultsArray.push(data.Result)

            const dataObject = {
                Maand: month,
                Resultaat: results(resultsArray)
            }

            dataArray.push(dataObject)
        })

       setData(dataArray)

    },[dataset])

    const results = (resultsArray) => {

      const sum = resultsArray.reduce((partialSum, a) => partialSum + a, 0);

      return sum

    }

  return (
        <AreaChart
        data={data && data}
        height={300}
        width={600}
        margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Maand" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="Resultaat" stroke={color ? color : '#f48183'} fill={color ? color : '#f48183'} />
        </AreaChart>
  )
}

export default OutputResultsGraphResponsive