import { db, bucket, auth } from "../../firebase/config.js"
import firebase from 'firebase'
import { useHistory } from "react-router";
import { useState, useContext } from 'react';
import { Auth } from '../../StateManagment/Auth';
import ButtonClicked from '../../hooks/ButtonClicked'
import ScrollToTop from "../../hooks/ScrollToTop";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const Profile = () => {
    // Context
    const [ authO ] = useContext(Auth)

    // Hooks
    const history = useHistory()
    ScrollToTop()

    // Change profile photo
    const changePhoto = (e) => {

        const newPhoto = e.target.files[0]

        const storageRef = bucket.ref("/ProfilePhotos/" + newPhoto.name);
        const uploadTask = storageRef.put(newPhoto)

        uploadTask.then(() => {
          
            uploadTask.on('state_changed', snapshot => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING:
                console.log('Upload is running');
                break;
            }
            }, (err) => {
                alert(err)
            }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log('File available at', downloadURL);

            savePhoto(downloadURL)

                })
            })
        })
    }

    // Save photo to database
    const savePhoto = (photo) => {
        auth.onAuthStateChanged(User =>{
            if(User){
                db.collection("Users")
                .doc(User.uid)
                .update({
                    Photo: photo
                })
            }
        })
    }

    // Delete account
    const deleteAccount = (e) => {

        console.log(authO)

        ButtonClicked(e, 'Verwijderen...')
        
        db.collection("Users")
        .doc(authO.Docid)
        .update({
            Deleted: true
        })
        .then(() => {
            auth
            .currentUser
            .delete()

            console.log('Account verwijderd')
        })
        .then(() => {
            history.push(`/`)
            window.location.reload()
        }) 
        .catch(err => {
            console.log(err)
        })

        console.log('Account in database geupdate')

    }

    // Change first name
    const forNameHandler = (e) => {
        const forName = e.target.value

        db.collection("Users")
        .doc(authO.Docid)
        .update({
            ForName: forName,
        })
    }

    // Change last name
    const surNameHandler = (e) => {
        const surName = e.target.value

        db.collection("Users")
        .doc(authO.Docid)
        .update({
            SurName: surName,
        })
    }

    return (
        <div className="main">
                <div className="main-container">
                    <div className='page-header'>
                        <h1>Mijn profiel</h1>
                    </div>

                    <div className="card-header">
                        <img id="profile-header-photo" src={authO?.Photo} alt="" />
                        <h2>{authO?.UserName}</h2>
                    </div>

                    <div className="table-container dashboard-container">
                        <h2>Schermnaam aanpassen</h2>
                        <h5>Voornaam</h5>
                        <input className="input-classic" type="text" defaultValue={authO?.ForName} onChange={forNameHandler}/>
                        <h5>Achternaam</h5>
                        <input className="input-classic" type="text" defaultValue={authO?.SurName} onChange={surNameHandler}/>
                    </div>

                    <div className="table-container dashboard-container">
                        <h2>Profielfoto aanpassen</h2>
                        <div className="photo-container-profile">
                            <img id="adjust-photo-profile" src={authO?.Photo} alt="" />
                        </div>
                        <input className="input-classic" type="file" onChange={changePhoto} />
                    </div>

                    <div className="table-container dashboard-container">
                        <h2>Account verwijderen</h2>
                        <p>Let op! Als je je account verwijderd, kan je deze niet meer herstellen.</p>
                        <button id="delete-account-button" onClick={deleteAccount}>Verwijderen</button>
                    </div>

                </div>
        </div>
    )
}

export default Profile
