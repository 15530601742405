import {useFirestoreID, useFirestoreGeneralTwoOrderBy} from "../../firebase/useFirestore";
import useSettings from "../../hooks/Settings";

const ScaleMomentVisualisation = ({field, moment}) => {

    // Hooks
    const primaryColor = useSettings().PrimaryColor

    // Firestore
    const results = useFirestoreGeneralTwoOrderBy('QuestionnairesResponses', 'FieldID', field ? field : '', 'MomentID', moment ? moment : '', 'Timestamp', 'asc' )
    const fields = useFirestoreID('QuestionnaireFields', field ? field : '')

    const resultsArray = results ? results.map(result => parseInt(result.Input)) : [];
    const total = results.length 
    const sum = resultsArray.length > 0 ? resultsArray.reduce((partialSum, a) => partialSum + a, 0) : 0
    const averageScore = sum && total > 0 ? Math.round((sum / total) * 10) / 10 : 0;

    // Function to calculate width percentage based on scores and field ranges
    const calculateWidthPercentage = (averageScore, reachStart, reachEnd) => {
      const range = reachEnd - reachStart;
      const normalizedScore = ((averageScore - reachStart) / range) * 100;
      return Math.max(0, Math.min(100, normalizedScore)); // Ensure it's between 0% and 100%
  };

  return (
    <>
      <div id='scale-visualisation-title-container'>
        <h2>Gemiddelde score</h2>
        <p>(van {total} reacties)</p>
      </div>
      <div id='scale-visualisation-scale-container'>
      {fields && fields.map(field => (
        <div key={field.ID} id='scale-visualisation-scale-item-container'>
          <div className="scale-visualisation-label-container">
            <p>{field.ReachStart}</p>
            <p>{field.ReachStartLable}</p>
          </div>
          <div id='scale-visualisation-scale-box-container'>
            <div id='scale-visualisation-average-score-container' style={{ width: `${calculateWidthPercentage(averageScore, field.ReachStart, field.ReachEnd)}%` }}>
              <div id='scale-visualisation-avarage-score-item-container'>
                <h1 style={{color: primaryColor}}>{averageScore}</h1>
              </div>
            </div>
          </div>
          <div className="scale-visualisation-label-container">
            <p>{field.ReachEnd}</p>
            <p>{field.ReachEndLabel}</p>
          </div>
        </div>
      ))}

      </div>
    </>
  )
}

export default ScaleMomentVisualisation