import { useState, useEffect } from 'react';
import SimpleLineChart from '../Visualisations/SimpleLineChart';
import { db } from '../../firebase/config';

const MatrixDevelopmentData = ({ field, startDate, endDate, researchID, personaId }) => {
  const [data, setData] = useState([]);
  const [totalGrowth, setTotalGrowth] = useState(0);
  const [uniqueRowTitles, setUniqueRowTitles] = useState([]);
  const [growthDataForRows, setGrowthDataForRows] = useState({});

  // Helper function to calculate total growth
  const calculateTotalGrowth = (dataArray) => {
    const totalAvarageArray = [];

    dataArray.forEach(item => {
      const moment = item.name;
      const momentData = dataArray.filter(dataItem => dataItem.name === moment);

      // Calculate the total sum of all numerical values for a given moment.
      // momentData is an array of objects where each object represents a measurement moment.
      // Each object contains row titles as keys and their corresponding average values as the values.

      // We use the outer reduce to iterate through each object (b) in momentData.
      // Inside this outer reduce, we use Object.values(b) to get an array of the values in the object (i.e., the average values for each row title).

      // The inner reduce sums up these values (if they are numbers) and adds them to the total sum for this moment.
      // If a value is not a number (e.g., null or undefined), we treat it as 0 and skip it.
      const total = momentData.reduce((a, b) =>
        a + Object.values(b).reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0)
      , 0);

      const average = total / momentData.length;

      totalAvarageArray.push({
        moment: moment,
        average: average
      });
    });

    let finalGrowth = 0;
    totalAvarageArray.forEach((item, index) => {
      const totalAvarage = item.average;

      if (index > 0) {
        const previousTotalAvarage = totalAvarageArray[index - 1].average;
        if (previousTotalAvarage !== 0) {
          const growth = ((totalAvarage - previousTotalAvarage) / previousTotalAvarage) * 100;
          finalGrowth = parseFloat(growth.toFixed(1));
        }
      }
    });

    return finalGrowth;
  };

  // Helper function to calculate growth for each rowTitle between moments
  const calculateGrowthForEachRowTitle = (dataArray, rowTitles) => {
    const growthData = {};

    rowTitles.forEach((rowTitle) => {
      growthData[rowTitle] = [];

      dataArray.forEach((item, index) => {
        if (index > 0) {
          const currentMomentValue = item[rowTitle];
          const previousMomentValue = dataArray[index - 1][rowTitle];

          if (previousMomentValue !== 0 && previousMomentValue !== null && currentMomentValue !== null) {
            const growth = ((currentMomentValue - previousMomentValue) / previousMomentValue) * 100;
            growthData[rowTitle].push(parseFloat(growth.toFixed(1))); // Store growth percentage for this rowTitle
          } else {
            growthData[rowTitle].push(null); // No growth if data is missing
          }
        } else {
          growthData[rowTitle].push(null); // No growth for the first moment
        }
      });
    });

    return growthData;
  };

  // Function to fetch data from Firestore
  const getData = async () => {
    const dataArray = [];
    const rowTitlesSet = new Set(); // To store unique row titles
     
  // Start the Firestore query by selecting the 'QuestionnairesResponses' collection and adding the 'FieldID' condition.
  let query = db.collection('QuestionnairesResponses')
  .where('FieldID', '==', field);


  // Conditionally add the 'PersonaID' filter if personaId is defined.
  if (personaId) {
    query = query.where('Persona', '==', personaId);
  }

  // Execute the query to get the responses.
  const responses = await query.get();

    // Collect all row titles first to ensure they are consistent across all moments
    responses && responses.docs.forEach(response => {
      rowTitlesSet.add(response.data().RowTitle);
    });

    const measureMoments = await db.collection('MeasureMoments')
      .where('ResearchID', '==', researchID)
      .orderBy('Position', 'asc')
      .get();

    measureMoments.docs.forEach((moment) => {
      const momentID = moment.data().ID;
      const momentTitle = moment.data().Title;

      const momentResponses = responses.docs.filter(response => response.data().MomentID === momentID);

      const columnData = {};

      momentResponses.forEach(response => {
        const responseData = response.data(); // Access the actual document data
        const rowTitle = responseData.RowTitle; // Group by row title instead of columnID
        const input = parseInt(responseData.Input, 10); // Parse the input as an integer
      
        // Initialize if this is the first time encountering this rowTitle
        if (!columnData[rowTitle]) {
          columnData[rowTitle] = {
            sum: 0,
            count: 0,
            rowTitle: rowTitle // Store the rowTitle as well
          };
        }
      
        // Add the input to the sum and increment the count
        columnData[rowTitle].sum += input;
        columnData[rowTitle].count += 1;
      });

      const momentData = {
        name: momentTitle
      };

      // Ensure all row titles from rowTitlesSet are present in momentData
     // Loop through each row title in the set of unique row titles
    rowTitlesSet.forEach(rowTitle => {

      // Find the corresponding column entry for this row title in columnData
      const columnEntry = Object.values(columnData).find(entry => entry.rowTitle === rowTitle);

      // If an entry for this row title exists, calculate the average
      if (columnEntry) {
        const average = columnEntry.sum / columnEntry.count; // Calculate the average by dividing sum by count

        // Add the average value to momentData using the row title as the key
        momentData[rowTitle] = average;
      } else {
        // If no data exists for this row title in this moment, set the value to null (or you could set it to 0 if preferred)
        momentData[rowTitle] = null;
      }
    });

    // After processing all row titles, push the momentData object into the dataArray
    dataArray.push(momentData);
    });

    setData(dataArray);

    const rowTitles = [...rowTitlesSet]; // Convert the Set to an Array

    setUniqueRowTitles(rowTitles); // Convert Set to Array

    // Calculate growth for each rowTitle between moments
    const growthDataForRows = calculateGrowthForEachRowTitle(dataArray, rowTitles);
    setGrowthDataForRows(growthDataForRows);

    // Calculate total growth and update state
    const growth = calculateTotalGrowth(dataArray);
    setTotalGrowth(growth);
  };

  // useEffect to trigger getData when field or researchID changes
  useEffect(() => {
    getData();
  }, [field, researchID]);

  return (
    <>
      <div id='matrix-one-data-total-average-container'>
        <p><b>Totale groei</b></p>
        <p>{totalGrowth}%</p>
      </div>
      <SimpleLineChart data={data} uniqueRowTitles={uniqueRowTitles} growthDataForRows={growthDataForRows} />
    </>
  );
};

export default MatrixDevelopmentData;


