import { createContext, useState } from "react";

export const ActiveMenu = createContext()

export const ActiveMenuProvider = (props) => {
    const [active, setActive] = useState("none")
    const [activeSubItem, setActiveSubItem] = useState('')

    return(
        <ActiveMenu.Provider value={{active, setActive, activeSubItem, setActiveSubItem}}>
            {props.children}
        </ActiveMenu.Provider>
    )
}