import { useFirestoreUsersApproved, useFirestore, useFirestoreNoCompagny,} from "../../firebase/useFirestore";
import { useHistory } from "react-router-dom";
import { client } from '../../hooks/Client';
import { db, auth } from "../../firebase/config";
import { useState, useContext, useEffect } from "react";
import { Auth } from '../../StateManagment/Auth';
import deleteIcon from '../../images/icons/delete-icon.png'
import ButtonClicked from "../../hooks/ButtonClicked";
import Hostname from '../../hooks/Hostname'
import firebase from "firebase";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import AddItemTableRow from '../../components/common/AddItemTableRow'
import useSettings from "../../hooks/Settings";

const Members = () => {
    // Context
    const [authO] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [showDeleteButton, setShowDeleteButton] = useState("none")
    const [email, setEmail] = useState('')
    const [communityName, setCommunityName] = useState('')
    const [userDocid, setUserDocid] = useState('')
    const [showAddContainer, setShowAddContainer] = useState(false)

    // Hooks
    const history = useHistory()
    const host = Hostname()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const members = useFirestoreUsersApproved()
    const compagnies = useFirestore("CompagnyMeta")
    const admins = useFirestore('Admins')
    const users = useFirestoreNoCompagny('Users', 'Email', email)

    useEffect(() => {
        compagnies && compagnies.forEach(compagny => {
            setCommunityName(compagny.CommunityName)
        })
 
    }, [compagnies])

    useEffect(() => {
        const showDeleteButtonForAdmin = () => {
            admins && admins.forEach(admin => {
                if(admin.UserID === authO?.ID){
                    setShowDeleteButton("block")
                }
            })
        }
        showDeleteButtonForAdmin()
    }, [admins])

    useEffect(() => {
        users && users.forEach(item => {
            const docid = item.docid 

            setUserDocid(docid)
        })
    },[users])

    const emailHandler = (e) => {
        const value = e.target.value 

        setEmail(value)
    }


    const inviteMemberHandler = (e) => {

        if(users.length > 0){
            console.log('bestaat al')
            updateExistingMember()
            sendMailToExistingMember()
        } else if(users.length === 0){
            console.log('bestaat nog niet')
            sendMailToNewMember()
        } 

        ButtonClicked(e, 'Uitnodiging verstuurd')

    }

    const updateExistingMember = async () => {

        const updateUserAccountWhenInvited = firebase.functions().httpsCallable('updateUserAccountWhenInvited')  

        const result = await updateUserAccountWhenInvited({uid: userDocid, client: client}) 

        if(result.data === 'success') {
           console.log('success')
           setShowAddContainer(false)
        } else if(result.data === 'error') {
            alert(`Er is iets mis gegaan, neem contact op met Deccos via info@deccos.nl`)
        }

    }

    const sendMailToExistingMember = () => {

        db.collection("Email").doc().set({
            to: email,
            cc: "info@deccos.nl",
            from: "info@deccos.nl",
            replyTo: `${host.Name}`,
            message: {
            subject: `${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving`,
            html: `
                ${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving. <br><br>

                <a href='https://${host.Hostname}/${client}'>Klik hier</a> om naar de omgeving van ${communityName} te gaan.<br><br>

                Met vriendelijke groet, <br><br>

                Team ${host.Name} <br><br>
                
                `,
            Gebruikersnaam: `${client}`,
            Emailadres: email,
            Type: "Invite member"
                }     
        })
        .then(() => {
            setSaved(true)
            setShowAddContainer(false)
        })
    }

    const sendMailToNewMember = () => {

        console.log(email)

        db.collection("Email").doc().set({
            to: email,
            cc: "info@deccos.nl",
            from: "info@deccos.nl",
            replyTo: `${host.Name}`,
            message: {
            subject: `${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving`,
            html: `
                ${communityName} nodigt je uit om lid te worden van de ${host.Name} ${host.Text} omgeving. <br><br>

                <a href='https://${host.Hostname}/${client}/RegisterInvitation'>Klik hier</a> om een account aan te maken.<br><br>

                Met vriendelijke groet, <br><br>

                Team ${host.Name} <br><br>
                
                `,
            Gebruikersnaam: `${client}`,
            Emailadres: email,
            Type: "Invite member"
                }     
        })
        .then(() => {
            setSaved(true)
            setShowAddContainer(false)
        })
    }

    const deleteUser = (e) => {

        const docid = e.target.dataset.id

        db.collection('Users')
        .doc(docid)
        .update({
            Compagny: firebase.firestore.FieldValue.delete(client),
        })
        .then(() => {
            setSaved(true)
        })
    }

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Team</h1>
                </div>
                <div className="profile-inner-container">
                    <h2>Teamleden</h2>
                    <div className='table-container'>
                          <table>
                              <tr>
                                  <th style={{backgroundColor: secundairyColor}}>FOTO</th>
                                  <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                                  {/* <th style={{backgroundColor: secundairyColor}}>ROLLEN</th> */}
                                  <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                              </tr>
                              {members && members.map(member => (
                                    <tr key={member.ID}>
                                        <td>
                                            <img src={member.Photo} alt="" id='members-photo'/>
                                        </td>
                                        <td>
                                            <p>{member.UserName}</p>
                                        </td>
                                        {/* <td>

                                        </td> */}
                                        <td>
                                            <img src={deleteIcon} alt="" className="userrole-users-delete-button" data-id={member.docid} onClick={deleteUser} />
                                        </td> 
                                    </tr>
                                ))}
                          </table>
                          <AddItemTableRow toolTipContent={'Document toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
                          <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}}>
                                <p>Nodig een teamlid uit</p>
                                <input type="text" placeholder='Noteer hier het emailadres' onChange={emailHandler} />
                              <div className='add-options-upload-buttons-container'>
                                  <button className='button-simple' onClick={() => setShowAddContainer(!showAddContainer)}>Annuleren</button>
                                  <button className='button-simple' onClick={inviteMemberHandler}>Uitnodigen</button>
                              </div>
                          </div>
                      </div>
                  </div>
            </div>
        </div>
    )
}

export default Members