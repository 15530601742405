import { useEffect } from 'react';

const useEnterToSend = (textareaRef, sendMessageHandler) => {
  useEffect(() => {
    const enterKey = (e) => {
      if (e.key === 'Enter' && document.activeElement === textareaRef.current) {
        sendMessageHandler();
      }
    };

    // Attach the keypress event listener to the document
    document.addEventListener('keypress', enterKey);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keypress', enterKey);
    };
  }, [textareaRef, sendMessageHandler]);
};

export default useEnterToSend;