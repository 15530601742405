import { useFirestoreID } from '../../firebase/useFirestore'

const FieldMeta = ({item}) => {

    const fields = useFirestoreID('QuestionnaireFields', item ? item : '')

    return (
      <>
          {fields && fields.map((item) => (
              <p>{item.Question}</p>
          ))}
      </>
    )
}

export default FieldMeta