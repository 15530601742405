import { useFirestoreID } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import { useState, useEffect } from "react"
import useSettings from "../../hooks/Settings"
import QuestionaireField from '../../components/open/QuestionaireField'

const QuestionnaireOpen = () => {

    // State
    const [compagnyId, setCompagnyId] = useState(null)
    const [compagnyLogo, setCompagnyLogo] = useState('')
    const [compagnyName, setCompagnyname] = useState('')
    const [secundaryColor, setSecundaryColor] = useState('')
    const [totalTopLevelQuestions, setTotalTopLevelQuestions] = useState(0)
    const [fieldIndex, setFieldIndex] = useState(0)

    // Get url params
    const questionnaireId = Location()[3]

    // Firestore
    const questionaires = useFirestoreID('Questionnaires', questionnaireId ? questionnaireId : '0')

    // Hooks
    const settings = useSettings()

    // Set settings
    useEffect(() => {
        setCompagnyLogo(settings.logo)
        setCompagnyname(settings.compagnyName)
        setSecundaryColor(settings.SecundairyColor)
        setCompagnyId(settings.compagnyID)
    }, [settings])

    // Progress bar
    const progressBar = () => {

        const percentage = (fieldIndex / totalTopLevelQuestions) * 100

        return(
            <div id='progress-bar-container'>
                <div id='progress-bar' style={{width: `${percentage}%`}}></div>
            </div>
        )
    }

  return (
    <div className='page-container'>
        <div id='questionniare-container'>
            <img id='compagny-logo' src={compagnyLogo} alt="logo" />
            <p><b>{compagnyName}</b></p>
            {questionaires && questionaires.map(questionaire => (
                <div key={questionaire.ID} id='questionnaire-field-outer-container'>
                    <div id='page-header-open-questionnaire'>
                        <p>Vragenlijst</p>
                        <h1>{questionaire.Title}</h1>
                        <p>{questionaire.Description}</p>
                        {progressBar(1)}
                    </div>
                    
                    <QuestionaireField 
                    questionaire={questionaire} 
                    setTotalTopLevelQuestions={setTotalTopLevelQuestions} 
                    setFieldIndex={setFieldIndex} 
                    fieldIndex={fieldIndex}
                    compagnyId={compagnyId}
                    />
                </div>
            ))}
        </div>
    </div>
  )
}

export default QuestionnaireOpen