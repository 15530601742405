import plusButton from '../../images/icons/plus-icon.png'
import Tooltip from "../../components/common/Tooltip";

const AddItemTableRow = ({toolTipContent, onClick}) => {
  return (
    <div className='add-item-table-row-container'>
         <Tooltip content={toolTipContent} width={'150px'} left='-10px' top={'-45px'}>
            <img src={plusButton} onClick={onClick} alt="" />
        </Tooltip>
    </div>
  )
}

export default AddItemTableRow