import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import useSettings from "../../hooks/Settings";
import { db } from "../../firebase/config";
import ButtonClicked from "../../hooks/ButtonClicked";
import { useState } from "react";

const QualityCheck = () => {
    const [succesMessage, setSuccesMessage] = useState('none')

    const compagnyName = useSettings().compagnyName

    const sendRequest = (e) => {

        ButtonClicked(e, 'Aangevraagd')
        setSuccesMessage('block')

        db.collection("Email").doc().set({
            to: "info@deccos.nl",
            from: "info@deccos.nl",
            replyTo: "info@deccos.nl",
            message: {
            subject: `Kwaliteitscheck aangevraagd door ${compagnyName}`,
            html: `
                ${compagnyName} heeft een kwaliteitscheck aangevraagd. <br><br>
                
                `,
            Gebruikersnaam: `${compagnyName}`,
            Emailadres: "info@deccos.nl",
            Type: "QualityCheck"
                }     
        })
        .then(() => {
            console.log("Email sent");
        
        })
    }

  return (
    <div className="main">
            <div className="main-container">
                <div className="page-header">
                    <VerifiedUserOutlinedIcon/>
                    <h1>Kwaliteitscheck</h1>
                </div>
                <div className="table-container dashboard-container">
                   <h2>Laat een kwaliteitscheck uitvoeren door een van onze impact experts.</h2>
                   <p>Heb je behoefte aan een onafhankelijke check door een ervaren impact expert? </p>
                   <button onClick={sendRequest}>Aanvragen</button>
                    <p style={{display: succesMessage}}>Je aanvraag is verzonden. Wij nemen zo snel mogelijk contact met u op om uw aanvraag te bespreken.</p>
                </div>
            </div>
        </div>
  )
}

export default QualityCheck