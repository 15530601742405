import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import Location from "../../hooks/Location"
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import { db } from '../../firebase/config';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Tooltip from '../../components/common/Tooltip';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"

const PersonaPresenceReportDetails = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [correctionReason, setCorrectionReason] = useState('')

    // Hooks
    const reportId = Location()[4]
    const personaId = Location()[3]
    const history = useHistory()

    // Firestore
    const presenceReports = useFirestoreGeneral('PersonaPresenceReports', 'ID', reportId ? reportId : ''); 

    // Update report type
    const updateReportType = (e) => {
      const value = e.target.value

      db.collection('PersonaPresenceReports')
        .doc(e.target.dataset.docid)
        .update({
          Type: value
        })
  
      setSaved(true)
    }

    // Update start and endtime
  const startTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        CorrectedStart: value
      })

    setSaved(true)
  }

  const endTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        CorrectedEnd: value
      })

    setSaved(true)
  }

  // Update absent reason
  const absentReasonHandler = (e) => {
    const value = e.target.options[e.target.selectedIndex].value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        Reason: value
      })

    setSaved(true)
  }

  // Update correction reason
  const correctionReasonHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .update({
        Reason: value
      })

    setSaved(true)
  }

  // Delete report
  const deleteReport = (e) => {
    console.log(e.target.dataset.docid)
    db.collection('PersonaPresenceReports')
      .doc(e.target.dataset.docid)
      .delete()
      .then(() => {
        setSaved(true)
      })
  }

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <PunchClockOutlinedIcon />
          <h1>Aanwezigheidsrapport</h1>
        </div>
        <div className='table-container dashboard-container'>
           {presenceReports && presenceReports.map((report) => (
            <div>
                <h2>{report.Date}</h2>
                <div>
                    <select name="" id="" defaultValue={report.Type} data-docid={report.docid} onChange={updateReportType}>
                        <option value="correct">Aanwezig</option>
                        <option value="absent">Afwezig</option>
                        <option value="correction">Aanpassing</option>
                    </select>
                </div>

                <div style={{display: report.Type === 'correct' ? 'block' : 'none'}}>
                  <h3>Start en eindtijd</h3>
                  <div id='presence-report-detail-times-input-container'>
                    <input type="time" defaultValue={report.Start} data-docid={report.docid} onChange={startTimeHandler} />
                    <p>-</p>
                    <input type="time" defaultValue={report.End} data-docid={report.docid} onChange={endTimeHandler} />
                  </div>
                </div>

                <div style={{display: report.Type === 'correction' ? 'block' : 'none'}}>
                  <h3>Start en eindtijd</h3>
                  <div id='presence-report-detail-times-input-container'>
                    <input type="time" defaultValue={report.CorrectedStart ? report.CorrectedStart : report.Start} data-docid={report.docid} onChange={startTimeHandler} />
                    <p>-</p>
                    <input type="time" defaultValue={report.CorrectedEnd ? report.CorrectedEnd : report.End} data-docid={report.docid} onChange={endTimeHandler} />
                  </div>
                  <h3>Reden correctie</h3>
                  <input type="text" placeholder='Reden correctie' defaultValue={report.Reason} data-docid={report.docid} onChange={correctionReasonHandler} />
                </div>

                <div style={{display: report.Type === 'absent' ? 'block' : 'none'}}>
                    <h3>Afwezig</h3>
                    <div>
                      <select name="" id="" defaultValue={report.Reason} data-docid={report.docid} onChange={absentReasonHandler}>
                        <option value="">-- Selecteer reden afwezigheid --</option>
                        <option value="ziek">Ziek</option>
                        <option value="verlof">Verlof</option>
                        <option value="overig">Overig</option>
                      </select>
                    </div>
                </div>
                <div id='presence-report-detail-options-container'>
                  <Tooltip content="Verwijderen" top={'-40px'}>
                    <DeleteOutlineOutlinedIcon data-docid={report.docid} onClick={deleteReport}/>
                  </Tooltip>
                </div>
                
            </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default PersonaPresenceReportDetails