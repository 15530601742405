import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import firebase from 'firebase/app';
import PieChart from '../Visualisations/PieChart';
import ParagraphBarChartResponsive from '../Visualisations/ParagraphBarChartResponsive';

const ParagraphMomentData = ({field, moment, startDate, endDate, graphType}) => {

  const [data, setData] = useState('')

  // Default start and end date if dates are undefined
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

  // Get wordcount for graphdata
const getwords = async (item) => {

  let words = 0
  
  await db.collection('AnalysisWords')
      .where('CompagnyID', '==', client)
      .where('FieldID', '==', field ? field : '123')
      .where('MomentID', '==', moment ? moment : '123')
      .where('CategoryID', '==', item ? item : '123')
      // .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
      // .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
      .get()
      .then((querySnapshot) => {
          words = querySnapshot.size

      })

  return words
}

// Get categories for graphdata
const getCategories = async () => {

  const array = [];

  const querySnapshot = await db
      .collection('AnalysisCategories')
      .where('CompagnyID', '==', client ? client : '123')
      .where('FieldID', '==', field ? field : '123')
      .get();

  const promises = querySnapshot.docs.map(async (doc) => {
      const object = {
      name: doc.data().Categorie,
      value: await getwords(doc.data().ID),
      color: doc.data().Color,
      };

      return object;
  });

  array.push(await Promise.all(promises))

  return array;
}

useEffect(() => {
    getCategories().then((data) => {
        setData(data)
    })
},[])

  return (
    <>
      {graphType === 'pie' ? 
      <PieChart data={data} />
      :
      <ParagraphBarChartResponsive data={data} />
      }
    </>
  )
}

export default ParagraphMomentData