import { useFirestoreID } from '../../firebase/useFirestore'

const FieldMetaForms = ({fieldId}) => {

    const fields = useFirestoreID('QuestionnaireFields', fieldId ? fieldId : '')

    return (
      <>
          {fields && fields.map((item) => (
                <div key={item.ID} id='field-meta-forms-item-container'>
                    <h3>{item.Question}</h3>
                </div>
          ))}
      </>
    )
}

export default FieldMetaForms