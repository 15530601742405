import { useContext } from "react"
import { SavedIcon } from '../../StateManagment/SavedIcon'
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";
import { useHistory } from "react-router"
import { client } from '../../hooks/Client';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const NextStepContainer = ({url, mainItem}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);

    // Hooks
    const history = useHistory()

  return (
    <div id='next-step-container'>
        <div>
            <button onClick={() => {
                history.push(`/${client}/${url}`);
                setActive(mainItem);
                setActiveSubItem(url)
            }}>
                Volgende stap
            </button>
        </div>
    </div>
  )
}

export default NextStepContainer