import { useContext } from "react"
import { SavedIcon } from '../../StateManagment/SavedIcon'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const SavedNotice = () => {
     // Context
     const [saved, setSaved] = useContext(SavedIcon)

     console.log(saved)

  return (
    <div id='next-step-saved-container'>
        {saved === 'flex' ? 
        <div className="next-step-saved-item-container">
            <p>Opslaan...</p>
        </div> 
        :
        <div className="next-step-saved-item-container">
            <CheckOutlinedIcon />
            <p>Opgeslagen</p>
        </div> 
        }
    </div>
  )
}

export default SavedNotice