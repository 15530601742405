import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import firebase from 'firebase/app';
import PieChart from '../Visualisations/PieChart';
import HomeMultipleBarChart from '../Visualisations/HomeMultipleBarChart';

const MultiMomentData = ({moment, field, startDate, endDate, graphType}) => {

    const [data, setData] = useState([])
    const [otherInputs, setOtherInputs] = useState(0)

     // Default start and end date if dates are undefined
    const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2021, 0, 1));
    const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

    // Query input results
    const queryResults = async (input) => {

        const querySnapshot = await db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('MomentID', '==', moment)
        .where('FieldID', '==', field)
        .where('Input', '==', input)
        // .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
        // .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
        .get()

        const array = []

        querySnapshot.docs.map(async (doc) => {
            array.push(doc.data())
        })

        return array.length

    }

    // Query other results
    const otherResults = async () => {

      const querySnapshot = await db.collection('QuestionnairesResponses')
      .where('CompagnyID', '==', client)
      .where('MomentID', '==', moment)
      .where('FieldID', '==', field)
      .where('OptionType', '==', 'other')
      // .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
      // .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
      .get()

      const array = []

      querySnapshot.docs.map(async (doc) => {
          array.push(doc.data().Input)
      })

      setOtherInputs(array)

      return array.length

  }

    const fields = async () => {

        const querySnapshot = await db.collection('MultipleQuestionOptions')
        .where('CompagnyID', '==', client)
        .where('Field', '==', field)
        .orderBy('Position', 'asc')
        .get()

        const array = []

        querySnapshot.docs.map(async input => {

            const object = {
                name: input.data().Option === 'other' ? 'Anders' : input.data().Option,
                score: input.data().Option === 'other' ? await otherResults() : await queryResults(input.data().Option),
                color: input.data().Color
            }
            array.push(object)
        })

        return array

    }


    useEffect(() => {
        fields().then((data) => {
            setTimeout(() => {
                setData(data)
            }, 1000)
        })
    },[])

    if(graphType === 'pie'){
        return <PieChart data={data} />;
        } else {
          return <HomeMultipleBarChart data={data} otherInputs={otherInputs} />;
        }
}

export default MultiMomentData