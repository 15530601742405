import { useState, useEffect } from "react";
import { db, timestamp } from "../../../firebase/config";
import firebase from "firebase";
import uuid from 'react-uuid';
import { client } from "../../../hooks/Client";
import { useFirestoreAnalysisWords, useFirestoreGeneral } from "../../../firebase/useFirestore";

const AnalyseResponse = ({item, setSaved, categoryID, moment, field, color}) => {
    const [selectedWord, setSelectedWord] = useState('');
    const [allWords, setAllWords] = useState([]);
    const [responseWords, setResponseWords] = useState([]);

    const allCategoryWords = useFirestoreAnalysisWords(field, moment, categoryID)
    const words = useFirestoreGeneral('AnalysisWords', 'Response', item.ID ? item.ID : '123')

    // Create selected words array
    useEffect(() => {

        const wordsArray = []

        allCategoryWords && allCategoryWords.forEach(word => {
            wordsArray.push(word.Word)
        })

        setAllWords(wordsArray)
    },[allCategoryWords])

    useEffect(() => {

        const wordsArray = []

        words && words.forEach(item => {
            wordsArray.push(item.Word)
        })

        setResponseWords(wordsArray)
    },[allCategoryWords])

    const handleWordSelect = (word) => {
        setSelectedWord(word);
      };

    const selectWord = (e) => {
        const word = e.target.dataset.word
        const docid = e.target.dataset.docid

        console.log(field)

        db.collection('AnalysisWords')
        .doc()
        .set({
            Word: word,
            CategoryID: categoryID,
            MomentID: moment,
            FieldID: field,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Response: item.ID,
            ID: uuid()
        })
        .then(() => {
            db.collection('QuestionnairesResponses')
            .doc(docid)
            .update({
                SelectedWords: firebase.firestore.FieldValue.arrayUnion(word)
            })
        })
        .then(() => {
            setSaved(true)
        })
    }

    // const deleteOrphanedWords = (docid, word) => {

    //     const wordsArray = []

    //     words && words.forEach(item => {
    //         wordsArray.push(item.Word)
    //     })

    //     if(!wordsArray.includes(word)) {
    //     db.collection('QuestionnairesResponses')
    //         .doc(docid)
    //         .update({
    //             SelectedWords: firebase.firestore.FieldValue.arrayRemove(word)
    //         })
    //     }
    // }

    const individualWords = (item, docid) => {

        if(typeof(item) !== 'number') {

        const words = item && item.split(' ')

        const wordArray = []
        
        words && words.forEach((word, index) => {

            // deleteOrphanedWords(docid, word)

            wordArray.push(
                <span
                key={index}
                // onMouseEnter={}
                // onMouseLeave={}
                data-word={word}
                data-docid={docid}
                onClick={selectWord}
                style={{
                    textDecoration: allWords.includes(word) ? 'underline' : 'none',
                    fontWeight: allWords.includes(word) ? 'bold' : '500',
                    color: responseWords.includes(word) ? color : '#000',
                   
                    cursor: 'pointer',
                }}
                >
                    {word}
                </span>
                )
            wordArray.push(' ')
        })

        return wordArray
    } else {

        return item
    }
}


  return (
    <div key={item.ID} className="analyse-categorie-response-container">
        <p>{individualWords(item.Input, item.docid)}</p>
    </div>
  )
}

export default AnalyseResponse