import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { useFirestoreGeneral } from '../../firebase/useFirestore';
import Location from "../../hooks/Location"
import DeleteModal from "../../components/common/DeleteModal";
import robotImage from "../../images/icons/robot-icon.png"
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from "../../images/icons/delete-icon.png"
import Tooltip from "../../components/common/Tooltip";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { useContext, useState, useEffect } from 'react';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import useSettings from "../../hooks/Settings";

const ResearchAnalysis = () => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)

  // State
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteDocid, setDeleteDocid] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [placeholderIndex, setPlaceholderIndex] = useState(null)
  const [hoverIndex, setHoverIndex] = useState(null)
  const [hightlighted, setHightlighted] = useState(false)
  const [hightlightText, setHightlightText] = useState('')
  const [chatOpen, setChatOpen] = useState(false)


  // Hooks
  const researchId = Location()[3]
  const { secundairyColor } = useSettings()

  // Firestore
  const researchInsights = useFirestoreGeneral('ResearchInsights', 'ResearchID', researchId ? researchId : '')

  // Delete modal 
  const deleteModalHandler = (e) => {
    const docid = e.target.dataset.docid
    const deleteName = e.target.dataset.deletename

    setDeleteDocid(docid)
    setDeleteName(deleteName)

    setDeleteModal(!deleteModal)
}

// Add insight
const addInsights = () => {
    db.collection('ResearchInsights').add({
        Title: '',
        ResearchID: researchId,
        ID: uuid()
    })
    .then(() => {
        setSaved('flex')
    })
}

// Delete insight
const deleteInsight = (e) => {

    db.collection('Questionnaires')
    .doc(deleteDocid)
    .delete()
    .then(() => {
        setSaved('flex')
        setDeleteModal(false)
    })
}

    // Reset hightlights
    useEffect(() => {
      setHightlighted(false)
      setHightlightText('')
  },[])

   // Hightlight impactAI
   const impactaiHandler = () => {
      setChatOpen(true)
      setHightlighted(!hightlighted)
      setHightlightText('Ja, ga je gang!')
  }

  // Title handler
  const titleHandler = (e) => {
    const docid = e.target.dataset.docid
    const value = e.target.value

    db.collection('ResearchInsights')
    .doc(docid)
    .update({
        Title: value
    })
    .then(() => {
        setSaved('flex')
    })
}

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <LightbulbOutlinedIcon/>
                <h1>Onderzoeksinzichten</h1>
            </div>
            <div className="table-container dashboard-container">
            <div className='list-top-row-container'>
                <Tooltip content={'Vragenlijst toevoegen'} width={'30px'} top='35px'>
                    <img src={plusButton} alt="" onClick={addInsights}/>
                </Tooltip>
                 <Tooltip content={'Kan ik je helpen?'} width={'30px'} top='-10px' left='30px'>
                    <img src={robotImage} alt="" className="ai-assistant-icon" onClick={impactaiHandler}/>
                </Tooltip>
            </div>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>INZICHT</th>
                        <th style={{backgroundColor: secundairyColor}}>DATA</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>
                {researchInsights && researchInsights.map((item, index) => (
                    <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={item}
                        input={
                            <Tooltip content={item.Title} width='100%' top='-60px'>
                                <input type="text" placeholder="Schrijf hier de titel" data-docid={item.docid} defaultValue={item.Title} onChange={titleHandler}/>
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'ResearchInsights'}
                        itemArray={researchInsights}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={item.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        >
                            
                            <td>
                              
                            </td>
                           
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} data-deletename={item.Title} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
            </div>
        </div>
        <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteInsight} deleteName={deleteName} />
    </div>
  )
}

export default ResearchAnalysis