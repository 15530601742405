import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { useFirestoreAll, useFirestore } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client"
import { v4 as uuid } from 'uuid';

const FundsDatabase = () => {

  // Hooks

    // Firestore
    const funds = useFirestoreAll('Funds')
    const compagnyFunds = useFirestore('CompanyFunds')

    const addFund = (e) => {

        const id = e.target.dataset.id

        db.collection('CompanyFunds').add({
            FundID: id,
            ID: uuid(),
            CompagnyID: client,
            Timestamp: timestamp,
            Position: compagnyFunds.length + 1,
            Status: 'not started'
        })
    }


  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <AccountBalanceOutlinedIcon/>
                <h1>Fondsenregister</h1>
            </div>
            <div className="table-container">
                {funds && funds.map((item, index) => (
                        <div className='dashboard-container' key={index}>
                        <h2>{item.Name}</h2>
                        <button data-id={item.ID} onClick={addFund}>Toevoegen aan mijn fondsen</button>
                    </div>
                ))}
            </div> 
        </div>
    </div>
  )
}

export default FundsDatabase