import { useFirestoreAllID } from '../../firebase/useFirestore';

const FundName = ({id}) => {

  console.log(id)

    const funds = useFirestoreAllID('Funds', id ? id : '')

    console.log(funds)
  return (
    <>
        {funds && funds.map((item, index) => (
            <>
                {item.Name}
            </>
        ))}
    </>
  )
}

export default FundName