import { useState, useContext } from "react";
import AddItemTableRow from "../../../components/common/AddItemTableRow.jsx";
import Tooltip from '../../../components/common/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { v4 as uuid } from 'uuid';
import { db, timestamp } from '../../../firebase/config';
import { useHistory } from "react-router-dom";
import DraggableTableItems from '../../../components/common/DraggableTableItems'; 
import useSettings from "../../../hooks/Settings";
import deleteIcon from '../../../images/icons/delete-icon.png'
import { client } from "../../../hooks/Client"

const PresenceTable = ({ presenceData, length, getStartTimeFromSchedule, getEndTimeFromSchedule, auth, personaId }) => {
    // State
    const [hoverIndex, setHoverIndex] = useState(null);
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    const history = useHistory();
    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const secundairyColor = useSettings().SecundairyColor

    // Helper function to get the current weekday and format date
    const getCurrentWeekdayInfo = () => {
        const currentDate = new Date();
        const currentWeekday = dayNames[currentDate.getDay()];
        const formattedDate = currentDate.toLocaleDateString('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

        return {
            date: formattedDate,
            type: 'correct',
            start: getStartTimeFromSchedule(currentWeekday),
            end: getEndTimeFromSchedule(currentWeekday),
        };
    };

    // Add a new presence report
    const addReport = () => {
        const { date, type, start, end } = getCurrentWeekdayInfo();
        
        db.collection('PersonaPresenceReports')
            .doc()
            .set({
                Date: date,
                Type: type,
                Start: start,
                End: end,
                CorrectedStart: '',
                CorrectedEnd: '',
                Absent: '',
                Reason: '',
                PresenceID: '',
                PersonaID: personaId,
                CompagnyID: client,
                Timestamp: timestamp,
                Author: auth.ID,
                ID: uuid(),
                Position: length + 1
            });
    };

    // Delete a report by document ID
    const deleteReport = (docid) => {
        db.collection('PersonaPresenceReports')
            .doc(docid)
            .delete()
            .then(() => console.log(`Report with ID ${docid} deleted`))
            .catch(error => console.error("Error deleting report:", error));
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: secundairyColor }}>DATE</th>
                        <th style={{ backgroundColor: secundairyColor }}>SCHEDULED</th>
                        <th style={{ backgroundColor: secundairyColor }}>PRESENT</th>
                        <th style={{ backgroundColor: secundairyColor }}>DIFFERENCE</th>
                        <th style={{ backgroundColor: secundairyColor }}>REASON</th>
                        <th style={{ backgroundColor: secundairyColor }}>EDIT</th>
                        <th style={{ backgroundColor: secundairyColor }}>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    {presenceData && presenceData.map((presence, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td colSpan="7" style={{ backgroundColor: secundairyColor }}></td>
                                </tr>
                            )}
                            <DraggableTableItems
                                item={presence}
                                input={<p>{presence.date}</p>}
                                parentIndex={index}
                                collection={'PersonaPresenceReports'}
                                itemArray={presenceData}
                                setHoverIndex={setHoverIndex}
                                hoverIndex={hoverIndex}
                                title={presence.date}
                                setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td><p>{presence.schedule}</p></td>
                                <td><p>{presence.present}</p></td>
                                <td><p>{presence.present - presence.schedule}</p></td>
                                <td><p>{presence.reason === "" ? '-' : presence.reason.charAt(0).toUpperCase() + presence.reason.slice(1)}</p></td>
                                <td>
                                    <EditOutlinedIcon onClick={() => history.push(`/${client}/personapresencereportdetail/${presence.id}`)} />
                                </td>
                                <td>
                                    <Tooltip content="Verwijderen" width="30px" top="-20px">
                                        <img 
                                            className='table-delete-icon' 
                                            src={deleteIcon} 
                                            alt="delete" 
                                            onClick={() => deleteReport(presence.docid)}
                                            data-docid={presence.docid} 
                                        />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </tbody>
            </table>
            <AddItemTableRow toolTipContent="Aanwezigheidsrapport toevoegen" onClick={addReport} />
        </div>
    );
};

export default PresenceTable;
