import { createContext, useState } from "react";

export const Onboarding = createContext()

export const OnboardingProvider = (props) => {
    const [onboarding, setOnboarding] = useState(false)
    const [onboardingNotice, setOnboardingNotice] = useState(false)
    const [step, setStep] = useState(1)

    return(
        <Onboarding.Provider value={{onboarding, setOnboarding, step, setStep, onboardingNotice, setOnboardingNotice}}>
            {props.children}
        </Onboarding.Provider>
    )
}