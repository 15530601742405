import { useFirestoreGeneralTwo } from '../../../firebase/useFirestore'
import MultiMatrixDevelopmentGraph from '../../Visualisations/MultiMatrixDevelopmentGraph';

const MultiMatrixDevelopment = ({field, researchID}) => {

  const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'FieldID', field, 'ResearchID', researchID)

  const getRows = () => {

    const rowArray = []

    results && results.forEach(result => {
      const input = result.Input

      // Get column and row
      const columnRowArray = input.split('/')
      const row = columnRowArray[1]

      // Filter duplicates and set rows
      if(!rowArray.includes(row)){
        rowArray.push(row)
      }

    })

    return rowArray
  }

  return (
    <>
      {getRows().map(row => (
        <MultiMatrixDevelopmentGraph 
          row={row} 
          results={results} 
          field={field} 
          researchID={researchID} 
        />
      ))}
    </>
  )



  
}

export default MultiMatrixDevelopment