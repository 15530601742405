import { useFirestoreGeneral } from "../../firebase/useFirestore"
import LiveReportEffectData from "./LiveReportEffectData"
import LiveReportMedia from "./LiveReportMedia"
import { db } from "../../firebase/config"
import deleteIcon from '../../images/icons/delete-icon.png'
import firebase from 'firebase'
import Tooltip from "../common/Tooltip"

const ReportBuilderEffects = ({effect, docid}) => {

  const effects = useFirestoreGeneral('OutputEffects', 'ID', effect ? effect : '123' )

    const effectDescriptionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('OutputEffects')
        .doc(docid)
        .update({
            Description: e.target.value
        })
    }

    const deleteEffect = (e) => {
        const id = e.target.dataset.id

        console.log(id)
        console.log(docid)

        db.collection('LiveReports')
        .doc(docid)
        .update({
            KPIs: firebase.firestore.FieldValue.arrayRemove(id)
        })
    }


  return (
    <div className="report-activities-container">
        {effects && effects.map(item => (
            <div className="report-activity-item-container" style={{borderBottom: effects.length === 1 ? 'none' : '1px solid lightgray'}}>
                <div className="report-activity-item-description-container">
                    <h3>{item.Effect}</h3>
                    <textarea cols="30" data-docid={item.docid} defaultValue={item.Description} placeholder="Voeg een omschrijving toe van de activiteit" rows="10" onChange={effectDescriptionHandler}></textarea>
                    <LiveReportMedia parent={item}/>
                </div>
                <div className="report-activity-item-graph-container">
                    <h3>Resultaten uit onderzoek</h3>
                    <LiveReportEffectData effectId={item.ID} researchId={item.researchId}/>
                </div>
                <Tooltip content="Effect verwijderen" width='4%' top='-60px'>
                    <img className="delete-icon-report-builder" src={deleteIcon} alt="" data-id={item.ID} onClick={deleteEffect}/>
                </Tooltip>
            </div>
        ))}
    </div>
  )
}

export default ReportBuilderEffects