import { useFirestoreAllID } from '../../firebase/useFirestore'

const UserImageMeta = ({userID}) => {

    const users = useFirestoreAllID('Users', userID ? userID : '123')

  return (
    <>
        {users && users.map( user => (
            <img src={user.Photo} key={user.ID} alt={user.Name} />
        ))}
    </>
  )
}

export default UserImageMeta