import PageHeader from "../../components/layout/PageHeader.jsx";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import useSettings from "../../hooks/Settings";
import { useState } from "react";
import { useFirestoreOrderBy } from '../../firebase/useFirestore';
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from '../../firebase/config';
import uuid from 'react-uuid';
import { timestamp } from '../../firebase/config';
import { client } from '../../hooks/Client';
import saveFile from '../../hooks/SaveFile'

const Storytelling = () => {
    // State
    const [activeTab, setActiveTab] = useState('media')
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [file, setFile] = useState(null)
    const [fileMeta, setFileMeta] = useState(null)

    // Hooks
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const media = useFirestoreOrderBy('LiveReportMedia', 'Position', 'asc')
    const quotes = useFirestoreOrderBy('Quotes', 'Position', 'asc')
    const anecdotes = useFirestoreOrderBy('Anecdotes', 'Position', 'asc')

    // Delete media item
    const deleteMediaItem = (e) => {
        const docid = e.target.getAttribute('data-docid')
        db.collection('LiveReportMedia').doc(docid).delete()
    }

    // Delete qoute item
    const deleteQuoteItem = (e) => {
        const docid = e.target.getAttribute('data-docid')
        db.collection('Quotes').doc(docid).delete()
    }

    // Media handler
    const mediaHandler = (e) => {
        saveFile(e, setFile, setFileMeta)
    }

    // Add media item
    const addMedia = () => {

        db.collection('LiveReportMedia')
        .doc()
        .set({
            Media: file,
            Type: fileMeta.type,
            ID: uuid(),
            Timestamp: timestamp,
            CompagnyID: client,
            Position: media.length + 1
        })
        .then(() => {
            setShowAddContainer(false)
        })
    }

    // Add qoute item
    const addQuote = () => {

        db.collection('Quotes')
        .doc()
        .set({
            Quote: '',
            Name: '',
            ID: uuid(),
            Timestamp: timestamp,
            CompagnyID: client,
            Position: quotes.length + 1,
            Type: 'Quote'
        })

    }

    // Update qoute title
    const quoteTitleHandler = (e) => {

        const docid = e.target.dataset.docid
        const quote = e.target.value

        db.collection('Quotes')
        .doc(docid)
        .update({
            Quote: quote
        })
    }

    // Update qoute name
    const quoteNameHandler = (e) => {

        const docid = e.target.dataset.docid
        const name = e.target.value

        db.collection('Quotes')
        .doc(docid)
        .update({
            Name: name
        })
    }

    // Media type
    const mediaType = (type) => {

        const parts = type.split('/')

        if (parts.includes('image')) {
            return 'Afbeelding'
        } else if (parts.includes('video')) { 
            return 'Video'
        }
    }

    // Add anecdote item
    const addAnecdote = () => {

        db.collection('Anecdotes')
        .doc()
        .set({
            Anecdote: '',
            Name: '',
            ID: uuid(),
            Timestamp: timestamp,
            CompagnyID: client,
            Position: anecdotes.length + 1,
            Type: 'Anecdote'
        })

    }

    // Update anecdote
    const anecdoteHandler = (e) => {

        const docid = e.target.dataset.docid
        const anecdote = e.target.value

        db.collection('Anecdotes')
        .doc(docid)
        .update({
            Anecdote: anecdote
        })
    }

    // Update anecdote name
    const anecdoteNameHandler = (e) => {

        const docid = e.target.dataset.docid
        const name = e.target.value

        db.collection('Anecdotes')
        .doc(docid)
        .update({
            Name: name
        })
    }

    // Delete anecdote item
    const deleteAnecdote = (e) => {
        const docid = e.target.dataset.docid
        db.collection('Anecdotes').doc(docid).delete()
    }


  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Storytelling'}
                description={`Hier kun je de foto's, video's, qoutes en anekdotes toevoegen waarmee je je impact laat zien.`}
                AI={false}
            />
            <div className="select-activity-container select-activity-container-wizard">
              <div className="select-activity-inner-container">
                  <div  className='activity-select-item-container' onClick={() => setActiveTab('media')} style={{backgroundColor: activeTab === 'media' ? secundairyColor : 'white'}}>
                      <p onClick={() => setActiveTab('media')}  style={{color:  activeTab === 'media' ? 'white' : '#616161'}}>Media</p>
                  </div>
                  <div  className='activity-select-item-container' onClick={() => setActiveTab('quotes')} style={{backgroundColor: activeTab === 'quotes' ? secundairyColor : 'white'}}>
                      <p onClick={() => setActiveTab('quotes')}  style={{color:  activeTab === 'quotes' ? 'white' : '#616161'}}>Quotes</p>
                  </div>
                  <div  className='activity-select-item-container' onClick={() => setActiveTab('anecdotes')} style={{backgroundColor: activeTab === 'anecdotes' ? secundairyColor : 'white'}}>
                      <p onClick={() => setActiveTab('anecdotes')}  style={{color:  activeTab === 'anecdotes' ? 'white' : '#616161'}}>Anekdotes</p>
                  </div>
              </div>
          </div>
            <div className="table-container dashboard-container">
                {activeTab === 'media' && (
                    <>
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>MEDIA</th>
                                <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                            {media && media.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <img id='table-media-item' src={item.Media} alt="" />
                                </td>
                                <td>
                                    <p>{mediaType(item.Type)}</p>
                                </td>
                                <td>
                                    <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteMediaItem}/>
                                </td>
                            </tr>
                            ))}
                        </table>
                        <AddItemTableRow toolTipContent={'Media toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
                        <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}}>
                            <div className="add-options-container-item-container">
                                <input type="file" onChange={mediaHandler}/>
                            </div>
                            <button className="button-simple" onClick={addMedia}>Opslaan</button>
                        </div>
                    </>
                )}

                {activeTab === 'quotes' && (
                    <>
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>QUOTE</th>
                                <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                            {quotes && quotes.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <input type="text" placeholder='Schrijf hier de quote' data-docid={item.docid} defaultValue={item.Quote} onChange={quoteTitleHandler}/>
                                </td>
                                <td>
                                    <input type="text" placeholder='Schrijf hier de naam' data-docid={item.docid} defaultValue={item.Name} onChange={quoteNameHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteQuoteItem}/>
                                </td>
                            </tr>
                            ))}
                        </table>
                        <AddItemTableRow toolTipContent={'Quote toevoegen'} onClick={addQuote}/>
                    </>
                )}

                {activeTab === 'anecdotes' && (
                    <>
                        <table>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>ANEKDOTE</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                            {anecdotes && anecdotes.map(item => (
                            <tr key={item.ID}>
                                <td>
                                    <textarea type="text" placeholder='Schrijf hier de anekdote' data-docid={item.docid} defaultValue={item.Anecdote} onChange={anecdoteHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteAnecdote}/>
                                </td>
                            </tr>
                            ))}
                        </table>
                        <AddItemTableRow toolTipContent={'Qoute toevoegen'} onClick={addAnecdote}/>
                    </>
                )}
            </div>
        </div>
    </div>
  )
}

export default Storytelling
