import { useFirestoreGeneral } from '../../firebase/useFirestore'

const OutputMeta = ({id}) => {

    const outputs = useFirestoreGeneral('Outputs', 'ID', id)

  return (
    <>
        {outputs && outputs.map(item => (
            <>{item.Title}</>
        ))}
    </>
  )
}

export default OutputMeta