import React from 'react'
import { useState } from 'react'
import { db, timestamp } from "../../firebase/config.js"

const Impactplan = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [compagny, setCompagny] = useState('')
    const [showForm, setShowForm] = useState(true)

    const nameHandler = (e) => {
        setName(e.target.value)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
    }

    const compagnyHandler = (e) => {
        setCompagny(e.target.value)
    }

    const createPlan = () => {
    
        if(name === '' || email === '' || compagny === ''){
            alert('Vul alle velden in')
        } else{
            db.collection('Leadlist')
            .doc()
            .set({
                Name: name,
                Email: email,
                Compagny: compagny,
                Timestamp: timestamp
            })
            .then(() => {
                setShowForm(false)
            })
        }
    }


  return (
    <div className='page-container'>
        <div id='impact-plan-form-container' style={{display: showForm ? 'flex' : 'none'}}>
            <h1>Gepersonaliseerd impactplan</h1>
            <p><b>Naam*</b></p>
            <input type="text" placeholder="Schrijf hier je naam" onChange={nameHandler}/>
            <p><b>E-mail*</b></p>
            <input type="text" placeholder="Schrijf hier je emailadres" onChange={emailHandler}/>
            <p><b>Bedrijfsnaam*</b></p>
            <input type="text" placeholder="Schrijf hier de naam van je bedrijf" onChange={compagnyHandler}/>
            <div class="button-container">
                <button id="send-impact-plan-form" onClick={createPlan}>Genereer mijn plan</button>
            </div>
        </div>
        
    </div>
  )
}

export default Impactplan