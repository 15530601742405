import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend
  } from "recharts";
import { useState, useEffect } from 'react'
import useSettings from "../../hooks/Settings";
import { useFirestoreGeneralTwo } from "../../firebase/useFirestore";
import GroupBy from "../../hooks/GroupBy";

const ScaleDevelopmentResultsGraphResponsive = ({field, researchID}) => {
    const [data, setData] = useState('')

    const primaryColor = useSettings().PrimaryColor

    const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'ResearchID', researchID ? researchID : '', 'FieldID', field ? field : '')

    const resultsArray = []

    results && results.forEach(result => {
        const resultsObject = {
            Input: parseInt(result.Input),
            Position: result.Position,
        }

        resultsArray.push(resultsObject)
    })

    const array = Object.entries(GroupBy(resultsArray, 'Position')) 

    const totalArray = []

    array && array.forEach(arr => {

        const sumArray = []

        arr[1] && arr[1].forEach(a => {
            sumArray.push(Math.round(a.Input * 10) / 10)
        })

        const sum = array.length > 0 ? sumArray.reduce((partialSum, a) => partialSum + a, 0) : 0

        const total = sum/arr[1].length

        const totalObject = {
            Title: arr[0],
            AvgScore: total
        }

        totalArray.push(totalObject)

    })

    useEffect(() => {

        const resultArray = []

        totalArray && totalArray.forEach(result => {

            const title = ''
            const score = Math.round(result.AvgScore * 10) / 10

            const resultObject = {
                Title: title,
                Gemiddelde: score
            }

            resultArray.push(resultObject)
        })

       setTimeout(() => {
        setData(resultArray)
       }, 1000)

    },[results])


  return (
    <ResponsiveContainer width="90%" height={300}>
        <AreaChart
        data={data && data}
        margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
        }}
            >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Title" />
        <YAxis/>
        <Legend />
        {/* <Tooltip /> */}
        <Area type="monotone" dataKey="Gemiddelde" stroke={primaryColor} fill={primaryColor} fill-opacity="1" />
        </AreaChart>
    </ResponsiveContainer>
  )
}

export default ScaleDevelopmentResultsGraphResponsive