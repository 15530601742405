import LeftSideBar from "./LeftSideBar"
import ChatScreen from "../AI/ChatScreen"
import Topbar from "./Topbar"

const Layout = ({children}) => {
  return (
    <div className="main">
        <LeftSideBar />
        <div id='page-container'>
            <Topbar />
            {children}
        </div>
        <ChatScreen />
    </div>
  )
}

export default Layout