import Location from '../../hooks/Location';
import { useEffect, useState, useContext } from 'react';
import { useFirestoreGeneral, useFirestoreID, useFirestoreOrderBy } from '../../firebase/useFirestore';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db, timestamp } from '../../firebase/config';
import ButtonClickedSimple from '../../hooks/ButtonClickedSimple';
import { Auth } from '../../StateManagment/Auth'

const PersonaReportDetail = () => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon);
  const [auth] = useContext(Auth)

  // State
  const [body, setBody] = useState('');
  const [docid, setDocid] = useState('');
  const [date, setDate] = useState('');
  const [pairedGoals, setPairedGoals] = useState([]);
  const [checkedGoals, setCheckedGoals] = useState([]);

  // Hooks
  const reportId = Location()[4];
  const personaId = Location()[3];
  const compagnyId = Location()[1];
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  // Firestore
  const report = useFirestoreID('PersonaReports', reportId ? reportId : '');
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', personaId ? personaId : '');
  const reportStandards = useFirestoreOrderBy('PersonaReportStandard', 'Position', 'asc')

  // Set docid of goal in state and initialize pairedGoals and checkedGoals
  useEffect(() => {
    report && report.forEach(item => {
      setBody(item.Body);
      setDocid(item.docid);
      setDate(item.Timestamp.toDate().toLocaleDateString("nl-NL", options));
      setPairedGoals(item.Goals || []);
      setCheckedGoals(item.Goals ? item.Goals.map(id => ({ id })) : []);
    });
  }, [report]);

  // // Save body to firestore
  // const saveBody = (e) => {

  //   ButtonClickedSimple(e, 'Opslaan...', 'Opslaan');

  //   db.collection('PersonaReports').doc(docid).update({
  //     Body: body
  //   });
  // };

  // Handle report body changes
  const reportHandler = (e) => {

    const value = e.target.value;
    const standardId = e.target.getAttribute('data-standardid');

    db.collection('PersonaReportsSections')
    .where('ReportID', '==', reportId)
    .where('StandardID', '==', standardId)
    .where('CompanyID', '==', compagnyId)
    .get()

    .then((querySnapshot) => {
      if(querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          db.collection('PersonaReportsSections').doc(doc.id).update({
            Body: value
          });
        });
      } else {
        db.collection('PersonaReportsSections').add({
          ReportID: reportId,
          StandardID: standardId,
          CompanyID: compagnyId,
          Body: value,
          Timestamp: timestamp,
          User: auth.ID
        });
      }
    });
  };

  // Get the default value for a report section
  const defaultReportSectionValue = (standardId) => {
    const reportSection = report.find(reportSection => reportSection.StandardID === standardId);
    return reportSection ? reportSection.Body : '';
  };

  // Toggle selected goals
  const handleCheckboxChange = (id, docid) => {
    setCheckedGoals(prevState => {
      const isSelected = prevState.some(goal => goal.id === id);
      if (isSelected) {
        // Remove the goal if it was selected
        return prevState.filter(goal => goal.id !== id);
      } else {
        // Add the goal if it was not selected
        return [...prevState, { id, docid }];
      }
    });
  };

  // Save the selected goals to firestore
  const saveGoals = (e) => {

    ButtonClickedSimple(e, 'Opslaan...', 'Opslaan');

    const goalIds = checkedGoals.map(goal => goal.id); // Get all goal IDs
    db.collection('PersonaReports')
      .doc(docid)
      .update({
        Goals: goalIds
      });
    setPairedGoals(goalIds); // Update pairedGoals to reflect saved state
  };

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <h1>Rapportage instellingen</h1>
          <p>{date}</p>
        </div>
        <div className="dashboard-container">
          <h2>Rapportage</h2>
          <div>
            {reportStandards && reportStandards.map((reportStandard) => (
              <div key={reportStandard.ID} className="table-item">
                <div className="table-item-content">
                  <h3>{reportStandard.Title}</h3>
                  <p>{reportStandard.Description}</p>
                  <textarea name="" placeholder='Schrijf hier de rapportage' defaultValue={defaultReportSectionValue(reportStandard.ID)} data-standardid={reportStandard.ID} id="" onChange={reportHandler}></textarea>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="dashboard-container">
          <h2>Doelen</h2>
          <p>Selecteer de doelen waarop deze rapportage betrekking heeft:</p>
          {goals && goals.map(goal => (
            <div key={goal.ID} id='persona-goal-item-container'>
              <input 
                type="checkbox" 
                checked={checkedGoals.some(q => q.id === goal.ID)}
                onChange={() => handleCheckboxChange(goal.ID, goal.docid)}
              />
              <p>{goal.Goal}</p>
            </div>
          ))}
          <button className="button-simple" onClick={saveGoals}>Opslaan</button>
        </div>
      </div>
    </div>
  );
};

export default PersonaReportDetail;
