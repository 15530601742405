import React, { useEffect } from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestore, useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import firebase from 'firebase'
import { useContext, useRef, useState } from "react";
import useSettings from "../../hooks/Settings";
import saveFile from '../../hooks/SaveFile'
import DownloadIcon from '../../images/icons/download-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow'
import DraggableTableItems from '../../components/common/DraggableTableItems'

const Data = ({personaNavigation, item, personaDocid}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [file, setFile] = useState(null);
    const [fileMeta, setFileMeta] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [showAddContainer, setShowAddContainer] = useState(false);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const personaId = Location()[3]
    const fileInputRef = useRef(null);

    // Firestore
    const routes = useFirestore('PersonaRoutes')
    const risks = useFirestoreGeneralOrderBy('PersonaRisks', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const networks = useFirestoreGeneralOrderBy('PersonaNetwork', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const data = useFirestoreGeneralOrderBy('PersonaData', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const documents = useFirestoreGeneralOrderBy('PersonaDocuments', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')

    // Functions
    const nawOptions = [
        'Voornaam',
        'Achternaam',
        'Straatnaam',
        'Huisnummer',
        'Postcode',
        'Woonplaats',
        'Telefoonnummer',
        'Email',
        'BSN'
    ]

    const startDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            StartDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const endDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            EndDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const addNAW = () => {

        db.collection('PersonaData')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Title: '',
            Value: '',
            CompagnyID: client,
            Compagny: client,
            Position: data.length + 1,
            Timestamp: timestamp
        })

    }

    const addNetwork = () => {

        db.collection('PersonaNetwork')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Name: '',
            PhoneNumber: '',
            CompagnyID: client,
            Compagny: client,
            Position: networks.length + 1,
            Timestamp: timestamp
        })

    }

    const addRisk = () => {

        db.collection('PersonaRisks')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Risk: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: risks.length + 1,
            Timestamp: timestamp,
            Position: risks.length + 1
        })

    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.options[e.target.selectedIndex].value

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const valueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Value: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteNAW = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaData')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })
    }

    const routeHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            RouteID: value
        })

    }

    const networkNameHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
           Title: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkRelationHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            Relation: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkPhoneHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            PhoneNumber: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Risk: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskValueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteNetwork = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteRisk = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    
    // Function to handle file selection
    const handleFileChange = (e) => {
        const document = e.target.files[0];
        if (document) {
            // Save file to Firebase Storage
            saveFile(e, setFile, setFileMeta)
        }
    };

    // Function to handle file save
    const handleSaveDocument = () => {
        db.collection('PersonaDocuments').add({
            ID: uuid(),
            PersonaID: personaId,
            Title: fileMeta.name,
            Type: '',
            URL: file,
            CompagnyID: client,
            Compagny: client,
            Position: documents.length + 1,
            Timestamp: timestamp
        })
        .then(() => {
            setSaved('flex')
            setShowAddContainer(false)
        })
    };

    const documentTypeHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .update({
            Type: e.target.options[e.target.selectedIndex].value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteDocument = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const documentTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .update({
            Title: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    };

    const addData = () => {
            
            db.collection('PersonaData')
            .add({
                ID: uuid(),
                PersonaID: personaId,
                Title: '',
                Value: '',
                CompagnyID: client,
                Compagny: client,
                Position: data.length + 1,
                Timestamp: timestamp
            })
            .then(() => {
                setSaved('flex')
            })
    }


  return (
    <div style={{display: personaNavigation === 'data' ? 'block' : 'none'}}>
        <h2>Gegevens</h2>
        <div>
            <div class="table-container dashboard-container">
                <h3>NAW</h3>
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}>TITLE</th>
                                <th style={{backgroundColor: secundairyColor}}>GEGEVEN</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => (
                                <React.Fragment key={item.docid}>  {/* key prop is necessary */}
                                    {placeholderIndex === index && (
                                        <tr className="placeholder-row">
                                            <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                        </tr>
                                    )}
                                    <DraggableTableItems
                                        item={item}  
                                        input={
                                            <select name="" id="" defaultValue={item.Title} data-docid={item.docid} onChange={titleHandler}>
                                                <option value="">-- Selecteer --</option>
                                                {nawOptions && nawOptions.map((option, i) => (
                                                    <option key={i} value={option}>{option}</option> 
                                                ))}
                                            </select>
                                        }
                                        parentIndex={index}
                                        collection={'PersonaData'}
                                        itemArray={data}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={item.Title}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                    >
                                        <td>
                                            <input type="text" data-docid={item.docid} defaultValue={item.Value} onChange={valueHandler} />
                                        </td>
                                        <td>
                                            <img className='table-delete-icon' data-docid={item.docid} onClick={deleteNAW} src={deleteIcon} alt="" />
                                        </td>
                                    </DraggableTableItems>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <AddItemTableRow toolTipContent={'Gegeven toevoegen'} onClick={addData}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Traject</h3>
                <p>Trajectnaam</p>
                <select name="" id="" onChange={routeHandler} value={item.RouteID}>
                    <option value="">-- Selecteer een traject --</option>
                    {routes && routes.map(route => (
                        <option key={route.ID} value={route.ID}>{route.Title}</option>
                    ))}
                </select>
                <p>Startdatum</p>
                <input type="date" defaultValue={item.StartDate} onChange={startDateHandler} />
                <p>Einddatum</p>
                <input type="date" defaultValue={item.EndDate} onChange={endDateHandler}/>
            </div>

            <div class="table-container dashboard-container">
                <h3>Netwerk</h3>
                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>RELATIE</th>
                            <th style={{backgroundColor: secundairyColor}}>TELEFOONNUMMER</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        {networks && networks.map((item, index) => (
                            <React.Fragment key={item.docid}> 
                                    {placeholderIndex === index && (
                                        <tr className="placeholder-row">
                                            <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                        </tr>
                                    )}
                                    <DraggableTableItems
                                        item={item}  
                                        input={
                                            <input type="text" data-docid={item.docid} defaultValue={item.Title} onChange={networkNameHandler}/>
                                        }
                                        parentIndex={index}
                                        collection={'PersonaNetwork'}
                                        itemArray={networks}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={item.Title}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                    >
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Relation} onChange={networkRelationHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.PhoneNumber} onChange={networkPhoneHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteNetwork} src={deleteIcon} alt="" />
                                </td>
                                </DraggableTableItems>
                            </React.Fragment>
                        ))}
                    </table>
                    <AddItemTableRow toolTipContent={'Netwerk toevoegen'} onClick={addNetwork}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Risico's</h3>

                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>RISICO</th>
                            <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        {risks && risks.map((item, index) => (
                            <React.Fragment key={item.docid}> 
                                    {placeholderIndex === index && (
                                        <tr className="placeholder-row">
                                            <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                        </tr>
                                    )}
                                    <DraggableTableItems
                                        item={item}  
                                        input={
                                            <input type="text" data-docid={item.docid} defaultValue={item.Risk} onChange={riskTitleHandler}/>
                                        }
                                        parentIndex={index}
                                        collection={'PersonaRisks'}
                                        itemArray={risks}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={item.Title}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                    >
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Description} onChange={riskValueHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteRisk} src={deleteIcon} alt="" />
                                </td>
                                </DraggableTableItems>
                            </React.Fragment>
                        ))}
                    </table>
                    <AddItemTableRow toolTipContent={'Risico toevoegen'} onClick={addRisk}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
            <h3>Documenten</h3>
            <div className='table-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                        <th style={{backgroundColor: secundairyColor}}>DOWNLAODEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                    </tr>
                    {documents && documents.map((item, index) => (
                         <React.Fragment key={item.docid}> 
                                    {placeholderIndex === index && (
                                        <tr className="placeholder-row">
                                            <td colSpan="3" style={{backgroundColor: secundairyColor}}></td>
                                        </tr>
                                    )}
                                    <DraggableTableItems
                                        item={item}  
                                        input={
                                            <input type="text" defaultValue={item.Title} data-docid={item.docid} onChange={documentTitleHandler}/>
                                        }
                                        parentIndex={index}
                                        collection={'PersonaDocuments'}
                                        itemArray={documents}
                                        setHoverIndex={setHoverIndex}
                                        hoverIndex={hoverIndex}
                                        title={item.Title}
                                        setPlaceholderIndex={setPlaceholderIndex}
                                    >
                            <td>
                                <select name="" id="" defaultValue={item.Type} data-docid={item.docid} onChange={documentTypeHandler}>
                                    <option value="">-- Selecteer --</option>
                                    <option value="care-agreement">Zorgovereenkomst</option>
                                    <option value="id">Identiteitsdocument</option>
                                    <option value="contract">Contract</option>
                                </select>
                            </td>
                            <td>
                                <a href={item.URL} target='_blank' download>
                                    <img className='table-delete-icon' src={DownloadIcon} alt="Download" />
                                </a>
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} onClick={deleteDocument} src={deleteIcon} alt="" />
                            </td>
                            </DraggableTableItems>
                        </React.Fragment>
                ))}
                </table>
                <AddItemTableRow toolTipContent={'Document toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
                <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}}>
                    <input
                    id='persona-document-upload-input'
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    />
                    <div className='add-options-upload-buttons-container'>
                        <button className='button-simple' onClick={() => setShowAddContainer(!showAddContainer)}>Annuleren</button>
                        <button className='button-simple' onClick={handleSaveDocument}>Uploaden</button>
                    </div>
                </div>
            </div>
        </div>


        </div>
    </div>
  )
}

export default Data