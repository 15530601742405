import { client } from "../../hooks/Client"
import { useFirestore, useFirestoreOrderBy} from "../../firebase/useFirestore"
import printIcon from "../../images/icons/print-icon.png"
import arrowLeftIcon from "../../images/icons/arrow-left-icon.png"
import { useHistory } from "react-router-dom";

const PrintOutputs = () => {

    // Hooks
    const history = useHistory()

    // Firestore
    const outputs = useFirestore('Outputs')
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')

    // Get acrivites for outputs
    const activitiesList = () => {

        const array = []

        outputs && outputs.map(output => {
            const activityId = output.ActivityID

            const activity = activities && activities.find(activity => activity.ID === activityId)

            const activityName = activity.Activity

            const object = 
                {
                    output: output.Title,
                    activity: activityName
                }
            
            array.push(object)
        })

        // Order array by activity
        array.sort((a, b) => a.activity.localeCompare(b.activity))

        return array
    }

    activitiesList()

  return (
    <div className="main">
        <div className="main-container">
            <div className="print-go-back-container" onClick={() => history.push(`/${client}/outputs`)}>
                <img src={arrowLeftIcon}/>
                <p>Terug</p>
            </div>
            <div className="print-icon-container" onClick={() => window.print()}>
                <img src={printIcon} alt="print icon" className="print-icon" />
                <p>Print outputs</p>
            </div>
            <div id='print-outputs-container'>
                <h1>Registratieformulier outputs</h1>
                <div id='print-outputs-period-container'>
                    <h2>Periode</h2>
                    <p>............................................</p>
                </div>
                <div class="table">
                    <div class="table-row header">
                        <div class="table-cell">ACTIVITEIT</div>
                        <div class="table-cell">OUTPUT</div>
                        <div class="table-cell">AANTAL</div>
                    </div>
                    {activitiesList() && activitiesList().map((item) => (
                        <div class="table-row">
                            <div class="table-cell">{item.activity}</div>
                            <div class="table-cell">{item.output}</div>
                            <div class="table-cell"></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default PrintOutputs