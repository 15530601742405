import { useFirestoreGeneralTwo } from "../../firebase/useFirestore";

const FieldResponsesCount = ({fieldID, researchID}) => {

    const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'FieldID', fieldID ? fieldID : '', 'ResearchID', researchID ? researchID : '')

  return (
    <p id='field-responses-count'>({results.length})</p>
  )
}

export default FieldResponsesCount