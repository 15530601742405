import { useFirestoreGeneralArrayContains } from '../../firebase/useFirestore'
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';

const LiveReportDashboardMedia = ({parent}) => {

    const media = useFirestoreGeneralArrayContains('LiveReportMedia', 'Parent', parent.ID)
    const anecdotes = useFirestoreGeneralArrayContains('Anecdotes', 'Parent', parent.ID)
    const quotes = useFirestoreGeneralArrayContains('Quotes', 'Parent', parent.ID)

    const displayMedia = (type, item) => {
      const filetype = type.split('/')[0]
      
      if(filetype === 'image') {
          return <img src={item.Media} alt="" />
      } else if(filetype === 'video') {
          return <video controls><source src={item.Media}/></video>
      } else if(filetype === 'audio') {
          return <audio controls><source src={item.Media}/></audio>
      }
  }

  return (
    <>
        {media && media.map(item => (
            <div className="report-media-dashboard-item-container">
                {displayMedia(item.Type, item)}
            </div>
        ))}
        {quotes && quotes.map(item => (
            <div className="report-media-dashboard-item-container">
                <div id='live-report-media-dashboard-qoute-container'>
                    <FormatQuoteOutlinedIcon />
                    <h2>{item.Quote}</h2>
                    <FormatQuoteOutlinedIcon />
                    <p>-</p>
                    <p>{item.Name}</p>
                </div>
                
            </div>
        ))}
        {anecdotes && anecdotes.map(item => (
            <div className="report-media-dashboard-item-container">
                <div id='live-report-media-dashboard-anecdote-container'>
                    <p>{item.Anecdote}</p>
                </div>
               
            </div>
        ))}
      
    </>
  )
}

export default LiveReportDashboardMedia