import { useFirestoreAllID } from '../../firebase/useFirestore'

const UserNameMeta = ({userID}) => {

    const users = useFirestoreAllID('Users', userID ? userID : '123')

  return (
    <>
        {users && users.map( user => (
            <p key={user.ID}>
                {user.ForName}
            </p>
        ))}
    </>
  )
}

export default UserNameMeta