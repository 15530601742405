import { createContext, useState, useRef } from "react";

export const ImpactAI = createContext();

export const ImpactAIProvider = (props) => {
    const [type, setType] = useState("none");
    const [systemMessage, setSystemMessage] = useState("none");
    const [toolCallContent, setToolCallContent] = useState("none");
    const [collection, setCollection] = useState("none");
    const [docid, setDocid] = useState("none");
    const [field, setField] = useState("none");
    const [saveMessage, setSaveMessage] = useState("none");
    const [startMessage, setStartMessage] = useState("none");
    const [parentId, setParentId] = useState("none");
    const [exampleMessages, setExampleMessages] = useState([]);
    const [chatOpen, setChatOpen] = useState(false);
    const [hightlighted, setHightlighted] = useState(false);
    const [hightlightText, setHightlightText] = useState("");
    const [position, setPosition] = useState(0);
    const [extraData, setExtraData] = useState("none");
    const [message, setMessage] = useState('');

    // Ref to function to send message from another component (like PageHeader)
    const sendMessageHandlerRef = useRef(null);

    return (
        <ImpactAI.Provider value={{
            type: [type, setType],
            systemMessage: [systemMessage, setSystemMessage],
            toolCallContent: [toolCallContent, setToolCallContent],
            collection: [collection, setCollection],
            docid: [docid, setDocid],
            field: [field, setField],
            saveMessage: [saveMessage, setSaveMessage],
            startMessage: [startMessage, setStartMessage],
            parentId: [parentId, setParentId],
            exampleMessages: [exampleMessages, setExampleMessages],
            chatOpen: [chatOpen, setChatOpen],
            hightlighted: [hightlighted, setHightlighted],
            hightlightText: [hightlightText, setHightlightText],
            position: [position, setPosition],
            extraData: [extraData, setExtraData],
            newMessage: [message, setMessage],
            sendMessageHandlerRef
        }}>
            {props.children}
        </ImpactAI.Provider>
    );
}