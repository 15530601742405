import { useState, useEffect } from 'react'
import { portfolioFunctions } from '../../firebase/configPortfolio'
import spinner from '../../images/spinner-ripple.svg'

const PortfolioOutputs = ({portfolioID, outputID, syncs}) => {
    // State
    const [portfolioOutput, setPortfolioOutput] = useState()
    const [loading, setLoading] = useState(true)

    // Get portfolio outputmeta from API
    useEffect(() => {
        const getPortfolioOutput = async () => {
    
          const portfolioOutput = portfolioFunctions.httpsCallable('portfolioOutput');

          const data = {
              portfolioID: portfolioID,
              outputID: outputID
          }
    
          portfolioOutput({ data: data })
          .then((result) => {
            setPortfolioOutput(result.data.title)
            setLoading(false)
          })
          .catch((error) => {
            // Handle errors
            console.error(error);
            alert(`Er is iets mis gegaan, neem contact op met Deccos`)
          });
        }
    
        getPortfolioOutput()
    }, [syncs])

  return (
    <>{loading ? <img src={spinner}/> : portfolioOutput}</>
  )
}

export default PortfolioOutputs