import { useState, useEffect } from 'react'
import { portfolioFunctions } from '../../firebase/configPortfolio'
import spinner from '../../images/spinner-ripple.svg'

const PortfolioEffects = ({portfolioID, effectID, syncs}) => {
    // State
    const [portfolioEffect, setPortfolioEffect] = useState()
    const [loading, setLoading] = useState(true)

    // Get portfolio effectmeta from API
    useEffect(() => {
        const getPortfolioEffect = async () => {
    
          const portfolioEffect = portfolioFunctions.httpsCallable('portfolioEffect');

          const data = {
              portfolioID: portfolioID,
              EffectID: effectID
          }
    
          portfolioEffect({ data: data })
          .then((result) => {
            setPortfolioEffect(result.data.title)
            setLoading(false)
          })
          .catch((error) => {
            // Handle errors
            console.error(error);
            alert(`Er is iets mis gegaan, neem contact op met Deccos`)
          });
        }
    
        getPortfolioEffect()
    }, [syncs])

  return (
    <>{loading ? <img src={spinner}/> : portfolioEffect}</>
  )
}

export default PortfolioEffects