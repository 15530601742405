const effects = () => {

    return(

    `Lijst met sociale effecten:
        FYSIEKE GEZONDHEID
        - Verbeterde lichamelijke activiteit
        - Gezondere voedingspatronen
        - Preventie van ziekte
        - Vermindering verslaving(en)
        - Gezonder lichaamsgewicht
        - Omgaan met chronische ziekte
        - Verbeterde slaapgezondheid 
        - Meer ontspanning
        - Verbeterde toegang tot gezondheidszorg
        - Verbeterde hygiëne
        - Verbeterde seksuele gezondheid
        - Verminderd werkverzuim
        
        MENTAAL WELZIJN
        - Stressvermindering- en preventie
        - Angstbeheersing- en preventie
        - Depressiebeheersing- en preventie
        - Verbeterd zelfbeeld
        - Verhoogd zelfvertrouwen
        - Verhoogde emotionele intelligentie
        - Verbeterde copingvaardigheden
        - Verbeterde toegang tot geestelijk ondersteuning en zorg
        - Toename in gevoel van zingeving
        - Verminderd werkverzuim

        SOCIALE GEZONDHEID
        - Bevordering sociale cohesie
        - Vergroten van sociale netwerk
        - Vermindering van sociale isolatie en eenzaamheid
        - Vermindering van sociale conflicten
        - Bevorderen van interculturele dialoog
        - Bevorderen van intergenerationele dialoog
        - Verbeterde sociale vaardigheden
        - Ondersteuning en preventie van sociale problemen

        PERSOONLIJKE ONTWIKKELING
        - Toename van kennis en vaardigheden
        - Verbeterde professionele vaardigheden
        - Verbeteren soft skills
        - Verbeterde creativiteit en artisticiteit 
        - Verhoogde zelfbewustzijn- en zelfreflectie
        - Verbeterde persoonlijke effectiviteit
        - Verbeterde leiderschapsvaardigheden
        - Verbeterde financiële geletterdheid en zelfredzaamheid
        - Verbeterde taalvaardigheden

        ZELFREDZAAMHEID
        - Verbeterde financiële zelfredzaamheid
        - Verbeterde sollicitatievaardigheden
        - Verbeterde zelfvoorziening in dagelijks leven
        - Verbeterde besluitvorming en probleemoplossing
        - Verbeterde zelfzorg
        - Verbeterde woonvaardigheden
        - Verhoogde mobiliteit
        - Verhoogde digitale geletterdheid
        - Verbeterde daginvulling 
        - Verbeterde dagstructuur
        - Verhoogde fysieke zelfredzaamheid
        - Verhoogde psychische zelfredzaamheid
        - Verhoogde cognitieve zelfredzaamheid
        - Verhoogde sociale steun
        - Uitbreiden van professioneel netwerk

        MAATSCHAPPELIJKE PARTICIPATIE
        - Toename van gemeenschapsgerichte initiatieven (zoals vrijwilligerswerk)
        - Verhoogde politieke participatie
        - Verhoogde inclusie
        - Verhoogd gemeenschapsgevoel
        - Verhoogde waardering van cultuur
        - Verhoogde internationale solidariteit
        - Verhoogde burgerlijke betrokkenheid
        - Verhoogde maatschappelijke verantwoordelijkheid

    `   
    )



}

export default effects