import {useFirestoreQuestionnairesResponses, useFirestoreGeneral, useFirestoreGeneralTwoOrderBy} from "../../../firebase/useFirestore";
import MultipleBarChart from '../../Visualisations/MultipleBarChart';
import Tooltip from "../../../components/common/Tooltip";
import searchIcon from '../../../images/icons/Search-icon.png'
import { client } from "../../../hooks/Client"
import { useHistory } from "react-router-dom";
import { useState, useContext } from 'react';
import { SavedIcon } from "../../../StateManagment/SavedIcon";
import penIcon from '../../../images/icons/pen-icon.png'
import { db } from '../../../firebase/config';
import MatrixBarChart from "../../Visualisations/MatrixBarChart";
import ScaleMomentScatterChart from "../../Visualisations/ScaleMomentScatterChart";
import ParagraphBarChart from '../../Visualisations/ParagraphBarChart'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import useSettings from "../../../hooks/Settings";

const Field = ({field, moment, researchID}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [edit, setEdit] = useState(false)

    // Hooks
    const history = useHistory();
    const primaryColor = useSettings().PrimaryColor

    // Firestore
    const results = useFirestoreGeneralTwoOrderBy('QuestionnairesResponses', 'FieldID', field.ID ? field.ID : '', 'MomentID', moment.ID ? moment.ID : '', 'Timestamp', 'asc')
    const multipleOptions = useFirestoreGeneral('MultipleQuestionOptions', 'Field', field.ID)

    // Find the average result
    const averageResult = () => {
        const total = results.length 

        const resultsArray = []
    
        results && results.forEach(result => {
            resultsArray.push(parseInt(result.Input))
        })
    
        const sum = resultsArray.length > 0 ? resultsArray.reduce((partialSum, a) => partialSum + a, 0) : 0

        const avarage = Math.round(sum/total * 10) / 10

        return avarage
    }

    // Edit field
    const editField = () => {
        setEdit(!edit)
    }

    // Change color
    const colorHandler = (e) => {
        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('MultipleQuestionOptions')
        .doc(docid)
        .update({
            Color: value
        })
        .then(() => {
            setSaved('flex')
        })
    }

    if(multipleOptions && multipleOptions?.length > 0){
        return(
            <td>
                 <div className='respons-count-container'>
                    <p>Aantal responses</p>
                    <p onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}>{results.length}</p>
                    <Tooltip content={'Beheer responses'} width='auto' top='-60px'>
                        <img src={searchIcon} alt="" onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}/>
                    </Tooltip>
                </div>
                <div style={{display: edit === false ? 'block' : 'none'}}>
                    <MultipleBarChart field={field.ID} moment={moment.ID}/>
                </div>
                <div style={{display: edit === false ? 'none' : 'block'}}>
                    {multipleOptions && multipleOptions.map((option) => (
                        <div className='edit-option-container' key={option.ID}>
                            <p>{option.Option}</p>
                            <input className="setting-color-picker" type="color" defaultValue={option.Color} data-docid={option.docid} onChange={colorHandler}/>
                        </div>
                    ))}
                </div>
                {/* <MultipleresultsPieChart field={field} moment={moment.ID}/> */}
                {/* <MultipleResults moment={moment} field={field} results={results} researchID={researchID}/> */}
                <div className='edit-paragraph-field-analysis-icon-container'>
                    <img src={penIcon} alt="" onClick={editField}/>
                </div>
            </td>
        )
    } else if(field.Type === 'paragraph'){
            return(
                <td>
                    <div className='category-container'>
                        <div className='respons-count-container'>
                            <p>Aantal responses</p>
                            <p onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}>{results.length}</p>
                            <Tooltip content={'Beheer responses'} width='auto' top='-60px'>
                                <img src={searchIcon} alt="" onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}/>
                            </Tooltip>
                        </div>
                        <div style={{display: edit === false ? 'block' : 'none'}}>
                            <ParagraphBarChart field={field.ID} moment={moment.ID}/>
                        </div>
                        <div style={{display: edit === false ? 'none' : 'block'}}>
                            <div className='link-to-categorisation-container'>
                                <Tooltip content={'Responses categoriseren'} width='1.5%' top='-60px'>
                                    <CategoryOutlinedIcon className='analyse-icon' onClick={() => history.push(`analysecategory/${moment.ID}/${field.ID}/${field.Question}`)}/>
                                </Tooltip>
                                <p onClick={() => history.push(`analysecategory/${moment.ID}/${field.ID}/${field.Question}`)}>Responses categoriseren</p>
                            </div>
                        </div>
                        {/* <div className='edit-paragraph-field-analysis-icon-container'>
                            <img src={penIcon} alt="" onClick={editField}/>
                        </div> */}
                    </div>
                </td>
            )
        } else if(field.Type === 'scale'){
            return(
                <td>
                    <div className="respons-container">
                        <div className='respons-count-container'>
                        <p onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}>{results.length}</p>
                            <Tooltip content={'Beheer responses'} width='auto' top='-60px'>
                                <img src={searchIcon} alt="" onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}/>
                            </Tooltip>
                        </div>
                        <div className="measuremoment-scale-average-container">
                            <ScaleMomentScatterChart field={field.ID} moment={moment.ID}/>
                            <h2>Gemiddelde score</h2>
                            <h1 style={{color: primaryColor}}>{ averageResult()}</h1>
                        </div>
                    </div>  
                </td> 
            )
        } else if(field.Type === 'matrix-multiple'){
            return(
                <td>
                 <div className='respons-count-container'>
                    <p>Aantal responses</p>
                    <p onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}>{results.length}</p>
                    <Tooltip content={'Beheer responses'} width='auto' top='-60px'>
                        <img src={searchIcon} alt="" onClick={() => {history.push(`/${client}/researchresponses/${field.ID}/${researchID}/${moment.ID}`)}}/>
                    </Tooltip>
                </div>
                <div style={{display: edit === false ? 'block' : 'none'}}>
                    <MatrixBarChart field={field.ID} moment={moment.ID}/>
                </div>
                <div style={{display: edit === false ? 'none' : 'block'}}>
                    {multipleOptions && multipleOptions.map((option) => (
                        <div className='edit-option-container' key={option.ID}>
                            <p>{option.Option}</p>
                            <input className="setting-color-picker" type="color" defaultValue={option.Color} data-docid={option.docid} onChange={colorHandler}/>
                        </div>
                    ))}
                </div>
                <div className='edit-paragraph-field-analysis-icon-container'>
                    <img src={penIcon} alt="" onClick={editField}/>
                </div>
            </td>
            )
        } else {
            return(
                <td>
                    <p>Onbekend type</p>
                </td>
            )
        }

    
}

export default Field