import { useFirestoreID } from '../../firebase/useFirestore'

const ResearchMeta = ({item}) => {

  const research = useFirestoreID('Research', item ? item : '')

  return (
    <>
        {research && research.map((item) => (
            <p>{item.Title}</p>
        ))}
    </>
  )
}

export default ResearchMeta