import questionMarkIcon from "../../images/icons/questionmark-icon.png"
import { useFirestoreID } from '../../firebase/useFirestore';
import Location from "../../hooks/Location"
import { db } from '../../firebase/config';
import QuestionnaireField from "../../components/Questionnaire/QuestionnaireField";

const IndicatorQuestionDetail = () => {

    // Hooks
    const questionId = Location()[3]

    // Firestore
    const questions = useFirestoreID('EffectQuestions', questionId ? questionId : '');

    // Update the question
    const questionHandler = (e) => {
        
        const docid = e.target.dataset.docid
        const question = e.target.value

        db.collection('EffectQuestions')
        .doc(docid)
        .update({
            Question: question
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <img src={questionMarkIcon}/>
                <h1>Vraag aanpassen</h1>
            </div>
            <div className="table-container dashboard-container">
                {questions && questions.map((question) => (
                <input type="text" key={question.ID} data-docid={question.docid} defaultValue={question.Question} onChange={questionHandler} />
                ))}
            </div>

            <QuestionnaireField 
             field={questionId}     
            />
        </div>
    </div>
  )
}

export default IndicatorQuestionDetail