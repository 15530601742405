import { useFirestoreGeneral } from '../../firebase/useFirestore'
import { db } from '../../firebase/config';

const UpdateOutputColors = ({activityID}) => {

    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityID ? activityID : '')

    const colorHandler = (e) => {

        const docid = e.target.dataset.docid 

        const color = e.target.value

        console.log(docid, color)

        db.collection('Outputs')
        .doc(docid)
        .update({
            Color: color
        })
    }


  return (
    <div id='update-output-color-container'>
        {outputs && outputs.map((output) => (
            <div key={output.ID}>
                <h3>{output.Title}</h3>
                <input type='color' data-docid={output.docid} defaultValue={output.Color} onChange={colorHandler}/>
            </div>
        ))}
    </div>
  )
}

export default UpdateOutputColors