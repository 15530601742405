import addresultsImage from '../../images/addresults.png'
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { AddresultsSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const AddResults = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = AddresultsSystemMessage()
  const pageStartMessage = `Welkom bij Resultaten toevoegen, ${auth?.ForName}. Hier kun je resultaten van activiteiten (outputs) toevoegen
  en onderzoeken uitzetten en analyseren.
  Heb je vragen over de het toevoegen van resultaten?`
  const pageExampleMessages = ['Wat zijn outputs?', 'Hoe verstuur ik een vragenlijst?']
  const pageType = 'addresults'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])


  return (
    <div>
      <div className='section-explainer-header-image-container'>
        <img src={addresultsImage} alt="" />
      </div>
      <div className='section-explainer-text-container'>
        <h1>Resultaten toevoegen</h1>
        <div>
          <p>Welkom bij de resultaten toevoegen, {auth?.ForName}. Hier ga je impactresultaten toevoegen aan de software, 
          zodat deze zichtbaar worden in het dashboard en kunnen worden gedeeld via de live-rapportages.</p>
        </div>

    <div id='add-results-start-container'>
      <div id='impact-guide-container'>
          <div className="home-start-image-container add-results-start-image-container">
            <BarChartOutlinedIcon/>
          </div>
          <div className="home-section-explainer-container">
            <h3>Output resultaten toevoegen</h3>
            <p>Voeg de laatste resultaten van je activiteiten (outputs) toe.</p>
          </div>
          <div className="home-cta-container" onClick={() => history.push(`/${client}/outputresults`)}>
            <p>Starten</p>
          </div>
        </div>

        <div id='impact-guide-container'>
          <div className="home-start-image-container add-results-start-image-container">
            <CheckBoxOutlinedIcon/>
          </div>
          <div className="home-section-explainer-container">
            <h3>Onderzoeksresultaten toevoegen</h3>
            <p>Zet onderzoeken uit en analyseer de resultaten.</p>
          </div>
          <div className="home-cta-container" onClick={() => history.push(`/${client}/researchresultsoverview`)}>
            <p>Starten</p>
          </div>
        </div>

    </div>
        

      </div>
      <Breadcrumb
      section={'Resultaten toevoegen'}
      previousPage={'Home'}
      previousPageUrl={'documentation'}
      nextPage={'Output resultaten'}
      nextPageUrl={'outputresults'}
      counter={0}
      totalSteps={2}
      />
    </div>
  )
}

export default AddResults