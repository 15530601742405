import { useFirestoreAllID } from "../../firebase/useFirestore"
import Location from "../../hooks/Location"
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { db } from "../../firebase/config"
import { useState, useContext, useEffect } from "react"
import { SavedIcon } from "../../StateManagment/SavedIcon";
import TinyMCE from "../../components/common/TinyMCE";
import spinner from '../../images/spinner-ripple.svg'
import SaveFile from "../../hooks/SaveFile";

const FundBuilder = () => {
     // Context
     const [saved, setSaved] = useContext(SavedIcon)

     // State
    const [selectedSectors, setSelectedSectors] = useState([])
    const [fundDocid, setFundDocid] = useState('')
    const [selectedRegion, setSelectedRegion] = useState([])
    const [selectedLegals, setSelectedLegals] = useState([])
    const [description, setDescription] = useState('')
    const [conditions, setConditions] = useState('')
    const [loading, setLoading] = useState(false)
    const [applicationProcess, setApplicationProcess] = useState('')
    const [selectedTargetgroups, setSelectedTargetgroups] = useState([])
    const [restrictions, setRestrictions] = useState('')
    const [context, setContext] = useState('')
    const [logo, setLogo] = useState('')

     // Hooks
    const fundId = Location()[3]

    // Firestore
    const funds = useFirestoreAllID('Funds', fundId ? fundId : '')

    // Data
    const sectorsArray = [
        { id: 'arbeidsparticipatie', name: 'Arbeidsparticipatie' },
        { id: 'armoedebestrijding', name: 'Armoedebestrijding' },
        { id: 'educatie', name: 'Educatie' },
        { id: 'gezondheid', name: 'Gezondheid' },
        { id: 'kerk- en levensbeschouwing', name: 'Kerk- en levensbeschouwing' },
        { id: 'klimaat, milieu en duurzaamheid', name: 'Klimaat, milieu en duurzaamheid' },
        { id: 'kunst en cultuur', name: 'Kunst en Cultuur' },
        { id: 'maatschappij en welzijn', name: 'Maatschappij en welzijn' },
        { id: 'mensenrechten en veiligheid', name: 'Mensenrechten en veiligheid' },
        { id: 'natuur(behoud) en dierenbelangen', name: 'Natuur(behoud) en dierenbelangen' },
        { id: 'Ondernemen en economie', name: 'Ondernemen en economie' },
        { id: 'sport', name: 'Sport' },
        { id: 'wetenschap, onderzoek en innovatie', name: 'Wetenschap, onderzoek en innovatie' },
    ]

    const regionsArray = [
        { id: 'landelijk', name: 'Landelijk' },
        { id: 'noord-holland', name: 'Noord-Holland' },
        { id: 'zuid-holland', name: 'Zuid-Holland' },
        { id: 'zeeland', name: 'Zeeland' },
        { id: 'noord-brabant', name: 'Noord-Brabant' },
        { id: 'limburg', name: 'Limburg' },
        { id: 'gelderland', name: 'Gelderland' },
        { id: 'utrecht', name: 'Utrecht' },
        { id: 'flevoland', name: 'Flevoland' },
        { id: 'overijssel', name: 'Overijssel' },
        { id: 'drenthe', name: 'Drenthe' },
        { id: 'groningen', name: 'Groningen' },
        { id: 'friesland', name: 'Friesland' },
    ];

    const targetgroupArray = [
        { id: 'Bewoners/inwoners', name: 'Bewoners/inwoners' },
        { id: 'Kinderen (leerlingen)', name: 'Kinderen (leerlingen)' },
        { id: 'Jongeren (leerlingen, studenten)', name: 'Jongeren (leerlingen, studenten)' },
        { id: 'Ouderen', name: 'Ouderen' },
        { id: 'Mensen met een afstand tot de arbeidsmarkt', name: 'Mensen met een afstand tot de arbeidsmarkt' },
        { id: 'Mensen met een beperking', name: 'Mensen met een beperking' },
        { id: 'Mensen met geestelijke gezondheidsproblemen', name: 'Mensen met geestelijke gezondheidsproblemen' },
        { id: 'Laaggeletterden', name: 'Laaggeletterden' },
        { id: 'Mensen in stressvolle omstandigheden (armoede, ziekte, dakloos)', name: 'Mensen in stressvolle omstandigheden (armoede, ziekte, dakloos)' },
        { id: 'Overige', name: 'Overige' },
    ]

    const legalArray = [
        { id: 'eenmanszaak', name: 'Eenmanszaak' },
        { id: 'BV', name: 'BV' },
        { id: 'NV', name: 'NV' },
        { id: 'VOF', name: 'VOF' },
        { id: 'maatschap', name: 'Maatschap' },
        { id: 'stichting', name: 'stichting' },
        { id: 'vereniging', name: 'Vereniging' },
        { id: 'cooperatie', name: 'Cooperatie' },
        { id: 'burgerinitiatieven', name: 'Burgerinitiatieven' },
    ]

    // Set db data to state
    useEffect(() => {
        if (funds) {
            setFundDocid(funds[0].docid)
            setSelectedSectors(funds[0].Sector)
            setSelectedRegion(funds[0].Region)
            setSelectedLegals(funds[0].Legal)
            setDescription(funds[0].Description)
            setConditions(funds[0].Conditions)
            setApplicationProcess(funds[0].ApplicationProcess)
            setSelectedTargetgroups(funds[0].Targetgroup)
            setRestrictions(funds[0].Restrictions)
            setContext(funds[0].Context)
        }
    }, [funds])

    // Handlers
    const nameHandler = (e) => {

        const docid = e.target.dataset.docid
        const name = e.target.value

        db.collection('Funds')
        .doc(docid)
        .update({
            Name: name
        })
        .then(() => {
            setSaved(true)
        })

    }

    const logoHandler = (e) => {
        SaveFile(e, setLogo)
    }

    const saveLogo = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Image: logo
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        })
    }

    const sectorHandler = (e) => {
        const value = e.target.value
        const checked = e.target.checked

        // If the checkbox was checked, add its value to the selectedSectors array; otherwise, remove it
        setSelectedSectors(prev => 
        checked ? [...prev, value] : prev.filter(sector => sector !== value)
        );
    }

    const saveSectors = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Sector: selectedSectors
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        })
    }

    const regionHandler = (e) => {

        const value = e.target.value
        const checked = e.target.checked

       // Check if "Landelijk" is the region being changed
       if (value === 'landelijk') {
        if (checked) {
            // Select all regions
            const allRegions = regionsArray.map(region => region.id);
            setSelectedRegion(allRegions);
        } else {
            // Deselect all regions
            setSelectedRegion([]);
        }
        } else {
            // For other regions, add or remove from selection based on checkbox state
            setSelectedRegion(prev => {

                if (!Array.isArray(prev)) {
                    console.error('Expected an array for prev, but received:', prev);
                    return []; // Return a default array to recover gracefully
                  }
    
                  console.log(prev);
    
                if (checked) {
                    return [...prev, value]; // Adds the value if checked
                  } else {
                    return prev.filter(region => region !== value && region !== 'landelijk'); // Removes the value if not checked
                  }
            });
        }
    }

    const saveRegions = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Region: selectedRegion
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        })
    }

    const legalHandler = (e) => {
        const value = e.target.value

        const checked = e.target.checked

        setSelectedLegals(prev => {
            // If the checkbox was checked, add its value to the selectedLegals array; otherwise, remove it
            if (!Array.isArray(prev)) {
                console.error('Expected an array for prev, but received:', prev);
                return []; // Return a default array to recover gracefully
            }

            console.log(prev);

            if (checked) {
                return [...prev, value]; // Adds the value if checked
            } else {
                return prev.filter(legal => legal !== value); // Removes the value if not checked
            }
        });
    }

    const saveLegals = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Legal: selectedLegals
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        })
    }

    const openCallHandler = (e) => {
        const openCall = e.target.options[e.target.selectedIndex].value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            OpenCall: openCall
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    const budgethandler = (e) => {

        const budget = e.target.value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Budget: budget
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    const contactPersonHandler = (e) => {

        const contactPerson = e.target.value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            ContactPerson: contactPerson
        })  
        .then(() => {
            setSaved(true)
        }
        )
    }

    const emailHandler = (e) => {

        const email = e.target.value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Email: email
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    const telephoneHandler = (e) => {
            
        const telephone = e.target.value

        console.log(telephone)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Phone: telephone
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    const websiteHandler = (e) => {
                
        const website = e.target.value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Website: website
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    const saveDescription = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Description: description
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        }
        )
    }

    const deadlineHandler = (e) => {

        const docid = e.target.dataset.docid
        const deadline = e.target.value

        db.collection('Funds')
        .doc(docid)
        .update({
            Deadline: deadline
        })
        .then(() => {
            setSaved(true)
        }
        )
    }


    const saveConditions = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Conditions: conditions
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        }
        )
    }

    const targetgroupHandler = (e) => {
                    
        const value = e.target.value
        const checked = e.target.checked

        // If the checkbox was checked, add its value to the selectedTargetgroups array; otherwise, remove it
        setSelectedTargetgroups(prev => {

            if (!Array.isArray(prev)) {
                console.error('Expected an array for prev, but received:', prev);
                return []; // Return a default array to recover gracefully
              }

              console.log(prev);

            if (checked) {
                return [...prev, value]; // Adds the value if checked
              } else {
                return prev.filter(targetgroup => targetgroup !== value); // Removes the value if not checked
              }
        });
    }

    const saveTargetgroups = () => {

        setLoading(true)

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Targetgroup: selectedTargetgroups
        })
        .then(() => {
            setSaved(true)
            setLoading(false)
        })
    }

    const durationHandler = (e) => {

        const duration = e.target.value

        db.collection('Funds')
        .doc(fundDocid)
        .update({
            Duration: duration
        })
        .then(() => {
            setSaved(true)
        }
        )
    }

    // Make the region label uppercase for 'Landelijk'
    const regionLabelFont = (region) => {

        if (region === 'Landelijk') {
            return region.toUpperCase()
        }
        return region
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <AccountBalanceOutlinedIcon/>
                <h1>Fonds instellingen</h1>
            </div> 
            {funds && funds.map((fund) => (
                <div key={fund.ID}>
                    <div className="dashboard-container">
                        <h2>
                            Naam
                        </h2>
                        <input id='fund-builder-name-input' type="text" data-docid={fund.docid} defaultValue={fund.Name} onChange={nameHandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Logo
                        </h2>
                        <img id='fundbuilder-fund-logo' src={fund.Image} alt="fund logo" />
                        <input type="file" onChange={logoHandler}/>
                        <div>
                            {loading ? 
                                <img src={spinner} alt="loading spinner" />
                            :
                                <button className="button-simple" onClick={saveLogo}>Opslaan</button>
                            }
                        </div>
                        
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Aandachtsgebied
                        </h2>
                        {sectorsArray.map((sector) => (
                            <div key={sector.id} className='fund-selector-item-container'>
                                <input
                                type="checkbox"
                                id={sector.id}
                                name="sectors"
                                data-docid={fund.docid}
                                value={sector.name}
                                checked={selectedSectors?.includes(sector.name)}
                                onChange={sectorHandler}
                                />
                                <label htmlFor={sector.id}>{sector.name}</label>
                            </div>
                        ))}
                        {loading ? 
                            <img src={spinner} alt="loading spinner" />
                        :
                            <button className="button-simple" onClick={saveSectors}>Opslaan</button>
                        }
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Regio
                        </h2>
                        <div>
                            {regionsArray.map(region => (
                                <div key={region.id} className='fund-selector-item-container'>
                                    <input 
                                        type="checkbox" 
                                        id={region.id} 
                                        name="region" 
                                        value={region.id} 
                                        checked={selectedRegion?.includes(region.id)}
                                        onChange={regionHandler} 
                                    />
                                    <label htmlFor={region.id}>{regionLabelFont(region.name)}</label>
                                </div>
                            ))}
                            {loading ? 
                                <img src={spinner} alt="loading spinner" />
                            :
                                <button className="button-simple" onClick={saveRegions}>Opslaan</button>
                            }
                        </div>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Doelgroep
                        </h2>
                        {targetgroupArray.map((targetgroup) => (
                            <div key={targetgroup.id} className='fund-selector-item-container'>
                                <input
                                type="checkbox"
                                id={targetgroup.id}
                                name="targetgroups"
                                data-docid={fund.docid}
                                value={targetgroup.name}
                                checked={selectedTargetgroups?.includes(targetgroup.name)}
                                onChange={targetgroupHandler}
                                />
                                <label htmlFor={targetgroup.id}>{targetgroup.name}</label>
                            </div>
                        ))}
                        {loading ? 
                            <img src={spinner} alt="loading spinner" />
                        :
                            <button className="button-simple" onClick={saveTargetgroups}>Opslaan</button>
                        }
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Geaccepteerde rechtsvormen
                        </h2>
                        {legalArray.map((legal) => (
                            <div key={legal.id} className='fund-selector-item-container'>
                                <input
                                type="checkbox"
                                id={legal.id}
                                name="targetgroups"
                                data-docid={fund.docid}
                                value={legal.name}
                                checked={selectedLegals?.includes(legal.name)}
                                onChange={legalHandler}
                                />
                                <label htmlFor={legal.id}>{legal.name}</label>
                            </div>
                        ))}
                        {loading ? 
                            <img src={spinner} alt="loading spinner" />
                        :
                            <button className="button-simple" onClick={saveLegals}>Opslaan</button>
                        }
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Beschrijving
                        </h2>
                        <TinyMCE body={description} setBody={setDescription}/>
                        {loading ? 
                            <img src={spinner} alt="loading spinner" />
                        :
                            <button className="button-simple" onClick={saveDescription}>Opslaan</button>
                        }
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Voorwaarden
                        </h2>
                        <TinyMCE body={conditions} setBody={setConditions}/>
                        {loading ? 
                            <img src={spinner} alt="loading spinner" />
                        :
                            <button className="button-simple" onClick={saveConditions}>Opslaan</button>
                        }
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Open inschrijving
                        </h2>
                        <select name="" id="" data-docid={fund.docid} defaultValue={fund.OpenCall} onChange={openCallHandler}>
                            <option value="Ja">Ja</option>
                            <option value="Nee">Nee</option>
                        </select>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Max aanvraagbedrag
                        </h2>
                        <input type="number" placeholder='Schrijf hier het budget' defaultValue={fund.Budget} data-docid={fund.docid} onChange={budgethandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Looptijd financiering
                        </h2>
                        <input type="date" data-docid={fund.docid} defaultValue={fund.Duration} onChange={durationHandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Contactpersoon
                        </h2>
                        <input type="text" placeholder='Contactpersoon'  data-docid={fund.docid} defaultValue={fund.ContactPerson} onChange={contactPersonHandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Email
                        </h2>
                        <input type="text" placeholder='Email'  data-docid={fund.docid} defaultValue={fund.Email} onChange={emailHandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Telefoon
                        </h2>
                        <input type="text"  data-docid={fund.docid} defaultValue={fund.Phone} onChange={telephoneHandler}/>
                    </div>
                    <div className="dashboard-container">
                        <h2>
                            Website
                        </h2>
                        <input type="text" placeholder='Website'  data-docid={fund.docid} defaultValue={fund.Website} onChange={websiteHandler}/>
                    </div>
                    
                </div>
            ))}
        </div>
    </div>
  )
}

export default FundBuilder