import ResearchResultsGraph from "../../common/ResearchResultsGraph";
import DevelopmentMulitlinegraph from "../../Visualisations/DevelopmentMulitlinegraph";
import MultipleMultiLineGraph from "../../Visualisations/MultipleMultiLineGraph";
import MultiMatrixDevelopment from "./MultiMatrixDevelopment";

const Development = ({field, researchID}) => {

   
    if(field.Type === 'paragraph'){
        return(
            <>
                <DevelopmentMulitlinegraph field={field.ID} researchID={researchID}/>
            </>   
        )
    } else if(field.Type === 'scale'){
        return(
            <>
                <ResearchResultsGraph field={field.ID} researchID={researchID}/>
            </>
        )
    } else if(field.Type === 'multiple-one' || field.Type === 'multiple-multiple'){
        return(
            <>
                <MultipleMultiLineGraph field={field.ID} researchID={researchID}/>
            </>
        )
    } else if(field.Type === 'matrix-multiple'){
        return(
            <>
                <MultiMatrixDevelopment field={field.ID} researchID={researchID}/>
            </>
        )
    }else {
        return(
            <div>
                <p>Onbekend type</p>
            </div>   
        )
    }
}

export default Development