import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';

const ParagraphBarChart = ({field, moment}) => {
  const [data, setData] = useState('')

    // Get wordcount for graphdata
  const getwords = async (item) => {

    let words = 0
    
    await db.collection('AnalysisWords')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field)
        .where('MomentID', '==', moment)
        .where('CategoryID', '==', item)
        .get()
        .then((querySnapshot) => {
            words = querySnapshot.size

        })

    return words
  }

// Get categories for graphdata
  const getCategories = async () => {

    const array = [];

    const querySnapshot = await db
        .collection('AnalysisCategories')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field && field)
        .get();

    const promises = querySnapshot.docs.map(async (doc) => {
        const object = {
        name: doc.data().Categorie,
        color: doc.data().Color,
        score: await getwords(doc.data().ID),
        };

        return object;
    });

    array.push(await Promise.all(promises))

    return array;
  }

  useEffect(() => {
      getCategories().then((data) => {
          setData(data)
      })
  },[])

  const getPath = (x, y, width, height) => {
    return `M${x},${y}
    L${x + width},${y}
    L${x + width},${y + height}
    L${x},${y + height}
    Z`;
  };
  
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <BarChart
      width={500}
      height={300}
      data={data.length > 0 ? data[0] : [{name: 'No data', uv: 0}]}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="score" shape={<TriangleBar />} label={{ position: 'top' }}>
        {data ? data[0].map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))
        : null}
      </Bar>
    </BarChart>
  );
}

export default ParagraphBarChart