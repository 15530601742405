import { createContext, useState } from "react";

export const Responses = createContext()

export const ResponsProvider = (props) => {
    const [respons, setRespons] = useState([])

    return(
        <Responses.Provider value={[respons, setRespons]}>
            {props.children}
        </Responses.Provider>
    )
}