import {  useFirestoreArrayContainsOrderBy } from "../../firebase/useFirestore";
import HomeMultipleBarChart from "../Visualisations/HomeMultipleBarChart";
import IndicatorData from "../Indicators/IndicatorData";
import { useState, useEffect } from "react";
import ArrowLeftIcon from "../../images/icons/arrow-left-icon.png";
import ArrowRightIcon from "../../images/icons/arrow-right-icon.png";
import Location from "../../hooks/Location"

const LiveReportEffectData = ({ effectId, startDate, endDate }) => {
    // State
    const [visible, setVisible] = useState(0);
    const [printPage, setPrintPage] = useState(false);
    const [openPage, setOpenPage] = useState(false);
  
    // Hooks
    const page = Location()[2];
  
    // Firestore
    const questionnaireFields = useFirestoreArrayContainsOrderBy(
      "QuestionnaireFields",
      "EffectId",
      effectId ? effectId : "123",
      "Position",
      "asc"
    );
    
    const visibleFields =
      questionnaireFields &&
      questionnaireFields.filter((item) => item.LiveReportHidden === false);
  
    // Set page type
    useEffect(() => {
      if (page === "printlivereport") {
        setPrintPage(true);
      } else if (page === "livereportopen") {
        setOpenPage(true);
      }
    }, [page]);
  
    // Navigation
    const nextIndicator = () => {
      if (visible < questionnaireFields.length - 1) {
        setVisible(visible + 1);
      } else {
        setVisible(0);
      }
    };
  
    const previousIndicator = () => {
      if (visible > 0) {
        setVisible(visible - 1);
      } else {
        setVisible(questionnaireFields.length - 1);
      }
    };
  
    return (
      <>
        <div
          id="live-report-effect-indicator-navbar"
          style={{ display: printPage ? "none" : "flex" }}
        >
          <div
            className="live-report-effect-indicator-navbar-button-container"
            onClick={previousIndicator}
          >
            <img src={ArrowLeftIcon} alt="Vorige" />
            <p className="live-report-effect-indicator-navbar-button">Vorige</p>
          </div>
  
          <div>
            {/* Check if page type is print page, open page or not */}
            {printPage ? (      
                <p>{visible + 1} van de {visibleFields.length}</p>
                ) : openPage ? (
                <p>{visible + 1} van de {visibleFields.length}</p>
                ) : (
                <p>{visible + 1} van de {questionnaireFields.length}</p>
                )
            }
          </div>
          <div
            className="live-report-effect-indicator-navbar-button-container"
            onClick={nextIndicator}
          >
            <p className="live-report-effect-indicator-navbar-button">Volgende</p>
            <img src={ArrowRightIcon} alt="Vorige" />
          </div>
        </div>
  
        {/* Check if page type is print page or not */}
        {printPage ? (
          <>
            {visibleFields.length > 0 ? (
              visibleFields.map((item, index) => (
                <div key={item.ID} className="home-kpi-container">
                  <IndicatorData
                    indicator={item}
                    startDate={startDate?.toDate()}
                    endDate={endDate?.toDate()}
                    docid={item.docid}
                  />
                </div>
              ))
            ) : (
              <HomeMultipleBarChart field={[{ ID: "123" }]} moment={"123"} />
            )}
          </>
        ) : openPage ? (
          // Show visibleFields when on livereportopen page
          <>
            {visibleFields.length > 0 ? (
              visibleFields.map((item, index) => (
                <div key={item.ID} className="home-kpi-container" style={{ display: index === visible ? "block" : "none" }}>
                  <IndicatorData
                    indicator={item}
                    startDate={startDate?.toDate()}
                    endDate={endDate?.toDate()}
                    docid={item.docid}
                  />
                </div>
              ))
            ) : (
              <HomeMultipleBarChart field={[{ ID: "123" }]} moment={"123"} />
            )}
          </>
        ) : (
          // Default behavior for non-print and non-open pages
          <>
            {questionnaireFields.length > 0 ? (
              questionnaireFields.map((item, index) => (
                <div
                  key={item.ID}
                  className="home-kpi-container"
                  style={{ display: index === visible ? "block" : "none" }}
                >
                  <IndicatorData
                    indicator={item}
                    startDate={startDate?.toDate()}
                    endDate={endDate?.toDate()}
                    docid={item.docid}
                  />
                </div>
              ))
            ) : (
              <HomeMultipleBarChart field={[{ ID: "123" }]} moment={"123"} />
            )}
          </>
        )}
      </>
    );
  };
  
  export default LiveReportEffectData;
  