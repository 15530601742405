import { useFirestoreID } from '../../firebase/useFirestore'

const MomentMeta = ({item}) => {

    const moments = useFirestoreID('MeasureMoments', item ? item : '')

    return (
      <>
          {moments && moments.map((item) => (
              <p>{item.Title}</p>
          ))}
      </>
    )
}

export default MomentMeta