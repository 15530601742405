import { useContext } from "react"
import { db } from "../../../firebase/config";
import { SavedIcon } from "../../../StateManagment/SavedIcon";
import Tooltip from "../../common/Tooltip";

const CategoryAnalysisTitle = ({item}) => {
    const [saved, setSaved] = useContext(SavedIcon)


    const categorieHandler = (e) => {
        
        const value = e.target.value

        const docid = e.target.dataset.docid
        
        db.collection('AnalysisCategories')
        .doc(docid)
        .update({
            Categorie: value
        })
        .then(() => {
            setSaved('flex')
        })
       
    }

  return (
    <>
        <Tooltip content={item.Categorie} top='-60px'>
            <input type="text" data-docid={item.docid} defaultValue={item.Categorie} onChange={categorieHandler} />
        </Tooltip>
    </>
  )
}

export default CategoryAnalysisTitle