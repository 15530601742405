import { useFirestoreGeneral, useFirestoreGeneralOrderBy, useFirestoreOrderBy } from "../../firebase/useFirestore"
import ScrollToTop from "../../hooks/ScrollToTop";
import ResultMeta from "../../components/projectmanagement/ResultMeta";
import { client } from "../../hooks/Client"
import { db } from "../../firebase/config";
import uuid from 'react-uuid';
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { OutputResultsSystemMessage } from "../../hooks/impactAI/Prompts"
import Tooltip from "../../components/common/Tooltip";
import plusButton from '../../images/icons/plus-icon.png'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import PageHeader from "../../components/layout/PageHeader.jsx";
import NextStepContainer from "../../components/common/NextStepContainer";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";

const OutputResults = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
    } = useContext(ImpactAI);

    // State
    const [activityID, setActivityID] = useState('123')

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
    const results = useFirestoreGeneralOrderBy('Results', 'ActivityID', activityID ? activityID : '123','Timestamp', 'asc')
    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityID ? activityID : '123')

    //ImpactAI
    const pageSystemMessage = OutputResultsSystemMessage()
    const pageStartMessage = `Welkom bij de outputresultaten, ${auth?.ForName}. Hier kun je resultaten van activiteiten (outputs) toevoegen.
    Heb je vragen over de outputresultaten?`
    const pageExampleMessages = ['Hoe kan ik resultaten verwijderen?', 'Hoe kan ik resultaten aanpassen?']
    const pageType = 'outputresults'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Select activity
    const selectActivity = (e) => {
        const activityID = e.target.dataset.id

        setActivityID(activityID)
    }

    // Set default activity
    useEffect(() => {
        activities.length > 0 ? setActivityID(activities[0].ID) : setActivityID(null)
    }, [activities])


    // Update output result
    const resultHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        db.collection('Results')
        .doc(docid)
        .update({
            Result: Number(value)
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Save date
    const saveDate = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        const date = new Date(value)

        const randomNumber = Math.floor(Math.random() * 1000) + 1;

        date.setSeconds(date.getSeconds() + randomNumber);

        db.collection('Results')
        .doc(docid)
        .update({
            Timestamp: date
        })
        .then(() => {
            setSaved('flex')
        })

    }


    // Add output result
    const addOutputResult = () => {

        const date = new Date()

        const randomNumber = Math.floor(Math.random() * 1000) + 1;

        date.setSeconds(date.getSeconds() + randomNumber);

        db.collection('Results')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Result: Number(0),
            Timestamp: date,
            OutputID: '',
            ActivityID: activityID
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Update selected output
    const selectedOutputHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Results')
        .doc(docid)
        .update({
            OutputID: value
        })
        .then(() => {
            setSaved('flex')
        })


    }


     // Delete result
     const deleteResult = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Results')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })


    }

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Outputresultaten'}
                description={`Voeg hier de resultaten van de activiteiten toe. Klik op de activiteit om de resultaten toe te voegen en te bekijken.`}
                AI={true}
            />
            <div className='table-container add-results-container'>
                <div className="select-activity-container select-activity-container-wizard">
                    <div className="select-activity-inner-container">
                    {activities && activities.map(item => (
                            <div key={item.ID} data-id={item.ID} data-title={item.Activity} className='activity-select-item-container' onClick={selectActivity} style={{backgroundColor: activityID === item.ID ? secundairyColor : 'white'}}>
                                <p data-id={item.ID} data-title={item.Activity} onClick={selectActivity} style={{color:  activityID === item.ID ? 'white' : '#616161'}}>{item.Activity}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='table-container dashboard-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                            <th style={{backgroundColor: secundairyColor}}>RESULTAAT</th>
                            <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                        {results && results.map(item => (
                        <tr key={item.ID}>
                            <td>
                                {item.OutputID === '' ? 
                                
                                <select data-docid={item.docid} onChange={selectedOutputHandler}>
                                    <option value="">Selecteer output</option>
                                    {outputs && outputs.map(output => (
                                        <option value={output.ID}>{output.Title}</option>
                                    ))}
                                </select>
                                :
                                <ResultMeta output={item.OutputID}/>
                                }
                                
                            </td>
                            <td>
                                <input id='add-result-input' type="number" data-docid={item.docid} defaultValue={item.Result} onChange={resultHandler} />
                            </td>
                            <td>
                                <input type="date" defaultValue={item.Timestamp.toDate().toISOString().split('T')[0]} data-docid={item.docid} onBlur={saveDate} />
                            </td>
                            <td>
                                <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteResult}/>
                            </td>
                        </tr>
                        ))}
                    </table>
                    <AddItemTableRow toolTipContent={'Outputresultaat toevoegen'} onClick={addOutputResult}/>
                </div>
            </div>
            <NextStepContainer url={'communication'} mainItem={'communication'}/>
            <Breadcrumb
            section={'Meten'}
            previousPage={'KPI"s'}
            previousPageUrl={'kpis'}
            nextPage={'Communiceren'}
            nextPageUrl={'communication'}
            counter={4}
            totalSteps={4}
            />
        </div>
    </div>
  )
}

export default OutputResults