import { useEffect } from 'react';

const useDelayedOpenState = (chatOpen, setDelayedOpen) => {

     // useEffect to handle the delayed open state
   useEffect(() => {
        if (chatOpen) {
            const timeout = setTimeout(() => {
                setDelayedOpen(true); // Set delayed open to true after 1/4 second
            }, 250); // 1/4 second delay

            return () => clearTimeout(timeout); // Cleanup timeout on unmount or open state change
        } else {
            setDelayedOpen(false); // Set delayed open to false immediately when closing
        }
    }, [chatOpen]);
}

export default useDelayedOpenState
