import React from 'react'
import editImage from '../../images/icons/edit-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from "../../firebase/config.js"
import ResearchMeta from "../../components/Research/ResearchMeta";
import FieldMeta from "../../components/Questionnaire/FieldMeta";
import MomentMeta from "../../components/Research/MomentMeta";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import useSettings from "../../hooks/Settings";
import { useContext } from "react";
import { useFirestoreOrderBy } from "../../firebase/useFirestore";
import OutputMeta from '../outputs/OutputMeta';
import EffectMeta from '../effects/EffectMeta';

const EffectProof = ({effectID}) => {
     // Context
     const [saved, setSaved] = useContext(SavedIcon)

     // Hooks
     const secundairyColor = useSettings().SecundairyColor

     // Firestore
     const proofs = useFirestoreOrderBy('KpiData', 'Position', 'asc' )

    // Delete proof
    const deleteProof = (e) => {
        e.preventDefault()

        const id = e.target.dataset.docid

        db.collection("KpiData")
        .doc(id)
        .delete()
        .then(() => {
            setSaved('flex')
        })
    }

    // Type translation
    const type = (type) => {
        if (type === 'output') {
            return 'Output'
        } else {
            return 'Onderzoek'
        }
    }

  return (
        <div className='kpi-proof'>
            <div className='table-container'>
                <h2>Onderzoeksvragen</h2>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>EFFECT</th>
                            <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                            <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>
                    {proofs && proofs.map(item => (
                        <tr key={item.ID}>
                            <td>
                                <EffectMeta item={item.KpiID} />
                            </td>
                            <td>
                                <p>{type(item.Type)}</p>
                                
                            </td>
                            <td>
                                {item.Type === 'output' 
                                ? 
                                <div>
                                    <p><b>Output</b></p> 
                                    <p>Aantal <OutputMeta id={item.Output} /></p>
                                </div>
                                :
                                <div>
                                    <p><b>Onderzoek</b></p>
                                    <ResearchMeta item={item.ResearchID} />
                                    <p><b>Vraag</b></p>
                                    <FieldMeta item={item.Field} />
                                    <p><b>Meetmoment</b></p>
                                    {item.Moment === 'development' ? <p>Ontwikkeling</p> : <MomentMeta item={item.Moment} />}
                                </div>
                                }
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid}  src={deleteIcon} alt="" onClick={deleteProof} />
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>  
    )
}

export default EffectProof