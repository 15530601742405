

const ButtonClicked = (e, text) => {

       e.target.innerText = text 
       e.target.style.borderColor = '#D3D3D3'
       e.target.style.color = 'white'
       e.target.style.backgroundColor = '#D3D3D3'
}

export default ButtonClicked
