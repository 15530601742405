import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Location from "../../hooks/Location"
import { useFirestoreGeneral, useFirestore } from '../../firebase/useFirestore';
import PortfolioMeta from '../../components/syncronisations/PortfolioMeta';
import PortfolioOutputs from '../../components/syncronisations/PortfolioOutputs';
import PortfolioEffects from '../../components/syncronisations/PortfolioEffects';
import { portfolioFunctions } from "../../firebase/configPortfolio";
import { db } from '../../firebase/config';
import OutputMeta from '../../components/outputs/OutputMeta';
import EffectMeta from '../../components/effects/EffectMeta';
import spinner from "../../images/spinner-ripple.svg";
import { useState } from "react";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SpokeOutlinedIcon from '@mui/icons-material/SpokeOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';

const SynchronisationBuilder = () => {
    // State
    const [loading, setLoading] = useState(false)

    // Hooks
    const syncID = Location()[3]

    // Firestore
    const syncs = useFirestoreGeneral('Synchronisations', 'ID', syncID ? syncID : '123')
    const outputs = useFirestore('Outputs')
    const effects = useFirestore('OutputEffects')

    // adjust the name and color of the status
    const status = (statusCode) => {
        switch (statusCode) {
          case 'requested':
            return { text: 'Aangevraagd', color: '#FFA500' }; // Orange
          case 'accepted':
            return { text: 'Geaccepteerd', color: '#008000' }; // Green
          case 'declined':
            return { text: 'Geweigerd', color: '#FF0000' }; // Red
        case 'deleted':
            return { text: 'Verwijderd', color: '#000000' }; // Red
          default:
            return { text: 'Onbekende status', color: '#000000' }; // Black
        }
      }

      // adjust the name and color of the status
    const syncStatus = (statusCode) => {
        switch (statusCode) {
          case 'requested':
            return { text: 'Inactief', color: '#FFA500' }; // Orange
          case 'accepted':
            return { text: 'Actief', color: '#008000' }; // Green
          case 'declined':
            return { text: 'Inactief', color: '#FF0000' }; // Red
        case 'deleted':
            return { text: 'Inactief', color: '#FF0000' }; // Red
          default:
            return { text: 'Onbekende status', color: '#000000' }; // Black
        }
      }

    // Adjust the name of the type
    const type = (type) => {
        switch (type) {
            case 'output':
            return 'Output';
            case 'effect':
            return 'Effect';
            default:
            return 'Onbekend';
        }
    }

    // Function to update portfolio database
    const updatePortfolioStatus = async (e) => {

        setLoading(true)

        const syncId = e.target.dataset.syncid
        const status = e.target.dataset.status

        const updateSyncStatus = portfolioFunctions.httpsCallable('updateSyncStatus');

        const data = {
            syncId: syncId,
            status: status
        }

        updateSyncStatus({ data: data })
        .then((result) => {
            console.log(result.data)
            // If the function returns a success update the project database 
            if(result.data === 'Success') {
                updateProject(e, status)
                setLoading(false)
            }
            
        })
        .catch((error) => {
            // Handle errors
            console.error(error);
            alert(`Er is iets mis gegaan, neem contact op met Deccos`)
        });
    }

    // Function to update project database
    const updateProject = async (e, status) => {
        const docid = e.target.dataset.docid

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            Status: status
        })
    }

    const acceptSyncHandler = (e) => {
        updatePortfolioStatus(e)
    }
    

    const declineSyncHandler = (e) => {
        updatePortfolioStatus(e)
    }

    // Function to update project database with selected project output
    const updateProjectOutputProjectDatabase = async (outputID, docid) => {

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            ProjectOutput: outputID
        })
    }


    // Function to update portfolio database with selected project output
    const updatePortfolioProjectOutput = async (syncID, outputID, docid) => {

        const updateProjectOutput = portfolioFunctions.httpsCallable('updateProjectOutput');

        const data = {
            syncId: syncID,
            outputId: outputID
        }

        updateProjectOutput({ data: data })
        .then((result) => {
            console.log(result.data)
            // If the function returns a success update the project database with the selected project output
            if(result.data === 'Success') {
                updateProjectOutputProjectDatabase(outputID, docid)
            }
            
        })
        .catch((error) => {
            // Handle errors
            console.error(error);
            alert(`Er is iets mis gegaan, neem contact op met Deccos`)
        });
    }

    // Function to handle project output selection
    const projectOutputHandler = (e) => {

        const outputID = e.target.options[e.target.selectedIndex].value 
        const syncID = e.target.dataset.syncid
        const docid = e.target.dataset.docid

        updatePortfolioProjectOutput(syncID, outputID, docid)
    }

    // Function to add effect to project
    const addEffectToProject = async (e) => {
        const effectId = e.target.dataset.id 

        console.log(effectId)

    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <SyncOutlinedIcon />
                <h1>Synchronisatie</h1>
            </div>
            <div className='dashboard-container'>
                {syncs && syncs.map((sync) => (
                    <div key={sync.ID} id='sync-detail-container'>

                        {/* Portfolio organisation name */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <ApartmentOutlinedIcon />
                                <p><b>Portfolio-organisatie</b></p>
                            </div>
                            <p className='sync-content'><PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs}/></p>
                        </div>
                        
                        {/* Type of sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <SpokeOutlinedIcon />   
                                <p><b>Type</b></p>
                            </div>
                            <p className='sync-content'>{type(sync.Type)}</p>
                        </div>

                        {/* Name of output to sync  */}
                        {sync.Type === 'output' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <OutputOutlinedIcon/>
                                    <p><b>Portfolio output</b></p>
                                </div>
                                <p className='sync-content'><PortfolioOutputs portfolioID={sync.PortfolioID} outputID={sync.SyncItem} syncs={syncs}/></p>
                            </div>
                        }

                        {/* Name of effect to sync  */}
                        {sync.Type === 'effect' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <OutputOutlinedIcon/>
                                    <p><b>Portfolio effect</b></p>
                                </div>
                                <p className='sync-content'><PortfolioEffects portfolioID={sync.PortfolioID} effectID={sync.SyncItem} syncs={syncs}/></p>
                            </div>
                        }

                        {/* Status of sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <MoreHorizOutlinedIcon />
                                <p><b>Status</b></p>
                            </div>
                            <p className='sync-content' style={{color: status(sync.Status).color}}>{status(sync.Status).text}</p>
                        </div>  

                        {/* Accept or decline sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <ApartmentOutlinedIcon />
                                <p><b>Accepteren of weigeren</b></p>
                            </div>
                            {sync.Status === 'requested' && 
                                <>
                                    {loading ? <img src={spinner} alt='loading' className='spinner' /> :
                                        <div className='sync-content'>
                                            <button 
                                            className='button-simple' 
                                            data-docid={sync.docid} 
                                            data-status={'accepted'} 
                                            data-syncid={sync.ID} 
                                            onClick={acceptSyncHandler}>
                                                Accepteren
                                            </button>
                                            <button 
                                            className='button-simple' 
                                            id='decline-sync-button' 
                                            data-docid={sync.docid} 
                                            data-status={'declined'} 
                                            data-syncid={sync.ID} 
                                            onClick={declineSyncHandler}>
                                                Weigeren
                                            </button>
                                        </div>
                                    }
                                </>
                                
                            }
                            {sync.Status === 'accepted' &&
                                <p className='sync-content'>De synchronisatie is geaccepteerd</p>
                            }
                            {sync.Status === 'declined' &&
                                <p className='sync-content'>De synchronisatie is geweigerd</p>
                            }
                            {sync.Status === 'deleted' &&
                                <p className='sync-content'>De synchronisatie is verwijderd</p>
                            }
                        </div>

                        {/* Accepted sync type is output */}
                        {sync.Status === 'accepted' || sync.Type === 'output' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld output</b></p>
                                </div>
                                <div>
                                    {sync.ProjectOutput ?
                                        <p className='sync-content'><OutputMeta id={sync.ProjectOutput ? sync.ProjectOutput : '123'}/></p>
                                    :
                                        <select data-syncid={sync.ID} data-docid={sync.docid} onChange={projectOutputHandler}>
                                            <option value=''>-- Selecteer een output --</option>
                                            {outputs && outputs.map((output) => (
                                                <option key={output.ID} value={output.ID}>{output.Title}</option>
                                            ))}
                                        </select>
                                    }
                                </div>
                            </div>
                        }

                        {/* Accepted sync type is effect */}
                        {sync.Status === 'accepted' && sync.Type === 'effect' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld effect</b></p>
                                </div>
                                <div>
                                    {sync.ProjectEffect ?
                                        <p className='sync-content'>Effect is toegevoegd aan impactstrategie</p>
                                    :
                                        <button className='button-simple' data-id={sync.SyncItem} onClick={addEffectToProject}>Effect toevoegen aan impactstrategie</button>
                                    }
                                </div>
                            </div>
                        }

   
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <SyncOutlinedIcon />
                                <p><b>Synchronisatie</b></p>
                            </div>
                            <div>
                                <p className='sync-content' >{syncStatus(sync.Status).text}</p>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default SynchronisationBuilder