import { createContext, useState } from "react";

export const PersonaNavigation = createContext()

export const PersonaNavigationProvider = (props) => {
    const [personaNavigation, setPersonaNavigation] = useState("dashboard")

    return(
        <PersonaNavigation.Provider value={[personaNavigation, setPersonaNavigation]}>
            {props.children}
        </PersonaNavigation.Provider>
    )
}