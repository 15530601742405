import Modal from 'react-modal';
import deleteIcon from '../../images/icons/delete-icon.png'

const DeleteModal = ({deleteModal, setDeleteModal, deleteItem, deleteName}) => {
  
  Modal.setAppElement('#root');

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const deleteHandler = () => {
    deleteItem()

    setDeleteModal(false)

  }

  return (
    <Modal
      isOpen={deleteModal}
      onRequestClose={deleteModal}
      style={modalStyles}
      contentLabel="Definitief verwijderen"
      >
        <div id='delete-modal-container'>
          <div id='delete-modal-title-container'>
            <img src={deleteIcon} alt="" />
            <h1>Verwijderen</h1>
          </div>
          <h2>Weet je zeker dat je <i>{deleteName}</i> definitief wilt verwijderen?</h2>
          <div id='delete-modal-button-container'>
            <button onClick={() => setDeleteModal(false)}>Annuleren</button>
            <button id='delete-modal-delete-button' onClick={deleteHandler} >Verwijderen</button>
          </div>
        </div>
    </Modal>
  )
}

export default DeleteModal