import ScrollToTop from "../../hooks/ScrollToTop";
import Hostname from "../../hooks/Hostname";
import { HomeSystemMessage } from "../../hooks/impactAI/Prompts"
import { useContext, useEffect } from "react";
import { Auth } from '../../StateManagment/Auth';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import strategyImage from '../../images/impactstrategy.png'
import measureplanImage from '../../images/measureplan.png'
import communicateImage from '../../images/communicate.png'
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import { db } from "../../firebase/config";
import { Onboarding } from "../../StateManagment/Onboarding";
import OnboardingModal from '../../components/common/OnboardingModal';

const Home = () => {
  // Context
  const [auth] = useContext(Auth)
  const { onboarding, setOnboarding, step, setStep, onboardingNotice, setOnboardingNotice } = useContext(Onboarding);
  const {
    systemMessage: [systemMessage, setSystemMessage],
    startMessage: [startMessage, setStartMessage],
    exampleMessages: [exampleMessages, setExampleMessages],
    chatOpen: [chatOpen, setChatOpen],
    type: [type, setType],
    hightlighted: [hightlighted, setHightlighted],
    hightlightText: [hightlightText, setHightlightText],
} = useContext(ImpactAI);

  // Hooks
  ScrollToTop()
  const host = Hostname()
  const history = useHistory()

  // ImpactAI
  const pageSystemMessage = HomeSystemMessage()
  const pageStartMessage = `Hallo ${auth?.ForName}, welkom bij ${host?.Name}. Ik ben Impact AI, jouw impact consultant. Ik sta altijd klaar 
  om je te helpen met de impactmanagementsoftware. 
  Ik ben hier om ervoor te zorgen dat je alles uit de software haalt wat erin zit. 
  Als je vragen hebt over hoe je iets moet doen, waar je iets kunt vinden of als je gewoon wat hulp nodig hebt, 
  ben ik er voor je. Waar kan ik je mee helpen? 😊`
  const pageExampleMessages = ['Ik ben nieuw. Waar begin ik?']

  useEffect(() => {
    setSystemMessage(pageSystemMessage)
    setStartMessage(pageStartMessage)
    setExampleMessages(pageExampleMessages)
    setType('welcome')
    setChatOpen(false)
}, [auth, host])

// Reset hightlights
useEffect(() => {
  setHightlighted(false)
  setHightlightText('')
},[])

// Onboarding
useEffect(() => {
  if (auth?.Logins === 1) {
    setOnboardingNotice(true)
  }
}, [auth])

 const onboardingTitle = 'Dit is de eerste stap van je impactmanagementproces'
 const onboardingText = 'Klik hier als je wilt starten met het proces'

 // Function to update number of logins (for onboarding)
  const updateLogins = async () => {

    db.collection('Users').doc(auth?.Docid)
    .update({
      Logins: auth?.Logins + 1
    })
  }

// Onboarding accept
const acceptOnboardingHandler = () => {
  setOnboarding(true)
  setOnboardingNotice(false)
  setStep(1)
  updateLogins()
}

// Onboarding decline
const onboardingDeclineHandler = () => {
  setOnboarding(false)
  setStep(1)
  setOnboardingNotice(false)
  updateLogins()
}

  return (
    <div className="main">
    <div className="main-container">
        <div className="page-header">
          <h1>Welkom bij {host?.Name}, {auth?.ForName}</h1>
        </div>

        {/* {onboardingNotice &&
          <div id='home-onboarding-container'>
            <EmojiPeopleOutlinedIcon/>
            <h2>Hoi {auth?.ForName}, dit is je eerste bezoek bij {host?.Name}.</h2>
            <p>Heb je behoefte aan een korte rondleiding?</p>
            <div id='onboaring-modal-button-container'>
              <button id='onboarding-modal-close-button' onClick={onboardingDeclineHandler}>Nee, bedankt.</button>
              <button onClick={acceptOnboardingHandler}>Ja, graag!</button>
            </div>
          </div>
        } */}

        <div className="home-quick-start-container">

          <div className="home-element-title-container">
            <h2>Essentieel</h2>
          </div>

          <div className='impact-guide-container' id='home-startegy-container'>
            <div className="home-start-image-container">
              <img src={strategyImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Impactstrategie</h1>
              <p>Je impactstrategie is de basis van je impactmanagement proces.</p>
            </div>
            <div className="home-cta-container" id='home-strategy-cta-container' onClick={() => history.push(`/${client}/impactstrategy`)}>
                <p>Starten</p>
              </div>
          </div>

          <div className="home-element-title-container">
            <h2>Modules</h2>
          </div>

          <div className='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={measureplanImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Meten</h1>
              <p>Meet je impact anoniem of op individueel niveau.</p>
            </div>
            <div className="home-cta-container" onClick={() => history.push(`/${client}/measurementplan`)}>
                <p>Starten</p>
              </div>
          </div>
{/* 
          <div className='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={resultImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Resultaten toevoegen</h1>
              <p>Voeg impact resultaten toe van je activitieten en onderzoeken</p>
            </div>
            <div className="home-cta-container" onClick={() => history.push(`/${client}/addresults`)}>
                <p>Starten</p>
              </div>
          </div> */}

          <div className='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={communicateImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Communiceren</h1>
              <p>Communiceer je impact aan je stakeholders aan de hand van op maat gemaakte live-rapportages.</p>
            </div>
            <div className="home-cta-container" onClick={() => history.push(`/${client}/communication`)}>
              <p>Starten</p>
            </div>
          </div>

          {/* <div id='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={personasImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Personas</h1>
              <p>Meet impact op individueel niveau.</p>
            </div>
            <div className="home-cta-container" onClick={() => history.push(`/${client}/personasexplainer`)}>
              <p>Starten</p>
            </div>
          </div> */}

        </div>
      </div>
      {/* {step === 5 &&
        <OnboardingModal 
          selector='#home-strategy-cta-container'
          title={onboardingTitle}
          text={onboardingText}
          margin={'-50px 0px 0px 0px'}
        />
      } */}
    </div>
  )
}

export default Home