import { useFirestoreID } from "../../firebase/useFirestore";

const TargetgroupMeta = ({targetgroupID}) => {

    const targetgroup = useFirestoreID('Stakeholders', targetgroupID ? targetgroupID : '')

  return (
    <p>{targetgroup[0]?.Organisation}</p>
  )
}

export default TargetgroupMeta