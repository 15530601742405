const targetgroupCategories =  [
        'Kinderen',
        'Jongeren',
        'Ouderen', 
        'Mensen met een afstand tot de arbeidsmarkt',
        'Mensen met een migratieachtergrond',
        'Mensen in armoede',
        'Mensen met een beperking',
        'Daklozen',
        'Mensen met geestelijke gezondheidsproblematiek',
        'Organisaties en overheden',
        'Overig'
    ]

export default targetgroupCategories