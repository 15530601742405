import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { useFirestore } from '../../firebase/useFirestore';
import PortfolioMeta from '../../components/syncronisations/PortfolioMeta';
import PortfolioOutputs from '../../components/syncronisations/PortfolioOutputs';
import PortfolioEffects from '../../components/syncronisations/PortfolioEffects';
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"

const Synchronisations = () => {

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()

    // Firestore
    const syncs = useFirestore('Synchronisations')

    // adjust the name and color of the status
    const status = (statusCode) => {
      switch (statusCode) {
        case 'requested':
          return { text: 'Verzoek', color: '#FFA500' }; // Orange
        case 'accepted':
          return { text: 'Geaccepteerd', color: '#008000' }; // Green
        case 'declined':
          return { text: 'Geweigerd', color: '#FF0000' }; // Red
        case 'deleted':
          return { text: 'Verwijderd', color: '#000000' }; // Red
        default:
          return { text: 'Onbekende status', color: '#000000' }; // Black
      }
    }

    // Data sync status
    const syncStatus = (statusCode) => {
      switch (statusCode) {
        case 'requested':
          return { text: 'Inactief', color: '#FFA500' }; // Orange
        case 'accepted':
          return { text: 'Actief', color: '#008000' }; // Green
        case 'declined':
          return { text: 'Inactief', color: '#FF0000' }; // Red
        case 'deleted':
          return { text: 'Inactief', color: '#FF0000' }; // Black
        default:
          return { text: 'Onbekende status', color: '#000000' }; // Black
      }
    }
  
    // Adjust the name of the type
    const type = (type) => {
      switch (type) {
        case 'output':
          return 'Output';
        case 'effect':
          return 'Effect';
        default:
          return 'Onbekend';
      }
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <SyncOutlinedIcon />
                <h1>Synchronisations</h1>
            </div>
            <div className='dashboard-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>ORGANIATIE</th>
                        <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                        <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                        <th style={{backgroundColor: secundairyColor}}>STATUS</th>
                        <th style={{backgroundColor: secundairyColor}}>SYNCHRONISATIE</th>
                        <th style={{backgroundColor: secundairyColor}}>INSTELLINGEN</th>
                    </tr>
                    {syncs && syncs.map((sync) => (
                        <tr key={sync.ID}>
                            <td>
                                <p><PortfolioMeta portfolioID={sync.PortfolioID} syncs={syncs}/></p>
                            </td>
                            <td>
                                <p>{type(sync.Type)}</p>
                            </td>
                            <td>
                                {sync.Type === 'effect' && <p><PortfolioEffects portfolioID={sync.PortfolioID} effectID={sync.SyncItem} syncs={syncs}/></p>}
                                {sync.Type === 'output' && <p><PortfolioOutputs portfolioID={sync.PortfolioID} outputID={sync.SyncItem} syncs={syncs}/></p>}
                            </td>
                            <td>
                                <p style={{color: status(sync.Status).color}}>{status(sync.Status).text}</p>
                            </td>
                            <td>
                                <p >{syncStatus(sync.Status).text}</p>
                            </td>
                            <td>
                                <img className='table-delete-icon' onClick={() => history.push(`/${client}/synchronisationbuilder/${sync.ID}`)} src={settingsIcon} alt="" />
                            </td>
                        </tr> 
                    ))}
                </table>
            </div>
        </div>
    </div>
  )
}

export default Synchronisations