import { useState, useRef, useEffect } from 'react';
import { throttle } from 'lodash';
import { db } from "../../firebase/config";
import dragIcon from '../../images/icons/drag-icon.png';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import deleteIcon from '../../images/icons/delete-icon.png';
import Tooltip from './Tooltip';

const DraggableItems = ({
    children,
    item,
    input,
    parentIndex,
    collection,
    itemArray,
    setHoverIndex,
    hoverIndex,
    setPlaceholderIndex,
    type,
    sectionIdRef,
    sectionRef,
    sectionDocid
}) => {
    const [docid, setDocid] = useState(null);
    const [dragIndex, setDragIndex] = useState(null);

    const throttledDragOverRef = useRef();

    useEffect(() => {
        throttledDragOverRef.current = throttle((parentRect, offsetY, currentIndex) => {
            dragoverLogic(parentRect, offsetY, currentIndex);
        }, 500); // Adjust the throttle interval as needed
    }, []);

    const dragstart = (e) => {
        e.stopPropagation();
        const docid = e.target.dataset.docid;
        const index = e.target.dataset.index;

        const numberIndex = parseInt(index);
        setDocid(docid);
        setDragIndex(numberIndex);
    };

    const dragging = (e) => {
        e.stopPropagation();
        e.target.style.opacity = '0.1';
    };

    const dragover = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const section = e.target.dataset.section;

        if(section === 'section-in'){
            sectionRef.current = 'in';
            sectionIdRef.current = e.target.dataset.id;
        } else if(section === 'section-out'){
            sectionRef.current = 'out';
        } else {
            sectionRef.current = 'field';
        }

        // Extract necessary information from the event object
        const parentRect = e.currentTarget.getBoundingClientRect();
        const offsetY = e.clientY - parentRect.top;
        const currentHoverIndex = parseInt(e.target.dataset.index);

        // Call the throttled function with the extracted information
        throttledDragOverRef.current(parentRect, offsetY, currentHoverIndex);
    };

    const dragoverLogic = (parentRect, offsetY, currentHoverIndex) => {
        const halfHeight = parentRect.height / 2;

        if (offsetY < halfHeight) {
            setPlaceholderIndex(currentHoverIndex);
            setHoverIndex(currentHoverIndex);
        } else {
            setPlaceholderIndex(currentHoverIndex + 1);
            setHoverIndex(currentHoverIndex + 1);
        }
    };

    const dragend = (e) => {
        e.preventDefault();
        e.stopPropagation();

        e.target.style.opacity = '1';

        setPlaceholderIndex(null);

        console.log('sectionRef.current:', sectionRef?.current);

        if(sectionRef?.current === 'in'){

            db.collection(collection)
            .doc(docid)
            .update({
                SectionID: sectionIdRef.current,
            })
            .then(() => {
                console.log('section updated');
                setPlaceholderIndex(null);
            });
        } else if(sectionRef?.current === 'out'){
            db.collection(collection)
            .doc(docid)
            .update({
                SectionID: '',
            })
            .then(() => {
                console.log('section updated');
                setPlaceholderIndex(null);
            });
        } else if( sectionRef?.current === 'field') {
            const arrayCopy = [...itemArray];
            const itemToMove = arrayCopy.splice(dragIndex, 1)[0];
            const adjustedHoverIndex = dragIndex < hoverIndex ? hoverIndex - 1 : hoverIndex;

            arrayCopy.splice(adjustedHoverIndex, 0, itemToMove);

            saveNewPositions(arrayCopy);
        } else {
            console.log('error: unknown sectionRef');
        }
    };

    const saveNewPositions = (arrayCopy) => {
        arrayCopy.forEach((item, index) => {
            db.collection(collection)
            .doc(item.docid)
            .update({
                Position: index + 1,
            })
            .then(() => {
                console.log('position updated');
                setPlaceholderIndex(null);
            });
        });
    };

    const deleteSection = (e) => {

        console.log('delete section')

        db.collection('QuestionnaireFields').doc(sectionDocid).delete()

    }

    return (
        <div
            id='draggable-item-container'
            key={item.ID}
            draggable='true'
            data-index={parentIndex}
            data-docid={item.docid}
            onDragStart={dragstart}
            onDrag={dragging}
            onDragOver={dragover}
            onDragEnd={dragend}

        >
            <div className="table-item-title-container" data-index={parentIndex}>
                <img className="drag-icon" src={dragIcon} alt="" draggable='false' data-index={parentIndex} />
                <div data-drag='no-drag' id='draggable-item-input-container'>
                    {input}
                    {type === 'section' && 
                     <Tooltip content='Verwijder sectie' width={'30px'}>
                        <img src={deleteIcon} id='delete-section-icon' onClick={deleteSection}/>
                     </Tooltip>
                    }
                </div>
            </div>
            {type === 'section' && (
                <div id='section-landing-container' data-index={parentIndex} data-section='section-in' data-id={item.ID}>
                    <div id='section-landing-in-container' data-index={parentIndex} data-section='section-in' data-id={item.ID}>
                        <p data-index={parentIndex} data-section='section-in' data-id={item.ID}>
                            <PanToolOutlinedIcon />
                            Sleep vraag in sectie
                        </p>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default DraggableItems;