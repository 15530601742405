import { createContext, useState, useEffect } from "react";
import { db, auth } from "../firebase/config";
import { client } from "../hooks/Client";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import firebase from 'firebase'

export const Auth = createContext()

export const AuthProvider = (props) => {
    const [authO, setAuthO] = useState(null)

    const history = useHistory()
    const location = useLocation()

    const locationArray = location.pathname.split("/")

    const urlOne = locationArray[1]
    const urlTwo = locationArray[2]
    const urlThree = locationArray[3]
    const urlFour= locationArray[4]
    const urlFive = locationArray[5]
    const urlSix = locationArray[6]
    const urlSeven = locationArray[7]

    // This is the main function that checks for user authentication and gets user info accordingly.
    const getUserInfo = async () => {
        auth.onAuthStateChanged(async user => {
        if (!user) {
            console.log("No user is signed in.");
            handleUnauthenticatedUser();
            return;
        }
        console.log("User is signed in.");
        await handleAuthenticatedUser(user);
        });
    };
    
  // This function handles the logic for authenticated users to retrieve their information from the database.
  const handleAuthenticatedUser = async (user) => {
    try {
      const userQuerySnapshot = await db.collection("Users")
        .where("Compagny", "array-contains", client)
        .where("Email", "==", user.email)
        .get();
    
      if (userQuerySnapshot.empty) {
        console.log("User is not a member of the company");
        handleNonCompanyMember();
        return;
      }
    
      userQuerySnapshot.forEach(doc => {
        console.log('User is a member of the company');
        setAuthO(doc.data());
      });
    } catch (error) {
      console.error("Failed to check company membership:", error);
      // Handle the error appropriately.
    }
  };
  
  
  // This function deals with unauthenticated users and decides if they should be redirected.
  const handleUnauthenticatedUser = () => {
    console.log("No user is signed in.");
    if (isPublicPage()) {
      handlePublicPageAccess();
    } else {
      redirectToLogin();
    }
  };
  
  // This function contains the logic to be executed when the user is authenticated but not a member of the company.
  const handleNonCompanyMember = () => {
    if (isPublicPage()) {
      console.log("openbare pagina");
    } else {
      console.log("geen openbare pagina")
      history.push('/login');
    }
  };
  
  // This function checks whether the current page is one of the public pages where authentication is not required.
  const isPublicPage = () => {

    const publicPages = ['open', 'createaccount', 'Register', 'RegisterInvitation', 'NotApproved'];
    return publicPages.includes(urlOne) || publicPages.includes(urlTwo) || publicPages.includes(urlThree) || publicPages.includes(urlFour) || publicPages.includes(urlFive) || publicPages.includes(urlSix) || publicPages.includes(urlSeven);
  };
  
  // Handles the logic if a user is on a public page, which may require different actions or logging.
  const handlePublicPageAccess = () => {
    console.log("Accessing a public page.");
    // Additional logic for public pages can be added here if needed
  };
  
  // Redirects the user to the login page, to be used when an unauthenticated user attempts to access a restricted page.
  const redirectToLogin = () => {
    console.log("Redirecting to login.");
    history.push('/login');
  };
  
  // useEffect is used here to ensure that the user information is checked whenever the specified URLs change.
  useEffect(() => {
    getUserInfo();
  }, [urlOne, urlTwo, urlThree, urlFour, urlFive, urlSix]);
  

    return(
        <Auth.Provider value={[authO]}>
            {props.children}
        </Auth.Provider>
    )
}