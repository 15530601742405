import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FundName from '../../components/financiers/FundName';
import Location from '../../hooks/Location';
import { useFirestoreAllID } from '../../firebase/useFirestore';

const FundDetails = () => {

    // Hooks
    const fundId = Location()[3]

    console.log(fundId)

    // Firestore
    const funds = useFirestoreAllID('Funds', fundId ? fundId : '')

    console.log(funds)

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <AccountBalanceOutlinedIcon/>
                <h1>Fondsdetails</h1>
                {funds && funds.map((item, index) => (
                    <p>{item.Name}</p>
                ))}
            </div>
            <div>
                <button>Aanvragen</button>
            </div>
        </div>
    </div>
  )
}

export default FundDetails