import Location from "../../hooks/Location"
import QRCode from "react-qr-code";
import printIcon from "../../images/icons/print-icon.png"
import arrowLeftIcon from "../../images/icons/arrow-left-icon.png"
import { useHistory } from "react-router-dom";

const Qrcode = () => {

    // Hooks
    const history = useHistory()

    // Url parameters
    const client = Location()[1]
    const questionnaire = Location()[3]
    const measureMomentID = Location()[4]
    const researchID = Location()[5]
    const persona = Location()[6]

  return (
    <div className="main">
        <div className="main-container">
            <div className="print-go-back-container" onClick={() => history.push(`/${client}/sharequestionnaire/${questionnaire}/${measureMomentID}/${researchID}/${persona}`)}>
                <img src={arrowLeftIcon}/>
                <p>Terug</p>
            </div>
            <div className="print-icon-container" onClick={() => window.print()}>
                <img src={printIcon} alt="print icon" className="print-icon" />
                <p>Print QRcode</p>
            </div>
            <div className="print-content-container">
                <QRCode value={`staging.deccos.nl/${client}/questionnaireopen/${questionnaire}/${measureMomentID}/${researchID}/${persona}/open`} />
            </div>
        </div>
    </div>
  )
}

export default Qrcode