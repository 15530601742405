import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const ParagraphBarChartResponsive = ({ data }) => {
  // Fallback if data[0] is not defined
  const chartData = data && data[0] ? data[0] : [];

  const getPath = (x, y, width, height) => {
    return `M${x},${y}
            L${x + width},${y}
            L${x + width},${y + height}
            L${x},${y + height}
            Z`;
  };

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <ResponsiveContainer width="90%" height={300}>
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="value"
          shape={<TriangleBar />}
          label={{ position: 'top' }}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || "#8884d8"} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ParagraphBarChartResponsive;
