import deleteIcon from '../../images/icons/delete-icon.png'
import { db } from "../../firebase/config";
import { useFirestoreGeneral, useFirestoreID } from "../../firebase/useFirestore";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Tooltip from "../common/Tooltip";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import { useEffect, useState } from "react";

const OutputIsSyncedDelete = ({outputID}) => {
    // State
    const [syncID, setSyncID] = useState('')

    // Hooks
    const history = useHistory()

    // Firestore
    const outputs = useFirestoreID('Outputs', outputID)
    const syncs = useFirestoreGeneral('Synchronisations', 'ProjectOutput', outputID ? outputID : '',)
    
    // Set sync ID
    useEffect(() => {
        if (syncs.length > 0) {
            setSyncID(syncs[0].ID)
        }
    }
    , [syncs])

    // Delete output
    const deleteOutput = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Outputs')
        .doc(docid)
        .delete()

    }

  return (
    <>
        {outputs && outputs.map(output => (
            <>
                {syncs.length > 0 ?
                    <Tooltip content='Deze output is gesynchroniseerd en kan niet worden verwijderd' top='65px'>
                        <SyncOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/synchronisationbuilder/${syncID}`)}/>
                    </Tooltip>
                    :
                    <Tooltip content={'Output verwijderen'} width='80%' top='-60px'>
                        <img className='table-delete-icon' data-docid={output.docid} onClick={deleteOutput} src={deleteIcon} alt="" />
                    </Tooltip>
                }
            </>
        ))}
    </>
  )
}

export default OutputIsSyncedDelete