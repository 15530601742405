import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';

const MultiMatrixDevelopmentGraph = ({row, results, field, researchID}) => {
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    const getColumns = () => {

        const columnArray = []
    
        results && results.forEach(result => {
    
          const input = result.Input
    
          // Get column and row
          const columnRowArray = input.split('/')
          const column = columnRowArray[0]
    
          // Filter duplicates and set columns
          if(!columnArray.includes(column)){
            columnArray.push(column)
          }
    
        })
    
        return columnArray
      }

      // Query input results
  const queryResults = async (input, moment) => {

    const querySnapshot = await db.collection('QuestionnairesResponses')
    .where('CompagnyID', '==', client)
    .where('MomentID', '==', moment)
    .where('FieldID', '==', field)
    .where('Input', '==', input)
    .get()

    const array = []

    querySnapshot.docs.map(async (doc) => {

        array.push(doc.data())
    })

    return array.length

}

   // Get measure moments
   const getMoments = async () => {

    const momentSnapshot = 
    await db.collection("MeasureMoments")
    .where('CompagnyID', '==', client)
    .where('ResearchID', '==', researchID)
    .orderBy("Position", "asc")
    .get();
  
    const momentPromise = momentSnapshot.docs.map(async (moment) => {
        const momentData = {
            name: moment.data().Title,
        }

        const momentID = moment.data().ID

        const querySnapshot = await db.collection('QuestionnairesResponses')
        .where('CompagnyID', '==', client)
        .where('FieldID', '==', field)
        .get()

        querySnapshot.docs.map(async input => {

            const filteredInput = input.data().Input.includes(row)

            const columnRowArray = input.data().Input.split('/')
            const column = columnRowArray[0]

            if(filteredInput){
                momentData[column] = await queryResults(input.data().Input, momentID)
            }
        })

        return momentData
    })
  
      const moments = await Promise.all(momentPromise)
  
      return moments
  }

  useEffect(() => {
    getMoments().then((moments) => {
        setTimeout(() => {
            setColumns(getColumns())
            columns && setData(moments)
        }, 1000)
    })
    }, [field, researchID, results])

  return (
    <div id='multi-matrix-development-graph-container'>
        <h2>{row}</h2>
        <LineChart
        width={500}
        height={300}
        data={data && data}
        margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
        }}
        >
            
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {columns && columns.map((column, index) => (
                <Line key={`line-${index}`} type="monotone" dataKey={column} stroke={column.Color ? column.Color : 'red'} strokeWidth='2'/>
            ))}
            <CartesianGrid strokeDasharray="3 3" />
        </LineChart>
    </div>
  )
}

export default MultiMatrixDevelopmentGraph