import { useState } from "react";
import { v4 as uuid } from 'uuid';
import saveFile from "../../hooks/SaveFile";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import Location from "../../hooks/Location"
import spinner from '../../images/spinner-ripple.svg'

const CreateAccount = () => {
    const [compagny, setCompagny] = useState('')
    const [logo, setLogo] = useState('https://firebasestorage.googleapis.com/v0/b/deccos-app.appspot.com/o/Logos%2FLOGO-logo.png?alt=media&token=2785407c-d390-4ddd-a7c8-db38d1b6a0ba')
    const [loading, setLoading] = useState(false)

    const compagnyId = uuid()
    const history = useHistory();
    const trialType = Location()[2]

    const compagnyHandler = (e) => {
        setCompagny(e.target.value)
    }

    const logoHandler = (e) => {

        saveFile(e, setLogo)

    }

    const createAccount = async () => {

        setLoading(true)

        const id = uuid()

        const createProjectAccount = firebase.functions().httpsCallable('createProjectAccount')  

        const data = {
            compagny: compagny,
            logo: logo,
            trialType: trialType,
            ImpactManager: 'Deccos',
            Parent: '6ab80619-906e-e86c-751c-9ac500b86c18',
            Subscription: 'trial',
            CompagnyId: id,
            Auth: null
        }

        const result = await createProjectAccount({data: data}) 

        if(result.data === 'success') {
            setLoading(false)
            history.push(`/${id}/Register`)
        } else if(result.data === 'error') {
            setLoading(false)
            alert(`Er is iets mis gegaan, neem contact op met Deccos via info@deccos.nl`)
        }
        
      };

    const price = () => {

        if(trialType === 'basis') {
            return '€ 65,-'
        } else if(trialType === 'professioneel') {
            return '€ 85,-'
        } else if(trialType === 'compleet') {
            return '€ 150,-'
        }
    }

  return (
    <div className="login-container" id='create-account-container'>
        <h2>Stap 1 van 2</h2>
        <h1>Creëer Deccos Impact Software bedrijfsprofiel</h1>
        <p>Om met Deccos Impact Software aan de slag te gaan heb je om te beginnen een bedrijfsprofiel nodig.</p>
        <div className="create-account-input-container">
            <h2>Bedrijfsnaam*</h2>
            <input type="text" placeholder="Schrijf hier de naam van je organisatie" onChange={compagnyHandler}/>
        </div>
        <div className="create-account-input-container">
            <h2>Logo</h2>
            <input type="file" onChange={logoHandler}/>
            <img src={logo} id='create-account-dummy-logo' alt="logo" width={150} height={80} />
        </div>
        <div id='subscribe-button-container'>
            <button style={{display: loading ? 'none' : 'block'}} onClick={createAccount}>Creëer bedrijfsprofiel</button>
            <img style={{display: loading ? 'block' : 'none'}} src={spinner} alt="" />
            <p>Na 7 dagen {price()} per maand. Abonnement gaat niet automatisch in en is altijd direct opzegbaar.</p>
        </div>
        <p id='mandetory-notice'>* is verplicht</p>
    </div>
  )
}

export default CreateAccount