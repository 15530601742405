import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

const Impactmarket = () => {
  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <StorefrontOutlinedIcon className='page-header-icon'/>
                <h1>Impactmarkt</h1>
            </div>
        </div>
    </div>
  )
}

export default Impactmarket