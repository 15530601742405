// Sectors Data
export const sectorsArray = [
    { id: 'arbeidsparticipatie', name: 'Arbeidsparticipatie' },
    { id: 'armoedebestrijding', name: 'Armoedebestrijding' },
    { id: 'educatie', name: 'Educatie' },
    { id: 'gezondheid', name: 'Gezondheid' },
    { id: 'kerk- en levensbeschouwing', name: 'Kerk- en levensbeschouwing' },
    { id: 'klimaat, milieu en duurzaamheid', name: 'Klimaat, milieu en duurzaamheid' },
    { id: 'kunst en cultuur', name: 'Kunst en Cultuur' },
    { id: 'maatschappij en welzijn', name: 'Maatschappij en welzijn' },
    { id: 'mensenrechten en veiligheid', name: 'Mensenrechten en veiligheid' },
    { id: 'natuur(behoud) en dierenbelangen', name: 'Natuur(behoud) en dierenbelangen' },
    { id: 'ondernemen en economie', name: 'Ondernemen en economie' },
    { id: 'sport', name: 'Sport' },
    { id: 'wetenschap, onderzoek en innovatie', name: 'Wetenschap, onderzoek en innovatie' },
];

// Regions Data
export const regionsArray = [
    { id: 'landelijk', name: 'Landelijk' },
    { id: 'noord-holland', name: 'Noord-Holland' },
    { id: 'zuid-holland', name: 'Zuid-Holland' },
    { id: 'zeeland', name: 'Zeeland' },
    { id: 'noord-brabant', name: 'Noord-Brabant' },
    { id: 'limburg', name: 'Limburg' },
    { id: 'gelderland', name: 'Gelderland' },
    { id: 'utrecht', name: 'Utrecht' },
    { id: 'flevoland', name: 'Flevoland' },
    { id: 'overijssel', name: 'Overijssel' },
    { id: 'drenthe', name: 'Drenthe' },
    { id: 'groningen', name: 'Groningen' },
    { id: 'friesland', name: 'Friesland' },
];

// Target Groups Data
export const targetgroupArray = [
    { id: 'bewoners/inwoners', name: 'Bewoners/inwoners' },
    { id: 'kinderen (leerlingen)', name: 'Kinderen (leerlingen)' },
    { id: 'jongeren (leerlingen, studenten)', name: 'Jongeren (leerlingen, studenten)' },
    { id: 'ouderen', name: 'Ouderen' },
    { id: 'mensen met een afstand tot de arbeidsmarkt', name: 'Mensen met een afstand tot de arbeidsmarkt' },
    { id: 'mensen met een beperking', name: 'Mensen met een beperking' },
    { id: 'mensen met geestelijke gezondheidsproblemen', name: 'Mensen met geestelijke gezondheidsproblemen' },
    { id: 'laaggeletterden', name: 'Laaggeletterden' },
    { id: 'mensen in stressvolle omstandigheden (armoede, ziekte, dakloos)', name: 'Mensen in stressvolle omstandigheden (armoede, ziekte, dakloos)' },
    { id: 'overige', name: 'Overige' },
];

// Legal Entities Data
export const legalArray = [
    { id: 'eenmanszaak', name: 'Eenmanszaak' },
    { id: 'bv', name: 'BV' },
    { id: 'nv', name: 'NV' },
    { id: 'vof', name: 'VOF' },
    { id: 'maatschap', name: 'Maatschap' },
    { id: 'stichting', name: 'Stichting' },
    { id: 'vereniging', name: 'Vereniging' },
    { id: 'cooperatie', name: 'Coöperatie' },
    { id: 'burgerinitiatieven', name: 'Burgerinitiatieven' },
];