import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'

const MultipleOptions = ({fieldID}) => {

    const multipleOptions = useFirestoreGeneralOrderBy('MultipleQuestionOptions', 'Field', fieldID ? fieldID : '', 'Position', 'asc')

  return (
    <div id='options-container'>
        {multipleOptions && multipleOptions.map(item => (
        <div className='options-item-container'>
            {item.Option === 'other' ? 
            <>
            <input className='question-checkbox-input' name={item.Option} type="checkbox" value={item.Option}/>
            <label htmlFor={item.Option}>Overig...</label>
            </>
            :
            <>
                <input className='question-checkbox-input' name={item.Option} type="checkbox" value={item.Option}/>
                <label htmlFor={item.Option}>{item.Option}</label>
            </>
            }
        </div> 
    ))}</div>
  )
}

export default MultipleOptions