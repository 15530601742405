import { useEffect, useState } from "react";
import ScrollToTop from "../../hooks/ScrollToTop";
import { useFirestoreGeneralOrderBy, useFirestore, useFirestoreGeneral } from "../../firebase/useFirestore";
import useSettings from "../../hooks/Settings";
import { db } from "../../firebase/config";
import { client } from "../../hooks/Client";
import loadingSpinner from '../../images/spinner-ripple.svg'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

const Data = () => {
    // State
    const [outputResultsArray, setOutputResultsArray] = useState([]);
    const [researchResultsArray, setResearchResultsArray] = useState([]);
    const [datasetCreated, setDatasetCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingResearch, setLoadingResearch] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState('');
    const [selectedActivityTitle, setSelectedActivityTitle] = useState('');
    const [selectedResearch, setSelectedResearch] = useState(null);
    const [selectedResearchTitle, setSelectedResearchTitle] = useState('');

    // Hooks
    ScrollToTop();
    const companyName = useSettings().compagnyName;
    const options = { year: 'numeric', month: 'numeric', day: 'numeric'};
    const optionsLong = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    // Firestore
    const questionnairesResponses = useFirestoreGeneralOrderBy('QuestionnairesResponses', 'ResearchID', selectedResearch, 'Timestamp', 'desc');
    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', selectedActivity);
    const activities = useFirestore('Activities');
    const research = useFirestore('Research');

    // COMMON

    // Function to convert an array of objects to a CSV-formatted string
    const convertArrayOfObjectsToCSV = (data) => {
        if (data.length === 0) return '';
        const header = Object.keys(data[0]);
        const csv = [
            header.join(','),
            ...data.map((row) =>
                header
                    .map((fieldName) => {
                        if (Array.isArray(row[fieldName]) && row[fieldName].length > 0) {
                            return row[fieldName][0];
                        } else {
                            return JSON.stringify(row[fieldName]);
                        }
                    })
                    .join(',')
            ),
        ];
        return csv.join('\n');
    };

    // download csv
    const downloadCSV = (data, filename) => {
        const csv = convertArrayOfObjectsToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    };

    // OUTPUTS

    const downloadOutputResultsCSV = () => {
        const filename = `Outputresults-${selectedActivityTitle}-${companyName}-${formatDate(new Date())}.csv`;
        downloadCSV(outputResultsArray, filename);
    };

    const activityHandler = (e) => {
        const id = e.target.value;
        const title = e.target.options[e.target.selectedIndex].dataset.title;
        setSelectedActivity(id);
        setSelectedActivityTitle(title);
    };

    const activityMeta = async (id) => {
        let title = '';
        await db.collection('Activities')
            .where('ID', '==', id)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    title = doc.data().Activity;
                });
            });
        return title;
    };

    const datesMeta = async (outputID) => {
        const dates = [];
        await db.collection('Results')
            .where('CompagnyID', '==', client)
            .where('OutputID', '==', outputID)
            .orderBy('Timestamp', 'asc')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    dates.push(doc.data().Timestamp.toDate().toLocaleDateString("nl-NL", options));
                });
            });
        const uniqueDates = [...new Set(dates)];
        return uniqueDates;
    };

    const results = async (output, date) => {
        const querySnapshot = await db.collection('Results')
            .where('CompagnyID', '==', client)
            .where('OutputID', '==', output)
            .get();
        const score = querySnapshot.docs.map(async (doc) => {
            if (doc.data().Timestamp.toDate().toLocaleDateString("nl-NL", options) !== date) return;
            const result = await doc.data().Result;
            return result;
        });
        const result = await Promise.all(score);
        const filterResults = result.filter(value => value !== undefined);
        if (filterResults.length === 0) return 0;
        const firstIndex = filterResults[0];
        return firstIndex;
    };

    const orderOutputResults = async () => {
        const array = [];
        await Promise.all(
            outputs.map(async (doc) => {
                const object = {
                    Activity: await activityMeta(doc.ActivityID),
                    Output: doc.Title,
                };
                const dates = await datesMeta(doc.ID);
                await Promise.all(
                    dates.map(async (date) => {
                        object[date] = await results(doc.ID, date);
                    })
                );
                array.push(object);
            })
        );
        setOutputResultsArray(array);
    };

    const createDatasetOutputs = async () => {
        setLoading(true);
        await orderOutputResults();
        setLoading(false);
        setDatasetCreated(true);
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // RESEARCH

    const researchHandler = (e) => {
        const id = e.target.value;
        const title = e.target.options[e.target.selectedIndex].dataset.title;
        setSelectedResearch(id);
        setSelectedResearchTitle(title);
    };

    const downloadResearchResultsCSV = (array) => {
        setLoadingResearch(true);
        const filename = `Researchresults-${selectedResearchTitle}-${companyName}-${formatDate(new Date())}.csv`;
        if(array.length !== 0){
            downloadCSV(array, filename);
        }
        setLoadingResearch(false);
    };

    // Define an asynchronous function to get research results
    const getResearchResults = async () => {

        const uniqueFormIDs = [...new Set(questionnairesResponses.map(doc => doc.FormID))];

        console.log(uniqueFormIDs);
        // Initialize an empty object to store data grouped by date
        const data = {};
        
        // Use Promise.all to handle multiple asynchronous operations in parallel
        await Promise.all(
            // Iterate over each document in the questionnaire responses array
            questionnairesResponses.map(async (doc) => {
                // Convert the timestamp to a string formatted as a date in "nl-NL" locale with specific options
                const date = doc.Timestamp.toDate().toLocaleDateString("nl-NL", optionsLong);

                // If the data object does not have an entry for this date, create an empty object for it
                if (!data[date]) {
                    data[date] = {};
                }
                // Retrieve metadata for the current field ID (e.g., question position and title)
                const questionMeta = await questionnaireFieldMeta(doc.FieldID);
                // Store the question title and user's input in the data object, indexed by question position
                data[date][questionMeta.position] = { question: questionMeta.title, input: doc.Input };
            })
        );

        // Initialize an empty array to hold the final formatted results
        const array = [];
        // Iterate over each date in the data object
        for (const date in data) {
            // Get and sort the question positions for the current date
            const sortedQuestions = Object.keys(data[date]).sort((a, b) => a - b);
            // Initialize a result object with the date
            const resultObj = { Date: date };
            // Iterate over each sorted question position
            sortedQuestions.forEach(position => {
                // Add each question and its corresponding input to the result object
                resultObj[data[date][position].question] = data[date][position].input;
            });
            // Push the result object to the results array
            array.push(resultObj);
        }

        // Download the research results as a CSV file
        downloadResearchResultsCSV(array);
    };

    // const measureMomentMeta = async (moment) => {
    //     let title = '';
    //     await db.collection('MeasureMoments')
    //         .where('ID', '==', moment)
    //         .get()
    //         .then((querySnapshot) => {
    //             querySnapshot.forEach((doc) => {
    //                 title = doc.data().Title;
    //             });
    //         });
    //     return title;
    // };

    const questionnaireFieldMeta = async (field) => {
        let title = '';
        let position = 0;
        await db.collection('QuestionnaireFields')
            .where('ID', '==', field)
            // .orderBy('Position', 'asc')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    title = doc.data().Question;
                    position = doc.data().Position;
                });
            });
        return { title, position };
    };

    // const questionnaireMeta = async (questionnaire) => {
    //     let title = '';
    //     await db.collection('Questionnaires')
    //         .where('ID', '==', questionnaire)
    //         .get()
    //         .then((querySnapshot) => {
    //             querySnapshot.forEach((doc) => {
    //                 title = doc.data().Title;
    //             });
    //         });
    //     return title;
    // };

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Data</h1>
                </div>

                <div className="table-container dashboard-container">
                    <h2>Outputresultaten</h2>
                    <p><b>Selecteer een activiteit</b></p>
                    <select name="" id="" onChange={activityHandler}>
                        <option value="">-- Selecteer activiteit --</option>
                        {activities && activities.map(item => (
                            <option value={item.ID} data-title={item.Activity}>{item.Activity}</option>
                        ))}
                    </select>
                    <button onClick={createDatasetOutputs} style={{ display: selectedActivity === '' ? 'none' : 'block' }}>Creëer dataset</button>
                    <img className="loader-svg" src={loadingSpinner} alt="" style={{ display: loading ? 'block' : 'none' }} />
                    <div style={{ display: datasetCreated ? 'flex' : 'none' }} id='download-svg-container'>
                        <p>Download een CSV bestand met alle outputresulaten</p>
                        <button onClick={downloadOutputResultsCSV}>Download resultaten</button>
                    </div>
                </div>

                <div className="table-container dashboard-container">
                    <h2>Onderzoeksresultaten</h2>
                    <p><b>Selecteer een onderzoek</b></p>
                    <select name="" id="" onChange={researchHandler}>
                        <option value="">-- Selecteer onderzoek --</option>
                        {research && research.map(item => (
                            <option value={item.ID} data-title={item.Title}>{item.Title}</option>
                        ))}
                    </select>
                    {loadingResearch
                        ?
                        <img id='loading-icon' src={loadingSpinner} alt="" />
                        :
                        <button style={{ display: selectedResearch ? 'block' : 'none' }} onClick={getResearchResults}>Download resultaten</button>
                    }
                </div>

            </div>
        </div>
    );
};

export default Data;