import Location from "../../hooks/Location"
import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"

const PresenceReport = () => {

    // Hooks
    const personaId = Location()[3]
    const startDate = Location()[4]
    const endDate = Location()[5]

    // Firestore
    const presenceReports = useFirestoreGeneralOrderBy('PersonaPresenceReports', 'PersonaID', personaId, 'Position', 'asc')

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Aanwezigheidsrapportage</h1>
                <p>{startDate}-{endDate}</p>
            </div>
            <div className='table-container dashboard-container'>
                {presenceReports && presenceReports.map((report) => (
                    <div key={report.ID} className='table-row'>
                        <p>{report.Start}</p>
                        <p>{report.End}</p>
                        <p>{report.Reason}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default PresenceReport