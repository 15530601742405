import { useFirestore, useFirestoreGeneral, useFirestoreGeneralOrderBy,  useFirestoreOrderBy} from "../../firebase/useFirestore";
import { useContext, useState, useEffect } from "react";
import { db, timestamp } from "../../firebase/config.js"
import { client } from '../../hooks/Client';
import plusButton from '../../images/icons/plus-icon.png'
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useHistory } from "react-router-dom";
import useSettings from "../../hooks/Settings";
import Modal from 'react-modal';
import { KPIsSystemMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import EffectProof from "../../components/kpis/EffectProof"
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";

const KPIs = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);


    // Hooks
    ScrollToTop()
    Modal.setAppElement('#root');
    const history = useHistory()

    // Firestore
    const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')

    //ImpactAI
    const pageSystemMessage = KPIsSystemMessage()
    const pageStartMessage = `Welkom, ${auth?.ForName}. In deze stap gaan we de KPI's selecteren. 
    KPI's staan voor Key Performance Indicators. Dit zijn de effecten die je wilt onderbouwen met impactresultaten.
    Kan ik nog iets voor je verduidelijken over de KPI's?.`

    useEffect(() => {
        setType('KPIs')
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages([])
    }, [auth])

  return (
     <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>KPI's</h1>
            </div>
            <div className="table-container dashboard-container">
                <div id='select-proof-type-container' style={{display: effects.length > 0 ? 'flex' : 'none'}}>
                    {/* <Tooltip content={'Onderbouwing toevoegen'} width='80%' top='-60px'> */}
                    <div className="select-proof-type-item-container" onClick={() =>  history.push(`/${client}/selectkpis`)}>
                        <img className="table-delete-icon" src={plusButton} alt=""/>
                        <p >Onderbouw met onderzoek</p>
                    </div>
                    <div className="select-proof-type-item-container" onClick={() =>  history.push(`/${client}/selectkpisoutputs`)}>
                        <img className="table-delete-icon" src={plusButton} alt="" />
                        <p >Onderbouw met output</p>
                    </div>
                </div>
                <EffectProof/>
            </div>
            <Breadcrumb
            section={'Meten'}
            previousPage={'Vragenlijsten'}
            previousPageUrl={'questionnaires'}
            nextPage={'Outputresultaten'}
            nextPageUrl={'outputresults'}
            counter={3}
            totalSteps={4}
            />
        </div>
    </div>
  )
}

export default KPIs