import EffectDatabase from "./EffectDatabase"
import { useState, useEffect } from 'react'
import spinner from '../../images/spinner-ripple.svg'

const MeetstandaardEffectMeta = ({effectId}) => {
    const [effectTitle, setEffectTitle] = useState('')
    const [loading, setLoading] = useState(true)

    const database = EffectDatabase()

    const getEffectTitle = () => {

        database && database.map(category => {
            category.effects.map(effect => {
                
                console.log(effect.id===effectId)
                if (effect.id === effectId) {
                    setEffectTitle(effect.name)
                    setLoading(false)
                }
            })
        })
    }

    useEffect(() => {
        getEffectTitle()
    }, [database])

  return (
    <>
        {loading ? <img id='table-loading-icon' src={spinner}/> : <p>{effectTitle}</p>}
    </>
  )
}

export default MeetstandaardEffectMeta