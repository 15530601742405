import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import uuid from 'react-uuid'
import plusButton from '../../images/icons/plus-icon.png'
import Tooltip from "../../components/common/Tooltip";
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import { useState, useContext } from 'react';
import { useFirestoreAllOrderBy } from '../../firebase/useFirestore';
import { timestamp } from '../../firebase/config';
import { db } from '../../firebase/config';
import settingsIcon from "../../images/icons/settings-icon.png";
import { client } from "../../hooks/Client"

const AdminFunds = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

     // Hooks
     const history = useHistory()
     const secundairyColor = useSettings().SecundairyColor

     // Firestore
     const funds = useFirestoreAllOrderBy('Funds', 'Position', 'asc')

    const addFund = () => {

        db.collection('Funds')
        .add({
            Name: 'Nieuw fonds',
            Sector: [],
            Region: [],
            ID: uuid(),
            Timestamp: timestamp,
            Position: funds.length + 1,
            OpenCall: 'Ja',
            Budget: 0,
            FINCode: 0,
            Context: '',
            ContactPerson: '',
            Email: '',
            Phone: '',
            Website: '',
            Description: '',
            SubmissionDeadline: '',
            Conditions: '',
            TargetGroup: [],
            Restrictions: '',
            ApplicationProcess: '',
            EvaluationProcess: ''
        })
        .then(() => {
            setSaved(true)
        })
    }

    const nameHandler = (e) => {

        const docid = e.target.dataset.docid
        const name = e.target.value

        db.collection('Funds')
        .doc(docid)
        .update({
            Name: name
        })
        .then(() => {
            setSaved(true)
        })

    }

    const deleteFund = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Funds')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved(true)
        })

    }

    const region = (region) => {

        if (region.includes('landelijk')) {
            console.log('landelijk')
            return 'Landelijk'
        }
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <AccountBalanceOutlinedIcon/>
                <h1>Fondsen beheer</h1>
            </div> 
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content={'Fonds toevoegen'} top='35px'>
                        <img  src={plusButton} alt="" onClick={addFund} />
                    </Tooltip>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>DETAILS</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>    
                    {funds && funds.map((item, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={item}
                            input={
                                <Tooltip content={item.Name} width='80%' right='-220px' top='-35px'>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Name} placeholder='Naam' onChange={nameHandler}/>
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'Funds'}
                            itemArray={funds}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={item.Name}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            {/* <td>
                                {item.Sector}
                            </td>
                            <td>{region(item.Region)}</td>
                            <td>{item.TargetGroup}</td> */}
                            <td>
                                <img className='table-delete-icon' onClick={() => history.push(`/${client}/fundBuilder/${item.ID}`)} src={settingsIcon} alt="" />
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} onClick={deleteFund} src={deleteIcon} alt="" />
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
            </div> 
        </div>
    </div>
  )
}

export default AdminFunds