import { useFirestoreQuestionnaireFields } from "../../firebase/useFirestore"

const FieldCount = ({id}) => {

    const questionnaireFields = useFirestoreQuestionnaireFields(id)

    return(
        <p id='questionnaifre-field-count'>{questionnaireFields.length}</p>
    )

}

export default FieldCount