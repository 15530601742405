import Location from "../../hooks/Location"
import { useFirestoreID } from "../../firebase/useFirestore";
import { db } from "../../firebase/config";

const ReportStandardDetails = () => {

    // Hooks
    const reportStandardId = Location()[3];

    // Firestore
    const reportStandard = useFirestoreID('PersonaReportStandard', reportStandardId ? reportStandardId : '');

    // Title handler
    const titleHandler = (e) => {
        const title = e.target.value;
        const docid = e.target.getAttribute('data-docid');

        db.collection('PersonaReportStandard').doc(docid).update({
            Title: title
        });
    }

    // Description handler
    const descriptionHandler = (e) => {
        const description = e.target.value;
        const docid = e.target.getAttribute('data-docid');

        db.collection('PersonaReportStandard').doc(docid).update({
            Description: description
        });
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Reportagestandaard instellingen</h1>
                <p></p>
            </div>
            <div className="table-container dashboard-container">
                {reportStandard && reportStandard.map((reportStandard) => (
                    <div key={reportStandard.ID} className="table-item">
                        <div className="table-item-content">
                            <h2>Titel</h2>
                            <input type="text" data-docid={reportStandard.docid} defaultValue={reportStandard.Title} onChange={titleHandler}/>
                            <p>Omschrijving</p>
                            <textarea name="" id="" data-docid={reportStandard.docid} defaultValue={reportStandard.Description} onChange={descriptionHandler}></textarea>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default ReportStandardDetails