import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
  } from "recharts";
import { useState, useEffect } from 'react'
import useSettings from "../../hooks/Settings";
import { db } from "../../firebase/config";

const ResearchResultsGraph = ({field, researchID}) => {
  const [data, setData] = useState('')

  // Get the primary color from the settings
  const primaryColor = useSettings().PrimaryColor

  const getData = async () => {
    const dataArray = [];
  
    // Get all the moments for this research
    const querySnapshot = await db.collection('MeasureMoments')
      .where('ResearchID', '==', researchID)
      .orderBy('Position', 'asc')
      .get();

      console.log(querySnapshot.size)
  
    for (const doc of querySnapshot.docs) {
      // Create an object for each moment with the title and the average
      const dataObject = {
        Title: doc.data().Title,
        Gemiddelde: await average(doc.data().ID)
      };
  
      dataArray.push(dataObject);
    }

    console.log(dataArray)
  
    setData(dataArray);
  };
  
  const average = async (momentID) => {
    const resultsArray = [];
  
    const querySnapshot = await db.collection('QuestionnairesResponses')
      .where('ResearchID', '==', researchID)
      .where('MomentID', '==', momentID)
      .where('FieldID', '==', field)
      .get();
  
    for (const doc of querySnapshot.docs) {
      const number = parseInt(doc.data().Input);
      resultsArray.push(number);
    }
  
    const sum = resultsArray.reduce((partialSum, a) => partialSum + a, 0);
    const average = sum / resultsArray.length;
    const roundedAverage = Math.round(average * 10) / 10;
    return roundedAverage;
  };
  
  useEffect(() => {
    getData();
  }, [field, researchID]);

  console.log(data)

  return (
    <div  >
        <AreaChart
        width={400}
        height={200}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
          >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Title" />
        <YAxis/>
        <Tooltip />
        <Area type="monotone" dataKey="Gemiddelde" stroke={primaryColor} fill={primaryColor} fill-opacity="1" />
      </AreaChart>
  </div>
  )
}

export default ResearchResultsGraph