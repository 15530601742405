import FieldType from './FieldType';

const Fields = ({field, compagnyId, questionaire}) => {

    return (
        <div className="question-field-outer-container" key={field.ID}>
            <div className='question-container'>
                <div className='field-title-container'>
                    <h2>{field.Question}</h2>
                </div>
                <div className='explainer-container'>
                    <p>{field.Explainer}</p> 
                </div>
                <FieldType 
                field={field} 
                compagnyId={compagnyId} 
                questionaire={questionaire}
                />
            </div>
        </div>
    )
}

export default Fields