import Navigation from './navigation/Navigation';
import { BrowserRouter as Router } from "react-router-dom";
import {AuthProvider} from './StateManagment/Auth';
import { ActiveMenuProvider } from './StateManagment/ActiveMenuItem';
import { SavedProvider } from './StateManagment/SavedIcon';
import { ResponsProvider } from './StateManagment/Responses';
import { ImpactAIProvider } from './StateManagment/ImpactAI';
import { ImpactAIDataProvider } from './StateManagment/ImpactAIData';
import { OnboardingProvider } from './StateManagment/Onboarding';
import { PersonaNavigationProvider } from './StateManagment/PersonaNavigation';

function App() {

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <ActiveMenuProvider>
            <SavedProvider>
              <ResponsProvider>
                <ImpactAIProvider>
                  <ImpactAIDataProvider>
                    <OnboardingProvider>
                      <PersonaNavigationProvider>
                        <Navigation/>
                      </PersonaNavigationProvider>
                    </OnboardingProvider>
                  </ImpactAIDataProvider>
                </ImpactAIProvider>
              </ResponsProvider>
            </SavedProvider>
          </ActiveMenuProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;