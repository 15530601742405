import uuid from "react-uuid";
import { db } from "../../firebase/config";
import deleteIcon from '../../images/icons/delete-icon.png'
import firebase from "firebase";
import { useFirestoreAnalysisWords } from "../../firebase/useFirestore";

const CategoryWords = ({category, field, moment}) => {

    console.log(field)

    const words = useFirestoreAnalysisWords(field ? field : '', moment ? moment : '', category ? category.ID : '')

    const deleteWord = (e) => {
        const docid = e.target.dataset.docid

        db.collection('AnalysisWords')
        .doc(docid)
        .delete()
    }

  return (
      <div>
          {words.length === 0 ? 
            <p>Selecteer woorden voor deze categorie</p>
            :
            <ul>
                {words && words.map(item => (
                    <li key={item.ID} className='selected-words-container'>
                       {item.Word}
                       <img src={deleteIcon} alt="" data-word={item.Word} data-docid={item.docid} onClick={deleteWord} /> 
                    </li>
                ))}
            </ul>
            }
      </div>
     
  )
}

export default CategoryWords