import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { client } from "../../hooks/Client"
import useSettings from "../../hooks/Settings";
import { useContext } from 'react';
import { Auth } from '../../StateManagment/Auth';

const Breadcrumb = ({
  previousPage, 
  previousPageUrl, 
  nextPage, 
  nextPageUrl, 
  counter, 
  section, 
  totalSteps
}) => {
  // Context
  const [auth] = useContext(Auth)

     // Hooks
  ScrollToTop()
  const history = useHistory()
  const secundairyColor = useSettings().SecundairyColor

  return (
    <div id='breadcrumb-container'>
        <div className='breadcrumb-item-container' onClick={() => history.push(`/${client}/${previousPageUrl}`)}>
            <WestOutlinedIcon/>
            <p 
            onMouseEnter={(e) => e.target.style.fontWeight = 'bold'}
            onMouseLeave={(e) => e.target.style.fontWeight = 'normal'}
            >{previousPage}</p>
        </div>
        <div id='breadcrumb-section-container'>
            <p><b>{section}</b></p>
            <div className='breadcrumb-item-container breadcrumb-steps-container'>
                <p>Stap &nbsp;</p>
                <p>{counter}</p>
                <p>&nbsp; van {totalSteps}</p>
            </div>
        </div>
        
        <div className='breadcrumb-item-container' onClick={() => history.push(`/${client}/${nextPageUrl}`)} style={{display: nextPage === '' ? 'none' : 'flex'}}>
            <p
             onMouseEnter={(e) => e.target.style.fontWeight = 'bold'}
             onMouseLeave={(e) => e.target.style.fontWeight = 'normal'}
            >{nextPage}</p>
            <EastOutlinedIcon/>
        </div>
        {/* <Joyride
        steps={auth?.Logins > 1 ? [] : steps}
        /> */}
    </div>
  )
}

export default Breadcrumb