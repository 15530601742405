import { useEffect } from "react"

const ScrollToTop = () => {
  
    useEffect(() => {

        window.scrollTo(0,0)
      
    }, [])
    
}

export default ScrollToTop